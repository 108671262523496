export class UpdateSubscriptionFormModel {
    currentSubData: any;

    constructor(

        public currentDNSFilteringQuantity: number = 0,
        public pendingDNSFilteringQuantity: number = 0,
        public currentDeviceSubQuantity: number = 0,
        public pendingDeviceSubQuantity: number = 0,
        public totalServiceCharge: number = 0,
        public remainingValue: number = 0,
        public outstandingBalance: number =0,
        public amountDueNow: number = 0,
        public devSubNextDueDate: string = "1970-01-01T00:00:00.000Z",
        public lastPaidDate: string = "1970-01-01T00:00:00.000Z",
        public terminationDate: string = null


    ) {

    }
}
