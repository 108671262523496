<form (ngSubmit)="onSubmit(submissionModalContent)" #updateSubscriptionForm="ngForm">

    <div class="row">
        <div class="col">
            <h5 class="modal-title text-danger mb-1">CURRENT PLAN</h5>
        </div>
        <div class="col">
            <p class="lead text-right mb-1">*FIRST DEVICE DOESN'T REQUIRE SUBSCRIPTION</p>
        </div>
    </div>

    <!-- Current Device Subscription Quantity -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="Current Device Subscription Quantity"
                ngbPopover="The number of devices currently available to be configured for use with the Greyhex VPN service. NON-EDITABLE.">
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="currentDeviceSubQuantity"
                >
                <fa-icon 
                    [icon]="faMobileAlt"  
                    class="text-danger"
                ></fa-icon>
                &nbsp;Subscription Quantity
                </label>
            </div>
            <input
                readonly
                id="currentDeviceSubQuantity" 
                type="text"
                required
                class="form-control"
                [(ngModel)]="model.currentDeviceSubQuantity"
                name="currentDeviceSubQuantity"
                placeholder="{{model.currentDeviceSubQuantity}}"
                #currentDeviceSubQuantity="ngModel"
                aria-describedby="currentDeviceSubscriptionQuantityHelp" 
            >
        </div>
        <small *ngIf="localDate(this.currentSubData.devSubNextDueDate) != '12/31/1969'" id="currentDeviceSubscriptionQuantityHelp" class="form-text text-muted ml-2">Next Payment Amount Due: ${{this.currentSubData.devSubNextDueAmount}} on {{localDate(this.currentSubData.devSubNextDueDate)}}.</small>
    </div>

    <!-- Current DNS Filtering Subscription -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="Current DNS Filtering Subscription"
                ngbPopover="The number of devices currently available to be configured for use with DNS Filtering on the Greyhex VPN service. NON-EDITABLE.">
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="currentDNSFilteringQuantity"
                >
                <fa-icon 
                    [icon]="faFilter" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;DNS Filtering
                </label>
            </div>
            <input
                readonly
                class="form-control" 
                id="Quantity"
                required
                [(ngModel)]="model.currentDNSFilteringQuantity" 
                name="currentDNSFilteringQuantity"
                #currentDNSFilteringQuantity="ngModel"       
                aria-describedby="currentDNSFilteringQuantityHelp"
                value="{{this.currentSubData.currentDNSFiltering == 0 ? 'No Devices Currently Subscribed' : 'DNS Filtering Available for ' + this.currentSubData.currentDNSFiltering + ' Devices' }}"
            >          
        </div>
        <small *ngIf="localDate(this.currentSubData.dnsSubNextDueDate) != '12/31/1969'" id="currentDNSFilteringQuantityHelp" class="form-text text-muted ml-2">Next Payment Amount Due: ${{this.currentSubData.dnsSubNextDueAmount}} on {{localDate(this.currentSubData.dnsSubNextDueDate)}}.</small>
    </div>

    <hr>

    <h5 class="modal-title text-danger mb-1">UPDATE PLAN</h5>

    <!-- Pending Device Subscription Quantity -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="New Device Subscription Quantity"
                ngbPopover="Increases are updated after payment confirmation. Decreases are updated at the end of the service period. Decreasing will cause the least active devices to be disabled at the end of the service period, if exceeding this value."
                >
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="pendingDeviceSubQuantity"
                >
                <fa-icon 
                    [icon]="faMobileAlt"  
                    class="text-danger"
                ></fa-icon>
                &nbsp;Subscription Quantity
                </label>
            </div>
            <input
                id="pendingDeviceSubQuantity" 
                type="text"
                required
                class="form-control"
                [(ngModel)]="model.pendingDeviceSubQuantity"
                name="pendingDeviceSubQuantity"
                placeholder="Enter a number greater than 0"
                #pendingDeviceSubQuantity="ngModel"
                aria-describedby="pendingDeviceSubscriptionQuantityHelp"
                pattern="[1-9]{1}[0-9]{0,3}"
                (ngModelChange)="updateFormVisualizations()"
            >
            <div class="input-group-append bg-light">
                <span class="input-group-text" id="newSubDeviceTotal">${{this.newDevTotal}}</span>
            </div>
        </div>
        <small id="pendingDeviceSubscriptionQuantityHelp" class="form-text text-muted ml-2">Device Subscriptions are $2.00 (USD) per device, per month.</small>
        <small id="pendingDeviceSubscriptionQuantityHelp" class="form-text text-muted ml-1">Currently, the first device may be connected without a subscription here.</small>
        <div 
            [hidden]="pendingDeviceSubQuantity.valid || pendingDeviceSubQuantity.pristine"
            class="alert alert-danger input-group"
            role="alert"
        >
        A Device subscription Quantity must be a number between 1 and 9999
        </div>
    </div>

    <!-- Pending DNS Filtering Subscription -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="New DNS Filtering Subscription"
                ngbPopover="Determines if currently subscribed devices can will be routed through the Greyhex VPN's Enhanced service providing improved privacy, security, and reduced interactions with ads, trackers, and known malicious content. Deactivation of this subscription will take effect at the end of the service period. Activations occur on reciept of payment.">
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="pendingDNSFilteringQuantity"
                >
                <fa-icon 
                    [icon]="faFilter" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;DNS Filtering
                </label>
            </div>
            <select
                class="form-control" 
                id="pendingDNSFilteringQuantity"
                required
                [(ngModel)]="model.pendingDNSFilteringQuantity" 
                name="pendingDNSFilteringQuantity"
                #pendingDNSFilteringQuantity="ngModel"       
                aria-describedby="pendingDNSFilteringQuantity"         
                (ngModelChange)="updateFormVisualizations()"
            >          
                <option selected [ngValue]=0>No DNS Filtering On {{model.pendingDeviceSubQuantity}} Devices</option>
                <option [ngValue]=model.pendingDeviceSubQuantity>Enable DNS Filtering on {{model.pendingDeviceSubQuantity}} Devices</option>

            </select>
            <div class="input-group-append bg-light">
                <span class="input-group-text" id="newDNSTotal">${{this.newDNSTotal}}</span>
            </div>
        </div>
        <small id="pendingDNSFilteringQuantityHelp" class="form-text text-muted ml-2">DNS Filtering is $0.99 (USD) per device, per month.</small>
        <small id="pendingDNSFilteringQuantityHelp" class="form-text text-muted ml-1">*Enabling or Disabling Filtering will require re-importing the configuration to your devices</small>
        <div 
            [hidden]="pendingDNSFilteringQuantity.valid && !pendingDNSFilteringQuantity.pristine" 
            class="alert alert-danger input-group"
            role="alert"
        >
        A DNS filtering choice is required
        </div>

    </div>

    <hr>

    <h5 class="modal-title text-danger mb-1">CHANGE BREAKDOWN</h5>

    <!-- New Monthly Charge -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="New Monthly Charge"
                ngbPopover="The monthly reoccuring amount to be charged based on service selections above.">
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="newMonthlyCharge"
                >
                <fa-icon 
                    [icon]="faMobileAlt"
                    class="text-danger"
                ></fa-icon>
                &nbsp;New Monthly Charge
                </label>
            </div>
            <input
                readonly
                id="totalServiceCharge" 
                type="text"
                required
                class="form-control"
                [(ngModel)]="model.totalServiceCharge"
                name="totalServiceCharge"
                placeholder="{{model.totalServiceCharge}}"
                #totalServiceCharge="ngModel"
                aria-describedby="totalServiceChargeHelp" 
            >
        </div>
        <small id="totalServiceChargeHelp" class="form-text text-muted ml-2">Taxes not included.</small>
    </div>

    <!-- Remaining Service value, outstanding balance or neither. -->
    <div *ngIf="this.currentSubData.currentSubDevCount != 0">
        <div *ngIf="!this.hasPastDue && !this.hasRemainingValue; then neither else !this.hasPastDue ? remainingValue : outstandingBalance"></div>
    </div>

    <ng-template #neither>

    </ng-template>

    <ng-template #remainingValue>
        <div class="form-row mb-3">
            <div class="input-group">
                <button type="button" class="btn btn-dark mr-2 ml-1" 
                    placement="right"
                    popoverTitle="Approximate Remaining Value"
                    ngbPopover="The daily value of the existing service, multiplied by the number of days remaining in the service period.">
                    <fa-icon 
                        [icon]="faInfoCircle" 
                        class="text-white"
                    ></fa-icon>
                </button>
                <div class="input-group-prepend">
                    <label 
                        class="input-group-text bg-light"
                        style="min-width:17rem;"
                        for="remainingValue"
                    >
                    <fa-icon 
                        [icon]="faMobileAlt"  
                        class="text-danger"
                    ></fa-icon>
                    &nbsp;Approximate Remaining Value
                    </label>
                </div>
                <input
                    readonly
                    id="remainingValue" 
                    type="text"
                    required
                    class="form-control"
                    [(ngModel)]="model.remainingValue"
                    name="remainingValue"
                    placeholder="{{model.remainingValue}}"
                    #remainingValue="ngModel"
                    aria-describedby="remainingValueHelp" 
                >
            </div>
            <small id="remainingValueHelp" class="form-text text-muted ml-2">When decreasing services, changes will not take affect until the next billing period starts, to prevent loss of value.</small>
        </div>
    </ng-template>
    
    <ng-template #outstandingBalance>
        <div class="form-row mb-3">
            <div class="input-group">
                <button type="button" class="btn btn-dark mr-2 ml-1" 
                    placement="right"
                    popoverTitle="Outstanding Balance"
                    ngbPopover="The daily value of the previously registered service multiplied by the number of days of services used without paying.">
                    <fa-icon 
                        [icon]="faInfoCircle" 
                        class="text-white"
                    ></fa-icon>
                </button>
                <div class="input-group-prepend">
                    <label 
                        class="input-group-text bg-light"
                        style="min-width:17rem;"
                        for="outstandingBalance"
                    >
                    <fa-icon 
                        [icon]="faMobileAlt"  
                        class="text-danger"
                    ></fa-icon>
                    &nbsp;Outstanding Balance Due
                    </label>
                </div>
                <input
                    readonly
                    id="outstandingBalance" 
                    type="text"
                    required
                    class="form-control"
                    [(ngModel)]="model.outstandingBalance"
                    name="outstandingBalance"
                    placeholder="{{model.outstandingBalance}}"
                    #outstandingBalance="ngModel"
                    aria-describedby="outstandingBalanceHelp" 
                >
            </div>
            <small id="routstandingBalanceHelp" class="form-text text-muted ml-2">Outstanding balances must be paid up to resume or elevate service.</small>
        </div>
    </ng-template>

    <!-- Due Now -->
    <div class="form-row mb-3">
        <div class="input-group">
            <button type="button" class="btn btn-dark mr-2 ml-1" 
                placement="right"
                popoverTitle="Total Due Now"
                ngbPopover="When increasing services, changes are billed immediately to enable instant access to additional services. Considering current value of services, some amount may be due.">
                <fa-icon 
                    [icon]="faInfoCircle" 
                    class="text-white"
                ></fa-icon>
            </button>
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:17rem;"
                    for="amountDueNow"
                >
                <fa-icon 
                    [icon]="faMobileAlt"  
                    class="text-danger"
                ></fa-icon>
                &nbsp;Amount Due Now
                </label>
            </div>
            <input
                readonly
                id="amountDueNow" 
                type="text"
                required
                class="form-control"
                [(ngModel)]="model.amountDueNow"
                name="amountDueNow"
                placeholder="{{model.amountDueNow}}"
                #amountDueNow="ngModel"
                aria-describedby="amountDueNowHelp" 
            >
        </div>
        <small id="amountDueNowHelp" class="form-text text-muted ml-2">Next bill will be due 30 days after payment is completed.</small>
    </div>

    <div class="container-fluid">
        <div class="row h-100">
            <div class="col-auto">
                <button type="submit" class="btn btn-dark" [disabled]="!updateSubscriptionForm.form.valid">Confirm Changes&nbsp;<fa-icon [icon]="faEdit"></fa-icon></button>
            </div>     
            <div class="col"></div>
            <div class="col-auto">
                <button type="button" (click)="cancelSubClicked(submissionModalContent);" class="btn btn-danger">Cancel Subscription&nbsp;<fa-icon [icon]="faBan"></fa-icon></button>
            </div>                 
        </div>
    </div>
</form>

<ng-template #submissionModalContent let-modal>
    <div class="modal-header card-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">SUBSCRIPTION CHANGES</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{this.submissionModalMessage}}</p>
    </div>
    <div class="modal-footer card-footer">     
        <button type="button" class="btn btn-dark" aria-label="okay" (click)="modal.close('Okay click')">
            <span aria-hidden="true">Okay</span>
        </button>
    </div>
</ng-template>