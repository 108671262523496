import { Component, OnInit } from '@angular/core';
import { faMobile, faLaptop, faMobileAlt, faFilter, faHandHoldingUsd, faDollarSign, faUserPlus, faTrashAlt, faHandPaper, faCalendarDay, faCalendarAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.css']
})
export class PricingInfoComponent implements OnInit {

  faDollarSign = faDollarSign;
  faMobileAlt = faMobileAlt;
  faLaptop = faLaptop;
  faHandHoldingUsd = faHandHoldingUsd;
  faFilter = faFilter;

  constructor() { }

  ngOnInit(): void {
  }

}
