<div class="container-fluid segmentLight" id="foundersPromise">

    <div class="row py-5 align-items-center">

        <div class="card-deck mx-auto">

            <div class="card card3D-xl card3D-light cardbg-light">

                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faBullhorn"></fa-icon><span class="ml-3">FOUNDERS MISSION</span></h4>
                    <hr class="border-danger">
                    <br>
                    <br>
                    <p class="card-text text-center">We created Bitcipher to help affect positive change through technology. To start, we want to make digital privacy realistic, accessible and affordable to as many people as possible. Your data privacy and confidentiality will always be a focus for us. With your support we will endeavor to create new and better service offerings rooted in the belief that customer privacy, confidentiality, and convenience are paramount.</p>
                </div>
            </div>
