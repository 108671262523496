import { Component, OnInit } from '@angular/core';
import { faInstagram,faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-meta-search-engine',
  templateUrl: './meta-search-engine.component.html',
  styleUrls: ['./meta-search-engine.component.css']
})
export class MetaSearchEngineComponent implements OnInit {


  // font awesome Icons
  faSearch = faSearch;

  baseURL = "https://greyhex.io/search?q=";

  //place holder for searchQuery
  model = {
    searchQuery: ""
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit() {
    var tempURL = this.baseURL + this.model.searchQuery;
    window.open(tempURL, '_blank');
  }

}
