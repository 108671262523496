<div *ngIf="this.currentUser.loadingData; then loading else profile">
</div>

<ng-template #loading>
  <!-- TOP SPACER -->
  <div class="container-fluid">

    <div [ngClass]="normalSpacer" style="height: 50px;"></div>
  </div>

  <!-- Loading Data Screen segment-->
  <div class="container-fluid segmentLight">
    <div class="row py-5">
      <div class="card-deck mx-auto">
        <!-- Loading data Card-->
        <div class="card card3D-loading card3D-light cardbg-light" id="loadingDataScreen">
          <div class="card-header bg-light" style="min-height: 7.5rem;">
            <div class="row">

              <div class="col">
                <div class="row">
                  <div class="col">
                    <h3 class="card-title text-danger text-left"> <fa-icon [icon]="faUserAlt"></fa-icon> LOADING</h3>
                  </div>
                </div>
              </div>

              <div class="col ml-auto text-right">
                <div class="row">
                  <div class=" ml-auto text-right pr-3">
                    <span class="spinner-border text-danger ml-auto text-right" role="status">
                      <span class="sr-only">Processing...</span>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="card-body">
                <div class="row">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light" style="min-width: 12rem;" id="accountID">Account ID:</span>
                    </div>
                    <input type="text" readonly class="form-control bg-white text-right" value={{this.currentUser.backendUserData.userID}} aria-label="Account ID" aria-describedby="accountID">
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</ng-template>

<ng-template #profile>

  <!-- TOP SPACER -->
  <div class="container-fluid">
    <div [ngClass]="normalSpacer" style="height: 50px;"></div>
  </div>

  <!-- PROFILE DISPLAY-->
  <div class="container-fluid segmentLight">
    <div class="row py-5 align-items-center">
      <div class="card-deck mx-auto">

        <!-- Account status segment-->
        <div class="card card3D-xl card3D-light cardbg-light" id="acctStatus">
          <div class="card-header bg-light" style="min-height: 7.5rem;">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h3 class="card-title text-danger text-left"> <fa-icon [icon]="faUserAlt"></fa-icon> ACCOUNT</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h6 class="card-subtitle mt-1 mb-2 text-lg text-muted" style="max-width: 300px;">{{this.currentUser.backendUserData.userID}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <div class="row">
                  <div class="col">
                    <!-- Nothing in the right col -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
                <div class="row">

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light" style="min-width: 13rem;" id="regEmailAddon">Registered Email:</span>
                    </div>
                    <input type="text" readonly class="form-control bg-white text-right" value={{this.currentUser.backendUserData.email}} aria-label="Registered Email" aria-describedby="regEmailAddon">
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light" style="min-width: 13rem;" id="activeAvailableAddon">Active/Available Devices:</span>
                    </div>
                    <input type="text" readonly class="form-control bg-white text-right" value="{{this.numberOfDevices}}/{{getCurrentSubDevCount()}}" aria-label="Active Available" aria-describedby="activeAvailableAddon">
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light" style="min-width: 13rem;" id="lastActiveTimeAddon">Last Active Time:</span>
                    </div>
                    <input type="text" readonly class="form-control bg-white text-right" [value]="convertDate(this.currentUser.backendUserData.lastActive, true)" aria-label="Status" aria-describedby="lastActiveTimeAddon">
                  </div>
                </div>
          </div>

        </div>

        <!-- Subscription status segment-->
        <div class="card card3D-xl card3D-light cardbg-light" id="subStatus">
          <div class="card-header bg-light" style="min-height: 7.5rem;">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h3 class="card-title text-danger text-left"> <fa-icon [icon]="faEdit"></fa-icon> SUBSCRIPTION</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h6 class="card-subtitle mt-1 mb-2 text-lg text-muted">{{this.currentUser.backendUserData.subStatus.status}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="row">
                  <div class="col ml-auto align-self-center">
                    <h3 class="text-right"><a class="btn btn-sm btn-dark text-white" style="min-width: 8rem;" (click)="updateSubscriptionClicked(updateSubscriptionModal);">Update</a></h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div class="card-body">

            <div *ngIf="(this.currentUser.backendUserData.subStatusID == '0' || this.currentSubData._lastChangedAt == 1 ); then blankSub else existingSub">
            </div>
    
            <ng-template #blankSub>
            </ng-template>
    
            <ng-template #existingSub>

              <div class="row">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="SubNextDueDateAddon">Next Due Date:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="convertDate(this.currentSubData.devSubNextDueDate, false)" aria-label="Next Due Date" aria-describedby="SubNextDueDateAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="SubNextBillAmountAddon">Next Due Amount:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="this.getNextDueAmount()" aria-label="Next Due Amount" aria-describedby="SubNextBillAmountAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="subLastPayDateAddon">Last Payment Date:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="convertDate(this.currentSubData.lastPaidDate, false)" aria-label="Last Payment Date" aria-describedby="subLastPayDateAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="subLastPayAmountAddon">Last Payment Amount:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" value={{this.currentSubData.lastPaidAmount}} aria-label="Last Payment Amount" aria-describedby="subLastPayAmountAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="currentDeviceCountAddon">Sub Devices This Period: </span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="this.currentSubData.currentSubDevCount" aria-label="Subscribed Devices this period" aria-describedby="currentDeviceCountAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="pendingDeviceCountAddon">Sub Devices Next Period: </span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="this.currentSubData.pendingSubDevCount" aria-label="subscribed Devices next period" aria-describedby="pendingDeviceCountAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="currentDNSFilteringAddon">DNS Filtering This Period:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="this.currentDNSFilteringAsText()" aria-label="Current DNS Filtering" aria-describedby="currentDNSFilteringAddon">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 13rem;" id="pendingDNSFilteringAddon">DNS Filtering Next Period:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right" [value]="this.pendingDNSFilteringAsText()" aria-label="Pending DNS Filtering" aria-describedby="pendingDNSFilteringAddon">
                </div>

                <div *ngIf="(this.currentUser.backendUserData.subStatusID == '8');" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light text-danger" style="min-width: 13rem;" id="subDevExpirationDate">Subscription Cancellation Date:</span>
                  </div>
                  <input type="text" readonly class="form-control bg-white text-right text-danger" [value]="convertDate(this.currentSubData.devSubExpirationDate, false)" aria-label="Subscription Expiration Date" aria-describedby="subDevExpirationDateAddon">
                </div>

              </div>

            </ng-template>

          </div>
        </div> 

        <!-- Device status segment-->
        <div class="card card3D-xl card3D-light cardbg-light" id="devStatus">
          <div class="card-header bg-light" style="min-height: 7.5rem;">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h3 class="card-title text-danger text-left"> <fa-icon [icon]="faMobileAlt"></fa-icon> DEVICES</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h6 class="card-subtitle mt-1 mb-2 text-muted text-lg text-left" *ngIf="this.numberOfDevices > 0">
                      <a class="homeLinks" (click)="previousDeviceClicked()"><fa-icon [icon]="faCaretSquareLeft"></fa-icon></a>
                      &nbsp; {{this.currentDeviceNumber}}/{{this.numberOfDevices}} of {{(getCurrentSubDevCount())}} &nbsp;
                      <a class="homeLinks" (click)="nextDeviceClicked()"><fa-icon [icon]="faCaretSquareRight"></fa-icon></a>
                    </h6>
                  </div>
                </div>
              </div>
                <div class="col">
                  <div class="row">
                    <div class="col ml-auto align-self-center">
                      <div class="div" *ngIf="this.numberOfDevices > 0">
                        <h3 class="text-right"> <a class="btn btn-sm btn-dark text-white" style="min-width: 8rem;" *ngIf="(this.currentUser.backendUserData.subStatusID != '5')" (click)="updateDeviceClicked(updateDevModal);">Update</a></h3>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col ml-auto align-self-center">
                      <h3 class="text-right">
                        <div class="btn-group" role="group" aria-label="Add or delete">
                          <button type="button" class="btn btn-sm btn-dark text-white" style="min-width: 4rem;" *ngIf="(this.numberOfDevices < getCurrentSubDevCount() ) && (this.currentUser.backendUserData.subStatusID != '5')" (click)="addDeviceClicked(addDevModal);">Add</button>
                          <button *ngIf="this.numberOfDevices > 0" type="button" class="btn btn-sm btn-danger text-white" style="min-width: 4rem;" (click)="deleteDeviceClicked(deleteDevModal);">Delete</button>
                        </div>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="card-body" *ngIf="(this.numberOfDevices > 0);">
            <div class="row">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="deviceNameaddon">Device:</span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" value={{this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].devName}} aria-label="Device Name" aria-describedby="deviceNameaddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="deviceTypeaddon">Type:</span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" value={{this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceType.devType}} aria-label="Device Type" aria-describedby="deviceTypeaddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="ipAddressAddon">IP Address:</span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" value={{this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].ipAddress}} aria-label="IP Address" aria-describedby="ipAddressAddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="dnsFilteringAddon">DNS Filtering:</span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" [value]="this.convertBoolToEnabledDisabled(this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].dnsFiltering)" aria-label="DNS Filtering" aria-describedby="dnsFilteringAddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="lastActiveAddon">Last Active:</span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" [value]="this.convertLastActive()" aria-label="Last Active Time" aria-describedby="lastActiveAddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="deviceStatusAddon">Device Status:
                    <span *ngIf="this.isDeviceProcessing()" class="spinner-border spinner-border-sm text-danger ml-auto" role="status">
                      <span class="sr-only">Processing...</span>
                    </span>
                  </span>
                </div>
                <input type="text" readonly class="form-control bg-white text-right" [value]="deviceStatusVisual(this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceStatus.id)" aria-label="Device Status" aria-describedby="deviceStatusAddon">
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="devicePubKeyAddon">Device Public Key:</span>
                </div>
                <textarea type="text" readonly class="form-control bg-white align-middle text-right" value={{this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].devicePubKey}} aria-label="Device Public Key" aria-describedby="devicePubKeyAddon"></textarea>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-light" style="min-width: 12rem;" id="devicePrivKeyAddon">Device Private Key:</span>
                </div>
                <textarea type="text" readonly class="form-control bg-white align-middle text-right" value={{this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].devicePrivKey}} aria-label="Device Private Key" aria-describedby="devicePrivKeyAddon"></textarea>
              </div>

              <ng-container *ngIf="this.regionDownloads != null">
                <div class="input-group mb-3" *ngFor="let regionDownload of this.regionDownloads">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-light" style="min-width: 12rem; min-height: 3rem;" id="regionListPreAddon">
                      {{regionDownload.name}}
                    </span>
                  </div>
                  <input type="text" readonly class="form-control bg-white align-middle text-right" style="min-height: 3rem;" value="{{regionDownload.uploaded}}Mb Up | {{regionDownload.downloaded}}Mb Down" aria-label="regionDownload" aria-describedby="regionDownloadAddon">
                  <div class="input-group-append">
                    <span class="input-group-text" style="min-height: 3rem;" id="regionListPostAddon">
                      <a [href]="regionDownload.link" class="btn btn-sm btn-dark" style="min-width: 8rem;">Configuration&nbsp;<fa-icon [icon]="faFileDownload"></fa-icon></a>
                    </span>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>

  <!-- Instructions for setting up -->
  <app-getting-started></app-getting-started>
  
  <!-- Wireguard download links -->
  <app-wireguard-info></app-wireguard-info>

  <!-- Frequently Aaked Questions -->
  <app-faq></app-faq>

  <!-- Modal for Deleting a device -->
  <ng-template #deleteDevModal let-modal>
  <div class="modal-header card-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">DELETE DEVICE</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-delete-device-form [currentUser]="currentUser" [displayDeviceNumber]="displayDeviceNumber" (closeModalEvent)="modal.close($event)"></app-delete-device-form>
  </div>
  <div class="modal-footer card-footer">     
  </div>
  </ng-template>

  <!-- Modal for Updating a device -->
  <ng-template #updateDevModal let-modal>
  <div class="modal-header card-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">UPDATE DEVICE</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-update-device-form [currentUser]="currentUser" [displayDeviceNumber]="displayDeviceNumber" (closeModalEvent)="modal.close($event)"></app-update-device-form>
  </div>
  <div class="modal-footer card-footer">     
  </div>
  </ng-template>

  <!-- Modal for Adding new device -->
  <ng-template #addDevModal let-modal>
    <div class="modal-header card-header">
      <h4 class="modal-title text-danger" id="modal-basic-title">ADD NEW DEVICE</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-device-form [currentUser]="currentUser" (closeModalEvent)="modal.close($event)"></app-add-device-form>
    </div>
    <div class="modal-footer card-footer">     
    </div>
  </ng-template>

  <!-- Modal for Updating a subscription -->
  <ng-template #updateSubscriptionModal let-modal>
    <div class="modal-header card-header">
      <h4 class="modal-title text-danger" id="modal-basic-title">UPDATE SUBSCRIPTION</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-update-subscription-form [currentUser]="currentUser" (closeModalEvent)="modal.close($event)"></app-update-subscription-form>
    </div>
    <div class="modal-footer card-footer">     
    </div>
  </ng-template>

</ng-template>