<!-- SHOW HERO IMAGE -->
<app-hero></app-hero>

<div class="container-fluid segmentDark" style="height: 30px;">
</div>

<!-- WHAT IS A VPN AND WHY DO I NEED ONE -->
<app-vpninfo></app-vpninfo>

<!-- WHY GREYHEX -->
<app-why-greyhex></app-why-greyhex>

<!-- SUBSCRIPTION PRICING -->
<app-pricing-info></app-pricing-info>

<!-- HOW TO USE GREYHEX VPN -->
<app-getting-started></app-getting-started>

<!-- WIREGUARD CLIENT -->
<app-wireguard-info></app-wireguard-info>

<!-- FOUNDERS INFO -->
<app-founders-info></app-founders-info>

<!-- FOUNDERS PROMISE -->
<app-founders-promise></app-founders-promise>

<!-- Frequently Aaked Questions -->
<app-faq></app-faq>

<!-- Meta Search Engine -->
<app-meta-search-engine></app-meta-search-engine>