import { Component, OnInit } from '@angular/core';
//import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { faToggleOn, faEdit, faTasks, faDollarSign, faUserPlus, faTrashAlt, faHandPaper, faCalendarDay, faCalendarAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faCloudversify, faAppStoreIos, faAndroid, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  //providers: [NgbCarouselConfig],
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  //font icons
  faToggleOn = faToggleOn;
  faEdit = faEdit;
  faLinux = faLinux;
  faWindows = faWindows;
  faAndroid = faAndroid;
  faAppStoreIos = faAppStoreIos;
  faTasks = faTasks;
  faDollarSign = faDollarSign;
  faUserPlus = faUserPlus;
  faTrashAlt = faTrashAlt;
  faHandPaper = faHandPaper;
  faCloudversify = faCloudversify;
  faCalendarDay = faCalendarDay;
  faCalendarAlt = faCalendarAlt;
  faCloudDownloadAlt = faCloudDownloadAlt;

  //ngClasses
  public outsideSpacer = {
    "row": true,
    "bodySpacer": true
  };

  //ng-bootstrap config
  showNavigationArrows = false;
  showNavigationIndicators = false;
 
  // constructor(config: NgbCarouselConfig) {
  //   config.showNavigationArrows = false;
  //   config.showNavigationIndicators = false;
  //  }

   constructor() {

   }

  ngOnInit(): void {
  }
}
