<!-- DOWNLOADS -->
<div class="container-fluid segmentLight" id="download">

    <div class="row py-5">

        <div class="card-deck mx-auto">
            <div class="card card3D-lg card3D-light cardbg-dark mx-auto">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faCloudDownloadAlt"></fa-icon> GREYHEX VPN DOWNLOADS</h4>
                    <hr class="border-danger">
                    <div class="card-deck mx-auto">
                        <div class="card card3D-sm card3D-dark cardbg-light mx-auto my-3">
                            <div class="card-header">
                                <h5 class="card-title text-center">Wireguard Client</h5>
                            </div>
                            <ul class="list-group list-group-flush">
                                <a href="https://play.google.com/store/apps/details?id=com.wireguard.android" rel="nofollow" target="_blank" class="list-group-item list-group-item-light list-group-item-action text-left"><fa-icon [icon]="faAndroid"></fa-icon><span class="ml-3"> Android</span></a>
                                <a href="https://apps.apple.com/us/app/wireguard/id1441195209" rel="nofollow" target="_blank" class="list-group-item list-group-item-light list-group-item-action text-left"><fa-icon [icon]="faAppStoreIos"></fa-icon><span class="ml-3"> iPhone</span></a>
                                <a href="https://www.wireguard.com/install/" rel="nofollow" target="_blank" class="list-group-item list-group-item-light list-group-item-action text-left"><fa-icon [icon]="faLinux"></fa-icon><span class="ml-3"> Linux</span></a>
                                <a href="https://apps.apple.com/us/app/wireguard/id1451685025?ls=1&mt=12" rel="nofollow" target="_blank" class="list-group-item list-group-item-light list-group-item-action text-left"><fa-icon [icon]="faAppStoreIos"></fa-icon><span class="ml-3"> MacOS</span></a>
                                <a href="https://www.wireguard.com/install/" rel="nofollow" target="_blank" class="list-group-item list-group-item-light list-group-item-action text-left"><fa-icon [icon]="faWindows"></fa-icon><span class="ml-3"> Windows 10</span></a>
                                
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>