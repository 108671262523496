<!-- ensure 100% height -->
<div id="wrap" class="darkMode">
    <!-- ensure 100% width -->
    <div id="main" class="clear-top text-white">
        <app-header></app-header>
    </div>
</div>    

<footer class="footer">
    <app-footer></app-footer>
</footer>