<div class="container-fluid segmentDark">

    <div class="row pt-5">

        <div class="col">
 
            <!-- FREQUENTLY ASKED QUESTIONS BUTTON -->
            <div class="text-center mb-5">
                <button class="btn btn-light text-dark text-center card-text" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="faqRootCollapse">
                    <h4 class="px-5">
                        <fa-icon [icon]="faQuestionCircle"></fa-icon>
                        <span class="ml-3">
                            FREQUENTLY ASKED QUESTIONS
                        </span>
                    </h4>
                </button>
            </div>
        
            <!-- COLLAPSEABLE QUESTIONS -->
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                <ngb-accordion [closeOthers]="true">
                    
                    <!-- Question 1 -->
                    <ngb-panel id="question1">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                What is a VPN?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                VPN stands for Virtual Private Network and creates a secure tunnel for all of your internet traffic.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    
                    <!-- Question 2 -->
                    <ngb-panel id="question2">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                Why did we create GreyHex? 
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                GreyHex was created to provide maximally secure access to the internet and protect sensitive data and information to the best capacity possible.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 3 -->
                    <ngb-panel id="question3">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                Does a VPN help with online privacy?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                VPNs are one of many tools used to enhance privacy. VPNs will not solve all privacy problems and should be used in conjection with other tools.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 4 -->
                    <ngb-panel id="question4">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                What is Wireguard?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Wireguard is a fast, modern, secure type of VPN tunnel.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 5 -->
                    <ngb-panel id="question5">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                Why use Wireguard?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                The Wireguard App is open sourced meaning all the code is visible for review before connecting to our service. Peer reviewed code improves service trust. 
                            </p>
                        </ng-template>
                    </ngb-panel>
                    
                    <!-- Question 6 -->
                    <ngb-panel id="question6">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                Does GreyHex VPN block ads, malware, and trackers? 
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                By default Greyhex VPN attempts to block ads, malware, and tracker links on sites and services you visit. Since these things are continually evolving, not all will be blocked. However, with the DNS filtering option these things are more agressively blocked while minimizing impact to your browsing experience.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 7 -->
                    <ngb-panel id="question7">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                What is DNS Filtering?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                DNS Filtering goes a step beyond our regular Ad blocking service. DNS Filtering adds community curated lists and expands the blocked site links by the thousands. 
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 8 -->
                    <ngb-panel id="question8">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                Why do some apps still show ads?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Some services, i.e.Facebook and instagram embed Ads directly within their app and those cannot be blocked by Greyhex VPN. 
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 9 -->
                    <ngb-panel id="question9">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                The Wireguard tunnel keeps turning off, what can I do?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Enabled "On-Demand" within the wireguard app by selecting your connection profile, hitting Edit and then tapping the "On-Demand" options for both Cellular and Wi-Fi. 
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 10 -->
                    <ngb-panel id="question10">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                How do I configure my connection in the wireguard app?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Simply download the config file from your account on vpn.Greyhex.io and import it to the Wireguard App.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 11 -->
                    <ngb-panel id="question11">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                How do I import my configuration file on iPhone?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Login to vpn.greyhex.io on your mobile device and click the configuration button for your device. When the blue circle appears in the address bar, tap it and then tap downloads. Choose the GreyHex VPN configuration file and tap the boxed arrow icon. Finally choose the wireguard app to complete the import process.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 12 -->
                    <ngb-panel id="question12">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                How do I import my configuration file on my computer?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Login to vpn.greyhex.io on your computer and click the configuration button for your device. The file will be downoaded to your typical downloads location. Open the wireguard application and click "import Tunnels from File." Choose the downloaded configuration file from your typical downloads location.
                            </p>
                        </ng-template>
                    </ngb-panel>

                    <!-- Question 13 -->
                    <ngb-panel id="question13">
                        <ng-template ngbPanelTitle>
                            <p class="lead panelTitle text-left"> 
                                How can I tell if I am connected to the service?
                            </p>
                        </ng-template> 
                        <ng-template ngbPanelContent>
                            <p class="card-text text-dark">
                                Within the wireguard app, you can select your active connection and see the numbers for data sent and data received increase as well as the latest handshake time.
                            </p>
                        </ng-template>
                    </ngb-panel>
        
                </ngb-accordion>
            </div>

        </div>

    </div>

</div>

