import { Component, OnInit } from '@angular/core';
import { faQuoteLeft, faQuoteRight, faAddressBook } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-founders-info',
  templateUrl: './founders-info.component.html',
  styleUrls: ['./founders-info.component.css']
})
export class FoundersInfoComponent implements OnInit {


  faQuoteLeft = faQuoteLeft;
  faQuoteRight = faQuoteRight;
  faAddressBook = faAddressBook;

  constructor() { }

  ngOnInit(): void {
  }

}
