import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddDeviceFormModel } from 'src/Types/add-device-form-model';
import { User } from '../../Types/user.model';
import { faSignature, faPlusSquare, faMobileAlt, faFilter, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'; 

@Component({
  selector: 'app-add-device-form',
  templateUrl: './add-device-form.component.html',
  styleUrls: ['./add-device-form.component.css']
})
export class AddDeviceFormComponent implements OnInit {

/* -------------------------------------------------------------------------- */
/*                              GLOBAL VARIABLES                              */
/* -------------------------------------------------------------------------- */

  @Input() currentUser : User;
  @Output() closeModalEvent = new EventEmitter<string>();

/* ------------------------------ ICON HANDLES ------------------------------ */

  faSignature = faSignature;
  faMobileAlt = faMobileAlt;
  faFilter = faFilter;
  faGlobeAmericas = faGlobeAmericas;
  faPlusSquare = faPlusSquare;

/* ----------------------------- STATE VARIABLES ---------------------------- */

  addDeviceResults: AddDeviceFormModel = new AddDeviceFormModel("0",0,false," ",null,"0",false,null,null);
  model: AddDeviceFormModel = new AddDeviceFormModel("0",0,false,"Your Device Name",null,"0",false,null,null);  
  submitted = false;
  hasDNSSubscription = false;

/* --------------------------------- HANDLES -------------------------------- */

  /**
   * Input handle for creating a device. Get data from user input
   * and overwrite defaults.
   */
  createDeviceInput = {
    //id : "", // Auto Assigned
    devName: "Your Device Name", // user should tell us the device name
    userID: "0000000000", // needs to be current user ID
    deviceTypeID: "0", // user should provide the device type for us to use based on current options.
    deviceStatusID: "0", // all devices start on "Default" status (0) for middleware processing.
    dnsFiltering: null, // Default to false, but ask user which they want.
    lastActive: "2021-01-01T01:01:01.001Z"
  };

  /**
   * Input handle for regions on a device
   */
  entryRegionInput = {
    //id: "0", automatically generated dont send anything
    devID:"0000000000", // should be overwritten with devID from CreateDeviceInput
    entryRegionID:"0", //overwrite with the region from createDeviceInput
    lastActive: "2021-04-24T16:44:26.308Z" //set the time to 0 so it adds the field in the table for later manipulation
  };

  /**
   * Input handle for regions on a device
   */
  exitRegionInput = {
    //id: "0", automatically generated dont send anything
    devID:"0000000000", // should be overwritten with devID from CreateDeviceInput
    exitRegionID:"0", //overwrite with the region from createDeviceInput
  };

  /**
   * Handle for Device type options from the backend.
   */
  deviceTypeOptions: { 
    __typename: "devType"; 
    id: string; 
    devType: string; 
    createdAt: string; 
    updatedAt: string; 
    _version: number; 
    _deleted: boolean; 
    _lastChangedAt: number; 
  }[];

  /**
   * Handle for Region options from the backend 
   */
  regionOptions: { 
    __typename: "region"; 
    id: string; 
    regionName: string; 
    publicKey: string | null; 
    privateKey: string | null; 
    serverName: string | null; 
    awsRegion: string | null; 
    fQDN: string | null; 
    ipAddress: string | null; 
    ipTablesPostUp: string | null; 
    ipTablesPostDown: string | null; 
    createdAt: string | null; 
    updatedAt: string | null; 
    prettyName: string | null; 
    _version: number; 
    _deleted: boolean | null; 
    _lastChangedAt: number; 
  }[];

  // handle for current subscription data for user in the back end.
  currentSubData = {
    __typename: "sub",
    _deleted: null,
    _lastChangedAt: 1,
    _version: 1,
    currentDNSFiltering: 0,
    currentSubDevCount: 0,
    devSubExpirationDate: "1970-01-01T00:00:00.000Z",
    devSubNextDueAmount: 0.00,
    devSubNextDueDate: "1970-01-01T00:00:00.000Z",
    devSubStartDate: "1970-01-01T00:00:00.000Z",
    dnsSubExpirationDate: "1970-01-01T00:00:00.000Z",
    dnsSubNextDueAmount: 0.00,
    dnsSubNextDueDate: "1970-01-01T00:00:00.000Z",
    dnsSubStartDate: "1970-01-01T00:00:00.000Z",
    id: "00000000-0000-0000-0000-000000000000",
    lastPaidAmount: 0.00,
    lastPaidDate: "1970-01-01T00:00:00.000Z",
    lastTransactionID: "0000001",
    lastTransactionUpdate: "1970-01-01T00:00:00.000Z",
    paymentEmail: "guest@greyhex.io",
    pendingDNSFiltering: 0,
    pendingSubDevCount: 0,
    status: "approved",
    sub_Token: "",
    transactionDNSFiltering: 0,
    transactionSubDevCount: 0,
    terminationDate: null
  };
  


  /**
   * no values required
   */
  constructor() {
  
  }  

  /**
   * Executed on initilization 
   */
  ngOnInit(): void {

    this.getDeviceTypeOptions();
    this.getDeviceRegionOptions();

    if(this.currentUser.backendUserData.subStatusID == '0') {
      
      // we stick with defaults since they don't have a subscription record.
      this.hasDNSSubscription = false;

    } else {
      //get the backend user data
      this.updateUserSubscriptionData().then( event => {

        if (event != null) {
          
          //assign results if they exist, otherwise use defaults
          event._lastChangedAt != 1 ? this.currentSubData = event : this.currentSubData;
          this.currentSubData.currentDNSFiltering > 0 ? this.hasDNSSubscription = true : this.hasDNSSubscription = false;

        } else {

          this.hasDNSSubscription = false;

        }
        
       } );

    }
  }

  /**
   * obtains viable device options from the backend
   */
  private async getDeviceTypeOptions(): Promise<any> {
    await this.currentUser.api.ListDevTypes().then( event => {
      this.deviceTypeOptions = event.items;
      if(event.items.length > 1 ) {
        this.model.deviceType = event.items[1];
      }      
    }).catch( e => {
      console.log("unable to get the list of device types from the backend.", e);
    });
  }

  /**
   * obtains viable device region options from the backend
   */
  private async getDeviceRegionOptions(): Promise<any> {
    await this.currentUser.api.ListRegions().then(event => {this.regionOptions = event.items}).catch(e => {console.log("unable to get the list of region options from the backend.", e);});
  }

  /**
   * track the form state after submission
   */
  onSubmit(): void {
    this.submitted = true;
  }

  /**
   * update the submission handle and reset the model for future additions
   */
  submitAddDeviceResults(): void {
    //get data from user's choices and their backend identifier. sicne the device is new set the status to default (0).
    this.addDeviceResults = this.model;
    this.addDeviceResults.deviceStatusID = "0";
    this.addDeviceResults.userID = this.currentUser.backendUserData.userID;

    this.model = new AddDeviceFormModel("0",0,false,"Your Device Name",null,"0",false,null,null);
    this.createBackendDevice();
    this.closeModalEvent.emit("Create clicked");
  }

  /**
   * Make a connection to the backend and submit the new device details then, subsequent region details
   */
  createBackendDevice() {
    this.createDeviceInput.userID = this.addDeviceResults.userID;
    this.createDeviceInput.devName = this.addDeviceResults.deviceName;
    this.createDeviceInput.deviceStatusID = this.addDeviceResults.deviceStatusID;
    this.createDeviceInput.deviceTypeID = this.addDeviceResults.deviceType.id;
    this.createDeviceInput.dnsFiltering = this.addDeviceResults.dnsFiltering;
    this.createDeviceInput.lastActive = "2021-04-24T16:44:26.308Z"

    this.currentUser.api.CreateDev(this.createDeviceInput).then(
      (e) => {
        this.addDeviceResults.entryRegionChoices.forEach( async (entryRegionChoice) => {
          var localEntryRegionInput: {
            devID: string;
            entryRegionID: string;
            lastActive: string;
          } = this.entryRegionInput;
          localEntryRegionInput.devID = e.id;
          localEntryRegionInput.entryRegionID = entryRegionChoice.id;
          localEntryRegionInput.lastActive = "2021-04-24T16:44:26.308Z";
          await this.addNewDeviceEntryRegionToBackend(localEntryRegionInput).catch(e => {this.currentUser.consoleDebug ? console.log("Unable to add Entry Region to the backend",e) : console.log();});
        });
        this.addDeviceResults.exitRegionChoices.forEach( async (exitRegionChoice) => {
          var localExitRegionInput: {
            devID: string;
            exitRegionID: string;            
          } = this.exitRegionInput;
          localExitRegionInput.devID = e.id;
          localExitRegionInput.exitRegionID = exitRegionChoice.id;
          await this.addNewDeviceExitRegionToBackend(localExitRegionInput).catch(e => {this.currentUser.consoleDebug ? console.log("Unable to add Exit Region to the backend",e) : console.log();});
        })
      }
    ).catch(e => {this.currentUser.consoleDebug ? console.log("Unable to add device to the backend",e) : console.log();});

  }

  /**
   * Send the new device's Entry Region information to the backend.
   */
   async addNewDeviceEntryRegionToBackend(regionHandle): Promise<any> {
    var localRegionHandle = {
      devID: regionHandle.devID,
      entryRegionID: regionHandle.entryRegionID
    }; 
   await this.currentUser.api.CreateDevEntryRegion(localRegionHandle).then(
     event => {
       this.currentUser.consoleDebug ? console.log(event.entryRegion.regionName, " has been added to ",event.devID, event) : console.log();
     }
   ).catch(e => {console.log("error writing new device's Entry Region to backend.", e);});
 }

 /**
  * Send the new device's exit Region information to the backend.
  */
 async addNewDeviceExitRegionToBackend(regionHandle): Promise<any> {
   var localRegionHandle = {
     devID: regionHandle.devID,
     exitRegionID: regionHandle.exitRegionID
   };
   await this.currentUser.api.CreateDevExitRegion(localRegionHandle).then(
     event => {
       this.currentUser.consoleDebug ? console.log(event.exitRegion.regionName, " has been added to ",event.devID, event) : console.log();
     }
   ).catch(e => {console.log("error writing new device's Exit Region to backend.", e);});
 }

 /**
  * copy the Exit Region choice into entry region since the field is hidden.
  * This way we do not have to modify the codebase once exit region functionality is added, we unhide and stop using this function on ngMOdelChange for exit region.
  */
 updateEntryRegion(): any {
  this.model.entryRegionChoices = this.model.exitRegionChoices;
 }

 /**
  * checks to see if the user has a DNS subscription
  * @returns hasDNSSubscription as bool
  */
 HasDNSSubscription(): boolean {
  
  this.currentSubData.currentDNSFiltering > 0 ? this.hasDNSSubscription = true : this.hasDNSSubscription = false;
  return this.hasDNSSubscription;
 }

  async updateUserSubscriptionData(): Promise<any> {
    //handle for results of action
    var results;
    
    try {

      //query the back end to see if the current user has subscription data by their cognito ID (not backendID)
      results = await this.currentUser.api.GetSub(this.currentUser.backendUserData.userID);

    } catch (err) {

      results = err.toString();

    }

    return results;

  }


}
