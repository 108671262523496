import { Component, OnInit } from '@angular/core';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { User } from '../../Types/user.model';
import { BackEndUserData } from 'src/Types/back-end-user-data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

/* --------------------------------- HANDLES -------------------------------- */

  currentUser : User = new User ();
  intervalID: any;

/* ------------------------------ DISPLAY ICONS ----------------------------- */

  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;

/* ----------------------------- DISPLAY STATES ----------------------------- */

  public MenuCollapsed = true;
  public outside = true;

/* --------------------------- NGCLASSES (display) -------------------------- */

  public outsideNav = {
    "navbar": true,
    "fixed-top": true,
    "navbar-expand-lg": true,
    "navbar-light": true,
    "navbar-dark": false,
    "navbarIn": false,
    "navbarOut": true
  };

  public insideNav = {
    "navbar": true,
    "fixed-top": true,
    "navbar-expand-lg": true,
    "navbar-light": false,
    "navbar-dark": true,
    "navbarIn": true,
    "navbarOut": false
  };

  public outsideBrand = {
    "navbar-brand": true,
    "brandOut": true,
    "ml-0": true,
    "mr-1": true,
    "my-0": true,
    "p-0": true
  };

  public outsideBrand2 = {
    "brandOut2": true
  };

  public insideBrand = {
    "navbar-brand": true,
    "brandIn": true,
    "ml-0": true,
    "mr-1": true,
    "my-0": true,
    "p-0": true
  };

  public insideBrand2 = {
    "brandIn2": true
  };

/* ------------------------------- CONSTRUCTOR ------------------------------ */

  constructor(public router:Router) { }

/* -------------------------------------------------------------------------- */
/*                                 NG METHODS                                 */
/* -------------------------------------------------------------------------- */

  ngOnInit(): void {
    //wait 2 sseconds then verify user data received from backend
    setTimeout(() => {this.userRefresh()}, 2000);
    //every 60 seconds refresh backend user data
    this.intervalID = setInterval(() => {
      this.currentUser.refreshBackendData(); 
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

/* -------------------------------------------------------------------------- */
/*                              PUBLIC FUNCTIONS                              */
/* -------------------------------------------------------------------------- */

/* ------------------------------ Status Checks ----------------------------- */

  async userRefresh() : Promise<void>
  {
    this.currentUser.consoleDebug ? console.log("Welcome to GreyHex VPN!") : console.log();
    this.currentUser.consoleDebug ? console.log(this.currentUser.email, "You are currently", (this.currentUser.loggedIn ? "logged in" : "logged out")) : console.log();
  }

  isMenuCollapsed() {
    return this.MenuCollapsed;
  }

  isLogedIn() {
    return this.currentUser.loggedIn;
  }

/* --------------------------------- Actions -------------------------------- */

  collapseMenu() {
    this.MenuCollapsed = !this.MenuCollapsed;
  }

/* ------------------------- Navigation interactions ------------------------ */

  accountClicked()  {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  informationClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  signoutClicked() {
    if (this.MenuCollapsed == false)
    {
      this.collapseMenu();
    }
    this.signoutBackend();
  }

  public async signoutBackend() {
    this.currentUser.refreshBackendData().then( (e)=>{
       this.currentUser.api.UpdateUserSubDev({
        id: this.currentUser.backendUserData.id,
        signedOn: false,
        lastActive: new Date().toISOString(),
        _version: this.currentUser.backendUserData._version
      }).then( (e)=> {
        console.log(e);
        this.currentUser.backendUserData = new BackEndUserData();
        this.currentUser.email = "guest@greyhex.io";
        this.currentUser.username = "guest"
        setTimeout(() => {
          this.currentUser.loggedIn = false;
          Auth.signOut({ global: true });
        }, 1000);
      });
    });
  }

  pricingClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  downloadClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  devicesClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  subscriptionClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  preferencesClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

  searchClicked() {
    if (this.MenuCollapsed == false)
      this.collapseMenu();
  }

}
