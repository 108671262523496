export class DeleteDeviceFormModel {

    constructor(
        public devName: string,
        public id: string,
        public deviceStatusID: string = "4",
        public _version: number = 1,
        public _deleted: boolean = false
    ) {

    }
}