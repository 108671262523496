<form (ngSubmit)="onSubmit()" #deleteDeviceForm="ngForm">
    <!-- Delete Device Warning -->
    <div class="form-row mb-3">
        <div class="input-group">
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:12rem;"
                    for="devName"
                >
                <fa-icon 
                    [icon]="faMobileAlt" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;Device to delete:
                </label>
            </div>
            <input
                id="devName" 
                type="text"
                required
                readonly
                class="form-control"
                [(ngModel)]="model.devName"
                name="devName"
                placeholder="model.devName"
                #devName="ngModel"
            >
        </div>
        <div 
            [hidden]="devName.valid || devName.pristine"
            class="alert alert-danger input-group"
            role="alert"
        >
        A Device is required
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-auto ml-auto">
                <button type="submit" class="btn btn-danger" [disabled]="!deleteDeviceForm.form.valid" (click)="submitDeleteDeviceResults(); deleteDeviceForm.reset()">Delete&nbsp;<fa-icon [icon]="faTrash"></fa-icon></button>
            </div>
        </div>
        
    </div>
</form>