<!-- NAVBAR CONTAINER -->
<div class="container-fluid">
    
    <!-- FIRST ROW - FOR NAVIGATION -->
    <div class="row">

        <!-- NAVIGATION BAR -->
        <nav [ngClass]="outside ? outsideNav : insideNav">
            
            <!-- BRAND LINK -->
            <a [ngClass]="outside ? outsideBrand : insideBrand" href="https://Greyhex.io/" hreflang="en">
                <img src="../../assets/img/ghIcon2.png" alt="Greyhex" width="35" height="30">
                greyhex 
                <span [ngClass]="outside ? outsideBrand2 : insideBrand2">VPN</span>
            </a>
            
            
            <!-- COLLAPSE / TOGGLER ICON -->
            <button class="navbar-toggler" type="button" (click)="collapseMenu()" [attr.aria-expanded]="!MenuCollapsed" aria-controls="collapseBar">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <!-- COLLAPSABLE CONTENT -->
            <div id="collapseBar" [ngbCollapse]="MenuCollapsed" class="collapse navbar-collapse">

                <!-- NAVIGATION BUTTONS -->
                <div class="ml-auto">
                    <a href="Https://Greyhex.io/" hreflang="en" *ngIf="!isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="searchClicked()">Search</a>
                    <a href="#information" hreflang="en" *ngIf="!isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="informationClicked()">Information</a>
                    <a href="#pricing" hreflang="en" *ngIf="!isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="pricingClicked()">Pricing</a>
                    <a href="#download" hreflang="en" *ngIf="!isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="downloadClicked()">Download</a>
                    <!-- <a href="#devices" *ngIf="isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="devicesClicked()">Devices</a>
                    <a href="#subscription" *ngIf="isLogedIn()" class="btn btn-sm btn-dark mx-1 my-1" (click)="subscriptionClicked()">Subscription</a> -->
                    <a href="login" hreflang="en" *ngIf="!isLogedIn(); else accountElseBlock" class="btn btn-sm btn-danger nav-item mx-1 my-1" (click)="accountClicked()">Account <fa-icon [icon]="faSignInAlt"></fa-icon></a>
                    
                    <ng-template #accountElseBlock> <button type="button" class="btn btn-sm btn-danger nav-item mx-1 my-1" (click)="signoutClicked()">Sign-out <fa-icon [icon]="faSignOutAlt"></fa-icon> </button> </ng-template>
                </div>                
            </div>
        </nav>
    </div>
</div>

<!-- ADD SPACER TO ACCOUNT FOR NAVIGATION BAR -->
<div class="container-fluid">
    <div class="row" style="height: 78px;">        
    </div>
</div>

<!-- SHOW CONTENT BASED ON USER STATUS OR USE ROUTER. -->
<div *ngIf="!isLogedIn(); else routerElseBlock"> <!-- User not logged in-->
    <div [ngSwitch]="(this.router.url)">    <!-- check current route -->
        <app-home *ngSwitchCase="'/'"></app-home> <!-- display home component. explicit just in case I want to pass variables.-->
        <app-home *ngSwitchCase="' '"></app-home> <!-- display home component. explicit just in case I want to pass variables.-->
        <app-home *ngSwitchCase=null></app-home> <!-- display home component. explicit just in case I want to pass variables.-->
        <app-login *ngSwitchCase="'/login'"></app-login>
        <!-- Default option -->
        <router-outlet *ngSwitchDefault></router-outlet> <!-- for all other cases not home or profile, and not having passed variables use router outlet.-->
    </div>
</div>
<ng-template #routerElseBlock> <!-- user is logged in-->
    <app-profile [currentUser]="currentUser"></app-profile> <!-- show user profile content and pass in user status.-->
</ng-template>

<!-- BOTTOM SPACER (MUST BE BIG ENOUGH TO FIT UNDER FOOTER) -->
<div class="container-fluid">
    <div class="row" style="height: 55px;"></div>
</div>




