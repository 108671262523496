import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    //  GO TO INFORMATION (DEFAULT ENTRY)
    path: "",
    component: HomeComponent // SHOW SALES PAGE
  },
  {
    //  GO TO SIGN UP (SIGNUP WAS CLICKED)
    path: "signup",
    component: LoginComponent //SHOW AMPLIFY AUTHENTICATOR
  },
  {
    //  GO TO LOG IN (MY ACCOUNT WAS CLICKED)
    path: "login",
    component: LoginComponent //SHOW AMPLIFY AUTHENTICATOR
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }