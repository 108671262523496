import { TestBed } from "@angular/core/testing";
import { Auth } from "aws-amplify";
import { setMaxListeners } from "process";
import { APIService } from '../app/API.service';
import { BackEndUserData } from "./back-end-user-data";


export class User {

  /**
   * Create the new instance of user and publicize the value for reference across the system.
   */
  constructor(
    public backendUserData = new BackEndUserData(),
    public consoleDebug = true,
    public api: APIService = new APIService(),
    public loggedIn: boolean = false,
    public username: string = "Guest",
    public email: string = "Guest@greyhex.io",
    public attrib : any = {},
    public loadingData: boolean = true) 
  {
      setTimeout(() => {this.initialize();}, 1200 ); 
  }

  /**
   * Verify the current users credentials with the Amazon Cognito service.
   * Then update local variables with associated user data from backend.  
   */
  private async initialize() {

    // check cookies for current user session
      await Auth.currentAuthenticatedUser()
      .then(
        async user => { 
            // set the session status, current user's name and cognito ID
            this.loggedIn = true;
            this.username = user.username;
            this.backendUserData.userID = user.username;

            // use the session information to get the users attributes
            await Auth.userAttributes(user)
            .then(
              //update the currentuser attributes
              async userAttributes => {
                //store all attributes in memory in case we need them again.
                this.attrib = userAttributes;
                //spin through the user's attributes looking values we need.
                userAttributes.forEach(
                  (e)=>{
                    if (e.getName() == "email")
                    { 
                      this.email = e.getValue();
                      this.backendUserData.email = e.getValue();
                    }
                  }
                );
               
                //query the back end to see if the current user exists by their Cognito ID (not backendID)
                await this.api.AccountsByUser(this.backendUserData.userID)
                .then(
                 async e => { 
                    //if the backend response is not null
                    if (e.items.length > 0 ) {

                      //if we what we got back is our current user 
                        //we only read the first user, becuase if there is more we have a major problem with our logic.
                      if(e.items[0].userID == this.backendUserData.userID) {
                        //Update any properties from the backend.
                        this.consoleDebug ? console.log("Pulling user properties from the backend.") : console.log() ;
                        
                        if(e.items[0].id != null) this.backendUserData.id = e.items[0].id;
                        if(e.items[0].subStatusID != null) this.backendUserData.subStatusID = e.items[0].subStatusID;
                        if(e.items[0].subStatus != null) this.backendUserData.subStatus = e.items[0].subStatus;
                        if(e.items[0].userStatusID != null) this.backendUserData.userStatusID = e.items[0].userStatusID;
                        if(e.items[0].userStatus != null) this.backendUserData.userStatus = e.items[0].userStatus;
                        if(e.items[0].deviceList != null) this.backendUserData.deviceList = e.items[0].deviceList;
                        if(e.items[0].signedOn != null) this.backendUserData.signedOn = e.items[0].signedOn;
                        if(e.items[0].lastActive != null) this.backendUserData.lastActive = e.items[0].lastActive;
                        if(e.items[0]._version != null) this.backendUserData._version = e.items[0]._version;
                        if(e.items[0]._deleted != null) this.backendUserData._deleted = e.items[0]._deleted;
                        
                        this.loadingData = false;
                        
                        this.updateSignedOn();
                        
                      } else {
                        //we did not get back our current user as the first result.
                        this.consoleDebug ? console.log(e, "err: User mismatch! How could we let this happen?") : console.log(); 
                      }
                    // We did not get anything back from the backend for this user
                    } else {
                      //add the new user to database
                      await this.api.CreateUserSubDev({
                        userID: this.backendUserData.userID,
                        email: this.backendUserData.email,
                        userStatusID: this.backendUserData.userStatusID,
                        subStatusID: this.backendUserData.subStatusID,
                        signedOn: this.backendUserData.signedOn
                        //lastActive: new Date().toISOString(), // can use this instead of .then( this.updatesignedOn() ) as seen below. 
                      })
                      .then(
                        (event) => {
                          this.consoleDebug ? console.log(this.backendUserData.userID, "has been added to the backend with the following email.", this.backendUserData.email) : console.log();
                          this.loadingData = false;
                          this.backendUserData.id = event.id;
                          this.updateSignedOn();
                        }
                      )
                      .catch( e => {this.consoleDebug ? console.log(e, "Err: Couldn't add user to the backed.") : console.log()} );                     
                    }
                  }
                )
                .catch(e => {this.consoleDebug ? console.log(e, "Err: Couldn't get the user from the backed.") : console.log()} );

              }
            )
            .catch(e => {this.consoleDebug ? console.log(e, "Err: Couldnt obtain UserAttributes from cognito serice") : console.log()} );
        }
      )
      .catch( e => {this.consoleDebug ? console.log(e, "Err: CurrentAuthenticatedUser Failure (user class validation)") : console.log()} );
  }

  /**
   * pulls the latest backend Data on request from backend
   */
  public async refreshBackendData() {
    if(this.backendUserData.id !=null && this.backendUserData.id != "0") {
      await this.api.GetUserSubDev(this.backendUserData.id).then( (user)=> {
        if(user.subStatusID != null) this.backendUserData.subStatusID = user.subStatusID;
        if(user.subStatus != null) this.backendUserData.subStatus = user.subStatus;
        if(user.userStatusID != null) this.backendUserData.userStatusID = user.userStatusID;
        if(user.userStatus != null) this.backendUserData.userStatus = user.userStatus;
        if(user.deviceList != null) this.backendUserData.deviceList = user.deviceList;
        if(user.signedOn != null) this.backendUserData.signedOn = user.signedOn;
        if(user.lastActive != null) this.backendUserData.lastActive = user.lastActive;
        if(user._version != null) this.backendUserData._version = user._version;
        if(user._deleted != null) this.backendUserData._deleted = user._deleted;

      }).catch( (e) => {
        this.consoleDebug ? console.log(e, "Err: Unable to get user data from the backend.") : console.log(); 
      }); 
    }
  }

  /**
   * pushes the signed on status and last active to backend
   * * signedOn = True
   * * lastActive = Current UTC DateTime
   */
  public async updateSignedOn() {
    if(this.backendUserData.id !=null && this.backendUserData.id != "0") {
      this.api.UpdateUserSubDev({
        id: this.backendUserData.id,
        signedOn: true,
        lastActive: new Date().toISOString(),
        _version: this.backendUserData._version
      }).catch((e) => {
        this.consoleDebug ? console.log(e, "Err: unable to update SignedOn and LastUpdate in backend") : console.log()
      });
    }
  }

  /**
   * pushes the last active time to backend
   * * LastActive = current UTC DateTime
   */
  public async updateLastActive() {
    if(this.backendUserData.id !=null && this.backendUserData.id != "0") {
      this.api.UpdateUserSubDev({
        id: this.backendUserData.id,
        lastActive: new Date().toISOString(),
        _version: this.backendUserData._version
      }).catch((e) => {
        this.consoleDebug ? console.log(e, "Err: unable to update LastActive in the backend") : console.log()
      });
    } else {
      console.log("id:", this.backendUserData.id, "isn't our user for updating lastActive")
    }
  }
  
}