/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateUserSubDevInput = {
  id?: string | null;
  userID: string;
  email: string;
  userStatusID: string;
  subStatusID: string;
  signedOn: boolean;
  lastActive?: string | null;
  _version?: number | null;
};

export type ModeluserSubDevConditionInput = {
  userID?: ModelIDInput | null;
  email?: ModelIDInput | null;
  userStatusID?: ModelIDInput | null;
  subStatusID?: ModelIDInput | null;
  signedOn?: ModelBooleanInput | null;
  lastActive?: ModelStringInput | null;
  and?: Array<ModeluserSubDevConditionInput | null> | null;
  or?: Array<ModeluserSubDevConditionInput | null> | null;
  not?: ModeluserSubDevConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateUserSubDevInput = {
  id: string;
  userID?: string | null;
  email?: string | null;
  userStatusID?: string | null;
  subStatusID?: string | null;
  signedOn?: boolean | null;
  lastActive?: string | null;
  _version?: number | null;
};

export type DeleteUserSubDevInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateDevInput = {
  id?: string | null;
  devName?: string | null;
  userID: string;
  deviceTypeID: string;
  deviceStatusID: string;
  dnsFiltering: boolean;
  devicePubKey?: string | null;
  devicePrivKey?: string | null;
  ipAddress?: string | null;
  lastActive?: string | null;
  _version?: number | null;
};

export type ModeldevConditionInput = {
  devName?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  deviceTypeID?: ModelIDInput | null;
  deviceStatusID?: ModelIDInput | null;
  dnsFiltering?: ModelBooleanInput | null;
  devicePubKey?: ModelStringInput | null;
  devicePrivKey?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  lastActive?: ModelStringInput | null;
  and?: Array<ModeldevConditionInput | null> | null;
  or?: Array<ModeldevConditionInput | null> | null;
  not?: ModeldevConditionInput | null;
};

export type UpdateDevInput = {
  id: string;
  devName?: string | null;
  userID?: string | null;
  deviceTypeID?: string | null;
  deviceStatusID?: string | null;
  dnsFiltering?: boolean | null;
  devicePubKey?: string | null;
  devicePrivKey?: string | null;
  ipAddress?: string | null;
  lastActive?: string | null;
  _version?: number | null;
};

export type DeleteDevInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateDevEntryRegionInput = {
  id?: string | null;
  devID: string;
  device?: Array<DevInput> | null;
  entryRegionID: string;
  lastActive?: string | null;
  confFile?: string | null;
  uploaded?: string | null;
  downloaded?: string | null;
  _version?: number | null;
};

export type DevInput = {
  id: string;
  devName?: string | null;
  userID: string;
  deviceTypeID: string;
  deviceStatusID: string;
  dnsFiltering: boolean;
  devicePubKey?: string | null;
  devicePrivKey?: string | null;
  ipAddress?: string | null;
  lastActive?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModeldevEntryRegionConditionInput = {
  devID?: ModelIDInput | null;
  entryRegionID?: ModelIDInput | null;
  lastActive?: ModelStringInput | null;
  confFile?: ModelStringInput | null;
  uploaded?: ModelStringInput | null;
  downloaded?: ModelStringInput | null;
  and?: Array<ModeldevEntryRegionConditionInput | null> | null;
  or?: Array<ModeldevEntryRegionConditionInput | null> | null;
  not?: ModeldevEntryRegionConditionInput | null;
};

export type UpdateDevEntryRegionInput = {
  id: string;
  devID?: string | null;
  device?: Array<DevInput> | null;
  entryRegionID?: string | null;
  lastActive?: string | null;
  confFile?: string | null;
  uploaded?: string | null;
  downloaded?: string | null;
  _version?: number | null;
};

export type DeleteDevEntryRegionInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateDevExitRegionInput = {
  id?: string | null;
  devID: string;
  device?: Array<DevInput> | null;
  exitRegionID: string;
  _version?: number | null;
};

export type ModeldevExitRegionConditionInput = {
  devID?: ModelIDInput | null;
  exitRegionID?: ModelIDInput | null;
  and?: Array<ModeldevExitRegionConditionInput | null> | null;
  or?: Array<ModeldevExitRegionConditionInput | null> | null;
  not?: ModeldevExitRegionConditionInput | null;
};

export type UpdateDevExitRegionInput = {
  id: string;
  devID?: string | null;
  device?: Array<DevInput> | null;
  exitRegionID?: string | null;
  _version?: number | null;
};

export type DeleteDevExitRegionInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateProductInput = {
  id?: string | null;
  SubTypeID: string;
  perdeviceDiscount?: number | null;
  perDeviceCost?: number | null;
  _version?: number | null;
};

export type ModelproductConditionInput = {
  SubTypeID?: ModelIDInput | null;
  perdeviceDiscount?: ModelFloatInput | null;
  perDeviceCost?: ModelFloatInput | null;
  and?: Array<ModelproductConditionInput | null> | null;
  or?: Array<ModelproductConditionInput | null> | null;
  not?: ModelproductConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProductInput = {
  id: string;
  SubTypeID?: string | null;
  perdeviceDiscount?: number | null;
  perDeviceCost?: number | null;
  _version?: number | null;
};

export type DeleteProductInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateRegionInput = {
  id?: string | null;
  regionName: string;
  publicKey?: string | null;
  privateKey?: string | null;
  serverName?: string | null;
  awsRegion?: string | null;
  fQDN?: string | null;
  ipAddress?: string | null;
  ipTablesPostUp?: string | null;
  ipTablesPostDown?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  prettyName?: string | null;
  _version?: number | null;
};

export type ModelregionConditionInput = {
  regionName?: ModelStringInput | null;
  publicKey?: ModelStringInput | null;
  privateKey?: ModelStringInput | null;
  serverName?: ModelStringInput | null;
  awsRegion?: ModelStringInput | null;
  fQDN?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  ipTablesPostUp?: ModelStringInput | null;
  ipTablesPostDown?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  prettyName?: ModelStringInput | null;
  and?: Array<ModelregionConditionInput | null> | null;
  or?: Array<ModelregionConditionInput | null> | null;
  not?: ModelregionConditionInput | null;
};

export type UpdateRegionInput = {
  id: string;
  regionName?: string | null;
  publicKey?: string | null;
  privateKey?: string | null;
  serverName?: string | null;
  awsRegion?: string | null;
  fQDN?: string | null;
  ipAddress?: string | null;
  ipTablesPostUp?: string | null;
  ipTablesPostDown?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  prettyName?: string | null;
  _version?: number | null;
};

export type DeleteRegionInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateUserStatusInput = {
  id?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModeluserStatusConditionInput = {
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeluserStatusConditionInput | null> | null;
  or?: Array<ModeluserStatusConditionInput | null> | null;
  not?: ModeluserStatusConditionInput | null;
};

export type UpdateUserStatusInput = {
  id: string;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteUserStatusInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateSubscriptionTypeInput = {
  id?: string | null;
  subType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelsubscriptionTypeConditionInput = {
  subType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelsubscriptionTypeConditionInput | null> | null;
  or?: Array<ModelsubscriptionTypeConditionInput | null> | null;
  not?: ModelsubscriptionTypeConditionInput | null;
};

export type UpdateSubscriptionTypeInput = {
  id: string;
  subType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteSubscriptionTypeInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateSubscriptionStatusInput = {
  id?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModelsubscriptionStatusConditionInput = {
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelsubscriptionStatusConditionInput | null> | null;
  or?: Array<ModelsubscriptionStatusConditionInput | null> | null;
  not?: ModelsubscriptionStatusConditionInput | null;
};

export type UpdateSubscriptionStatusInput = {
  id: string;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteSubscriptionStatusInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateDevTypeInput = {
  id?: string | null;
  devType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModeldevTypeConditionInput = {
  devType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeldevTypeConditionInput | null> | null;
  or?: Array<ModeldevTypeConditionInput | null> | null;
  not?: ModeldevTypeConditionInput | null;
};

export type UpdateDevTypeInput = {
  id: string;
  devType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteDevTypeInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateDevStatusInput = {
  id?: string | null;
  devStatus?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type ModeldevStatusConditionInput = {
  devStatus?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeldevStatusConditionInput | null> | null;
  or?: Array<ModeldevStatusConditionInput | null> | null;
  not?: ModeldevStatusConditionInput | null;
};

export type UpdateDevStatusInput = {
  id: string;
  devStatus?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version?: number | null;
};

export type DeleteDevStatusInput = {
  id?: string | null;
  _version?: number | null;
};

export type CreateSubInput = {
  id?: string | null;
  status?: string | null;
  currentSubDevCount?: number | null;
  pendingSubDevCount?: number | null;
  transactionSubDevCount?: number | null;
  currentDNSFiltering?: number | null;
  pendingDNSFiltering?: number | null;
  transactionDNSFiltering?: number | null;
  paymentEmail?: string | null;
  devSubStartDate?: string | null;
  devSubExpirationDate?: string | null;
  dnsSubStartDate?: string | null;
  dnsSubExpirationDate?: string | null;
  lastPaidDate?: string | null;
  lastPaidAmount?: number | null;
  devSubNextDueDate?: string | null;
  dnsSubNextDueDate?: string | null;
  devSubNextDueAmount?: number | null;
  dnsSubNextDueAmount?: number | null;
  sub_Token?: string | null;
  lastTransactionUpdate?: string | null;
  lastTransactionID?: string | null;
  terminationDate?: string | null;
  dateChangeRequired?: number | null;
  _version?: number | null;
};

export type ModelsubConditionInput = {
  status?: ModelStringInput | null;
  currentSubDevCount?: ModelIntInput | null;
  pendingSubDevCount?: ModelIntInput | null;
  transactionSubDevCount?: ModelIntInput | null;
  currentDNSFiltering?: ModelIntInput | null;
  pendingDNSFiltering?: ModelIntInput | null;
  transactionDNSFiltering?: ModelIntInput | null;
  paymentEmail?: ModelStringInput | null;
  devSubStartDate?: ModelStringInput | null;
  devSubExpirationDate?: ModelStringInput | null;
  dnsSubStartDate?: ModelStringInput | null;
  dnsSubExpirationDate?: ModelStringInput | null;
  lastPaidDate?: ModelStringInput | null;
  lastPaidAmount?: ModelFloatInput | null;
  devSubNextDueDate?: ModelStringInput | null;
  dnsSubNextDueDate?: ModelStringInput | null;
  devSubNextDueAmount?: ModelFloatInput | null;
  dnsSubNextDueAmount?: ModelFloatInput | null;
  sub_Token?: ModelStringInput | null;
  lastTransactionUpdate?: ModelStringInput | null;
  lastTransactionID?: ModelIDInput | null;
  terminationDate?: ModelStringInput | null;
  dateChangeRequired?: ModelIntInput | null;
  and?: Array<ModelsubConditionInput | null> | null;
  or?: Array<ModelsubConditionInput | null> | null;
  not?: ModelsubConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateSubInput = {
  id: string;
  status?: string | null;
  currentSubDevCount?: number | null;
  pendingSubDevCount?: number | null;
  transactionSubDevCount?: number | null;
  currentDNSFiltering?: number | null;
  pendingDNSFiltering?: number | null;
  transactionDNSFiltering?: number | null;
  paymentEmail?: string | null;
  devSubStartDate?: string | null;
  devSubExpirationDate?: string | null;
  dnsSubStartDate?: string | null;
  dnsSubExpirationDate?: string | null;
  lastPaidDate?: string | null;
  lastPaidAmount?: number | null;
  devSubNextDueDate?: string | null;
  dnsSubNextDueDate?: string | null;
  devSubNextDueAmount?: number | null;
  dnsSubNextDueAmount?: number | null;
  sub_Token?: string | null;
  lastTransactionUpdate?: string | null;
  lastTransactionID?: string | null;
  terminationDate?: string | null;
  dateChangeRequired?: number | null;
  _version?: number | null;
};

export type DeleteSubInput = {
  id?: string | null;
  _version?: number | null;
};

export type ModeluserSubDevFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  email?: ModelIDInput | null;
  userStatusID?: ModelIDInput | null;
  subStatusID?: ModelIDInput | null;
  signedOn?: ModelBooleanInput | null;
  lastActive?: ModelStringInput | null;
  and?: Array<ModeluserSubDevFilterInput | null> | null;
  or?: Array<ModeluserSubDevFilterInput | null> | null;
  not?: ModeluserSubDevFilterInput | null;
};

export type ModeldevFilterInput = {
  id?: ModelIDInput | null;
  devName?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  deviceTypeID?: ModelIDInput | null;
  deviceStatusID?: ModelIDInput | null;
  dnsFiltering?: ModelBooleanInput | null;
  devicePubKey?: ModelStringInput | null;
  devicePrivKey?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  lastActive?: ModelStringInput | null;
  and?: Array<ModeldevFilterInput | null> | null;
  or?: Array<ModeldevFilterInput | null> | null;
  not?: ModeldevFilterInput | null;
};

export type ModeldevEntryRegionFilterInput = {
  id?: ModelIDInput | null;
  devID?: ModelIDInput | null;
  entryRegionID?: ModelIDInput | null;
  lastActive?: ModelStringInput | null;
  confFile?: ModelStringInput | null;
  uploaded?: ModelStringInput | null;
  downloaded?: ModelStringInput | null;
  and?: Array<ModeldevEntryRegionFilterInput | null> | null;
  or?: Array<ModeldevEntryRegionFilterInput | null> | null;
  not?: ModeldevEntryRegionFilterInput | null;
};

export type ModeldevExitRegionFilterInput = {
  id?: ModelIDInput | null;
  devID?: ModelIDInput | null;
  exitRegionID?: ModelIDInput | null;
  and?: Array<ModeldevExitRegionFilterInput | null> | null;
  or?: Array<ModeldevExitRegionFilterInput | null> | null;
  not?: ModeldevExitRegionFilterInput | null;
};

export type ModelproductFilterInput = {
  id?: ModelIDInput | null;
  SubTypeID?: ModelIDInput | null;
  perdeviceDiscount?: ModelFloatInput | null;
  perDeviceCost?: ModelFloatInput | null;
  and?: Array<ModelproductFilterInput | null> | null;
  or?: Array<ModelproductFilterInput | null> | null;
  not?: ModelproductFilterInput | null;
};

export type ModelregionFilterInput = {
  id?: ModelIDInput | null;
  regionName?: ModelStringInput | null;
  publicKey?: ModelStringInput | null;
  privateKey?: ModelStringInput | null;
  serverName?: ModelStringInput | null;
  awsRegion?: ModelStringInput | null;
  fQDN?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  ipTablesPostUp?: ModelStringInput | null;
  ipTablesPostDown?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  prettyName?: ModelStringInput | null;
  and?: Array<ModelregionFilterInput | null> | null;
  or?: Array<ModelregionFilterInput | null> | null;
  not?: ModelregionFilterInput | null;
};

export type ModeluserStatusFilterInput = {
  id?: ModelIDInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeluserStatusFilterInput | null> | null;
  or?: Array<ModeluserStatusFilterInput | null> | null;
  not?: ModeluserStatusFilterInput | null;
};

export type ModelsubscriptionTypeFilterInput = {
  id?: ModelIDInput | null;
  subType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelsubscriptionTypeFilterInput | null> | null;
  or?: Array<ModelsubscriptionTypeFilterInput | null> | null;
  not?: ModelsubscriptionTypeFilterInput | null;
};

export type ModelsubscriptionStatusFilterInput = {
  id?: ModelIDInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelsubscriptionStatusFilterInput | null> | null;
  or?: Array<ModelsubscriptionStatusFilterInput | null> | null;
  not?: ModelsubscriptionStatusFilterInput | null;
};

export type ModeldevTypeFilterInput = {
  id?: ModelIDInput | null;
  devType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeldevTypeFilterInput | null> | null;
  or?: Array<ModeldevTypeFilterInput | null> | null;
  not?: ModeldevTypeFilterInput | null;
};

export type ModeldevStatusFilterInput = {
  id?: ModelIDInput | null;
  devStatus?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModeldevStatusFilterInput | null> | null;
  or?: Array<ModeldevStatusFilterInput | null> | null;
  not?: ModeldevStatusFilterInput | null;
};

export type ModelsubFilterInput = {
  id?: ModelIDInput | null;
  status?: ModelStringInput | null;
  currentSubDevCount?: ModelIntInput | null;
  pendingSubDevCount?: ModelIntInput | null;
  transactionSubDevCount?: ModelIntInput | null;
  currentDNSFiltering?: ModelIntInput | null;
  pendingDNSFiltering?: ModelIntInput | null;
  transactionDNSFiltering?: ModelIntInput | null;
  paymentEmail?: ModelStringInput | null;
  devSubStartDate?: ModelStringInput | null;
  devSubExpirationDate?: ModelStringInput | null;
  dnsSubStartDate?: ModelStringInput | null;
  dnsSubExpirationDate?: ModelStringInput | null;
  lastPaidDate?: ModelStringInput | null;
  lastPaidAmount?: ModelFloatInput | null;
  devSubNextDueDate?: ModelStringInput | null;
  dnsSubNextDueDate?: ModelStringInput | null;
  devSubNextDueAmount?: ModelFloatInput | null;
  dnsSubNextDueAmount?: ModelFloatInput | null;
  sub_Token?: ModelStringInput | null;
  lastTransactionUpdate?: ModelStringInput | null;
  lastTransactionID?: ModelIDInput | null;
  terminationDate?: ModelStringInput | null;
  dateChangeRequired?: ModelIntInput | null;
  and?: Array<ModelsubFilterInput | null> | null;
  or?: Array<ModelsubFilterInput | null> | null;
  not?: ModelsubFilterInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type CreateUserSubDevMutation = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateUserSubDevMutation = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteUserSubDevMutation = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateDevMutation = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDevMutation = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDevMutation = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateDevEntryRegionMutation = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDevEntryRegionMutation = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDevEntryRegionMutation = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateDevExitRegionMutation = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDevExitRegionMutation = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDevExitRegionMutation = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateProductMutation = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateProductMutation = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteProductMutation = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateRegionMutation = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateRegionMutation = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteRegionMutation = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateUserStatusMutation = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateUserStatusMutation = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteUserStatusMutation = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateSubscriptionTypeMutation = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSubscriptionTypeMutation = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSubscriptionTypeMutation = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateSubscriptionStatusMutation = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSubscriptionStatusMutation = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSubscriptionStatusMutation = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateDevTypeMutation = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDevTypeMutation = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDevTypeMutation = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateDevStatusMutation = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateDevStatusMutation = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteDevStatusMutation = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type CreateSubMutation = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSubMutation = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSubMutation = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type SyncUserSubDevsQuery = {
  __typename: "ModeluserSubDevConnection";
  items: Array<{
    __typename: "userSubDev";
    id: string;
    userID: string;
    email: string;
    deviceList: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    userStatusID: string;
    userStatus: {
      __typename: "userStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatus: {
      __typename: "subscriptionStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatusID: string;
    signedOn: boolean;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetUserSubDevQuery = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListUserSubDevsQuery = {
  __typename: "ModeluserSubDevConnection";
  items: Array<{
    __typename: "userSubDev";
    id: string;
    userID: string;
    email: string;
    deviceList: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    userStatusID: string;
    userStatus: {
      __typename: "userStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatus: {
      __typename: "subscriptionStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatusID: string;
    signedOn: boolean;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncDevsQuery = {
  __typename: "ModeldevConnection";
  items: Array<{
    __typename: "dev";
    id: string;
    devName: string | null;
    userID: string;
    deviceTypeID: string;
    deviceType: {
      __typename: "devType";
      id: string;
      devType: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    deviceStatusID: string;
    deviceStatus: {
      __typename: "devStatus";
      id: string;
      devStatus: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    dnsFiltering: boolean;
    devicePubKey: string | null;
    devicePrivKey: string | null;
    ipAddress: string | null;
    entryRegions: {
      __typename: "ModeldevEntryRegionConnection";
      items: Array<{
        __typename: "devEntryRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        entryRegionID: string;
        entryRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        lastActive: string | null;
        confFile: string | null;
        uploaded: string | null;
        downloaded: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegions: {
      __typename: "ModeldevExitRegionConnection";
      items: Array<{
        __typename: "devExitRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegionID: string;
        exitRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetDevQuery = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListDevsQuery = {
  __typename: "ModeldevConnection";
  items: Array<{
    __typename: "dev";
    id: string;
    devName: string | null;
    userID: string;
    deviceTypeID: string;
    deviceType: {
      __typename: "devType";
      id: string;
      devType: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    deviceStatusID: string;
    deviceStatus: {
      __typename: "devStatus";
      id: string;
      devStatus: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    dnsFiltering: boolean;
    devicePubKey: string | null;
    devicePrivKey: string | null;
    ipAddress: string | null;
    entryRegions: {
      __typename: "ModeldevEntryRegionConnection";
      items: Array<{
        __typename: "devEntryRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        entryRegionID: string;
        entryRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        lastActive: string | null;
        confFile: string | null;
        uploaded: string | null;
        downloaded: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegions: {
      __typename: "ModeldevExitRegionConnection";
      items: Array<{
        __typename: "devExitRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegionID: string;
        exitRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncDevEntryRegionsQuery = {
  __typename: "ModeldevEntryRegionConnection";
  items: Array<{
    __typename: "devEntryRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    entryRegionID: string;
    entryRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    lastActive: string | null;
    confFile: string | null;
    uploaded: string | null;
    downloaded: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetDevEntryRegionQuery = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListDevEntryRegionsQuery = {
  __typename: "ModeldevEntryRegionConnection";
  items: Array<{
    __typename: "devEntryRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    entryRegionID: string;
    entryRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    lastActive: string | null;
    confFile: string | null;
    uploaded: string | null;
    downloaded: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncDevExitRegionsQuery = {
  __typename: "ModeldevExitRegionConnection";
  items: Array<{
    __typename: "devExitRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegionID: string;
    exitRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetDevExitRegionQuery = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListDevExitRegionsQuery = {
  __typename: "ModeldevExitRegionConnection";
  items: Array<{
    __typename: "devExitRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegionID: string;
    exitRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncProductsQuery = {
  __typename: "ModelproductConnection";
  items: Array<{
    __typename: "product";
    id: string;
    SubTypeID: string;
    subType: {
      __typename: "subscriptionType";
      id: string;
      subType: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    perdeviceDiscount: number | null;
    perDeviceCost: number | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetProductQuery = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListProductsQuery = {
  __typename: "ModelproductConnection";
  items: Array<{
    __typename: "product";
    id: string;
    SubTypeID: string;
    subType: {
      __typename: "subscriptionType";
      id: string;
      subType: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    perdeviceDiscount: number | null;
    perDeviceCost: number | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncRegionsQuery = {
  __typename: "ModelregionConnection";
  items: Array<{
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetRegionQuery = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListRegionsQuery = {
  __typename: "ModelregionConnection";
  items: Array<{
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncUserStatusesQuery = {
  __typename: "ModeluserStatusConnection";
  items: Array<{
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetUserStatusQuery = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListUserStatussQuery = {
  __typename: "ModeluserStatusConnection";
  items: Array<{
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncSubscriptionTypesQuery = {
  __typename: "ModelsubscriptionTypeConnection";
  items: Array<{
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetSubscriptionTypeQuery = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListSubscriptionTypesQuery = {
  __typename: "ModelsubscriptionTypeConnection";
  items: Array<{
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncSubscriptionStatusesQuery = {
  __typename: "ModelsubscriptionStatusConnection";
  items: Array<{
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetSubscriptionStatusQuery = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListSubscriptionStatussQuery = {
  __typename: "ModelsubscriptionStatusConnection";
  items: Array<{
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncDevTypesQuery = {
  __typename: "ModeldevTypeConnection";
  items: Array<{
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetDevTypeQuery = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListDevTypesQuery = {
  __typename: "ModeldevTypeConnection";
  items: Array<{
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncDevStatusesQuery = {
  __typename: "ModeldevStatusConnection";
  items: Array<{
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetDevStatusQuery = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListDevStatussQuery = {
  __typename: "ModeldevStatusConnection";
  items: Array<{
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type SyncSubsQuery = {
  __typename: "ModelsubConnection";
  items: Array<{
    __typename: "sub";
    id: string;
    status: string | null;
    currentSubDevCount: number | null;
    pendingSubDevCount: number | null;
    transactionSubDevCount: number | null;
    currentDNSFiltering: number | null;
    pendingDNSFiltering: number | null;
    transactionDNSFiltering: number | null;
    paymentEmail: string | null;
    devSubStartDate: string | null;
    devSubExpirationDate: string | null;
    dnsSubStartDate: string | null;
    dnsSubExpirationDate: string | null;
    lastPaidDate: string | null;
    lastPaidAmount: number | null;
    devSubNextDueDate: string | null;
    dnsSubNextDueDate: string | null;
    devSubNextDueAmount: number | null;
    dnsSubNextDueAmount: number | null;
    sub_Token: string | null;
    lastTransactionUpdate: string | null;
    lastTransactionID: string | null;
    terminationDate: string | null;
    dateChangeRequired: number | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type GetSubQuery = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type ListSubsQuery = {
  __typename: "ModelsubConnection";
  items: Array<{
    __typename: "sub";
    id: string;
    status: string | null;
    currentSubDevCount: number | null;
    pendingSubDevCount: number | null;
    transactionSubDevCount: number | null;
    currentDNSFiltering: number | null;
    pendingDNSFiltering: number | null;
    transactionDNSFiltering: number | null;
    paymentEmail: string | null;
    devSubStartDate: string | null;
    devSubExpirationDate: string | null;
    dnsSubStartDate: string | null;
    dnsSubExpirationDate: string | null;
    lastPaidDate: string | null;
    lastPaidAmount: number | null;
    devSubNextDueDate: string | null;
    dnsSubNextDueDate: string | null;
    devSubNextDueAmount: number | null;
    dnsSubNextDueAmount: number | null;
    sub_Token: string | null;
    lastTransactionUpdate: string | null;
    lastTransactionID: string | null;
    terminationDate: string | null;
    dateChangeRequired: number | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type AccountsByUserQuery = {
  __typename: "ModeluserSubDevConnection";
  items: Array<{
    __typename: "userSubDev";
    id: string;
    userID: string;
    email: string;
    deviceList: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    userStatusID: string;
    userStatus: {
      __typename: "userStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatus: {
      __typename: "subscriptionStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatusID: string;
    signedOn: boolean;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type AccountsByEmailQuery = {
  __typename: "ModeluserSubDevConnection";
  items: Array<{
    __typename: "userSubDev";
    id: string;
    userID: string;
    email: string;
    deviceList: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    userStatusID: string;
    userStatus: {
      __typename: "userStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatus: {
      __typename: "subscriptionStatus";
      id: string;
      status: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    subStatusID: string;
    signedOn: boolean;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type DevicesByUserQuery = {
  __typename: "ModeldevConnection";
  items: Array<{
    __typename: "dev";
    id: string;
    devName: string | null;
    userID: string;
    deviceTypeID: string;
    deviceType: {
      __typename: "devType";
      id: string;
      devType: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    deviceStatusID: string;
    deviceStatus: {
      __typename: "devStatus";
      id: string;
      devStatus: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    dnsFiltering: boolean;
    devicePubKey: string | null;
    devicePrivKey: string | null;
    ipAddress: string | null;
    entryRegions: {
      __typename: "ModeldevEntryRegionConnection";
      items: Array<{
        __typename: "devEntryRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        entryRegionID: string;
        entryRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        lastActive: string | null;
        confFile: string | null;
        uploaded: string | null;
        downloaded: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegions: {
      __typename: "ModeldevExitRegionConnection";
      items: Array<{
        __typename: "devExitRegion";
        id: string;
        devID: string;
        device: {
          __typename: "ModeldevConnection";
          items: Array<{
            __typename: "dev";
            id: string;
            devName: string | null;
            userID: string;
            deviceTypeID: string;
            deviceType: {
              __typename: "devType";
              id: string;
              devType: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            deviceStatusID: string;
            deviceStatus: {
              __typename: "devStatus";
              id: string;
              devStatus: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            dnsFiltering: boolean;
            devicePubKey: string | null;
            devicePrivKey: string | null;
            ipAddress: string | null;
            entryRegions: {
              __typename: "ModeldevEntryRegionConnection";
              items: Array<{
                __typename: "devEntryRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                entryRegionID: string;
                entryRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                lastActive: string | null;
                confFile: string | null;
                uploaded: string | null;
                downloaded: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegions: {
              __typename: "ModeldevExitRegionConnection";
              items: Array<{
                __typename: "devExitRegion";
                id: string;
                devID: string;
                device: {
                  __typename: "ModeldevConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegionID: string;
                exitRegion: {
                  __typename: "region";
                  id: string;
                  regionName: string;
                  publicKey: string | null;
                  privateKey: string | null;
                  serverName: string | null;
                  awsRegion: string | null;
                  fQDN: string | null;
                  ipAddress: string | null;
                  ipTablesPostUp: string | null;
                  ipTablesPostDown: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  prettyName: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            lastActive: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegionID: string;
        exitRegion: {
          __typename: "region";
          id: string;
          regionName: string;
          publicKey: string | null;
          privateKey: string | null;
          serverName: string | null;
          awsRegion: string | null;
          fQDN: string | null;
          ipAddress: string | null;
          ipTablesPostUp: string | null;
          ipTablesPostDown: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          prettyName: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    lastActive: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type DevsByEntryRegionQuery = {
  __typename: "ModeldevEntryRegionConnection";
  items: Array<{
    __typename: "devEntryRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    entryRegionID: string;
    entryRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    lastActive: string | null;
    confFile: string | null;
    uploaded: string | null;
    downloaded: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type EntryRegionsByDevsQuery = {
  __typename: "ModeldevEntryRegionConnection";
  items: Array<{
    __typename: "devEntryRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    entryRegionID: string;
    entryRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    lastActive: string | null;
    confFile: string | null;
    uploaded: string | null;
    downloaded: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type DevsByExitRegionQuery = {
  __typename: "ModeldevExitRegionConnection";
  items: Array<{
    __typename: "devExitRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegionID: string;
    exitRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type ExitRegionsByDevsQuery = {
  __typename: "ModeldevExitRegionConnection";
  items: Array<{
    __typename: "devExitRegion";
    id: string;
    devID: string;
    device: {
      __typename: "ModeldevConnection";
      items: Array<{
        __typename: "dev";
        id: string;
        devName: string | null;
        userID: string;
        deviceTypeID: string;
        deviceType: {
          __typename: "devType";
          id: string;
          devType: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        deviceStatusID: string;
        deviceStatus: {
          __typename: "devStatus";
          id: string;
          devStatus: string | null;
          createdAt: string | null;
          updatedAt: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null;
        dnsFiltering: boolean;
        devicePubKey: string | null;
        devicePrivKey: string | null;
        ipAddress: string | null;
        entryRegions: {
          __typename: "ModeldevEntryRegionConnection";
          items: Array<{
            __typename: "devEntryRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            entryRegionID: string;
            entryRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            lastActive: string | null;
            confFile: string | null;
            uploaded: string | null;
            downloaded: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        exitRegions: {
          __typename: "ModeldevExitRegionConnection";
          items: Array<{
            __typename: "devExitRegion";
            id: string;
            devID: string;
            device: {
              __typename: "ModeldevConnection";
              items: Array<{
                __typename: "dev";
                id: string;
                devName: string | null;
                userID: string;
                deviceTypeID: string;
                deviceType: {
                  __typename: "devType";
                  id: string;
                  devType: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                deviceStatusID: string;
                deviceStatus: {
                  __typename: "devStatus";
                  id: string;
                  devStatus: string | null;
                  createdAt: string | null;
                  updatedAt: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null;
                dnsFiltering: boolean;
                devicePubKey: string | null;
                devicePrivKey: string | null;
                ipAddress: string | null;
                entryRegions: {
                  __typename: "ModeldevEntryRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                exitRegions: {
                  __typename: "ModeldevExitRegionConnection";
                  nextToken: string | null;
                  startedAt: number | null;
                } | null;
                lastActive: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null> | null;
              nextToken: string | null;
              startedAt: number | null;
            } | null;
            exitRegionID: string;
            exitRegion: {
              __typename: "region";
              id: string;
              regionName: string;
              publicKey: string | null;
              privateKey: string | null;
              serverName: string | null;
              awsRegion: string | null;
              fQDN: string | null;
              ipAddress: string | null;
              ipTablesPostUp: string | null;
              ipTablesPostDown: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              prettyName: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null> | null;
          nextToken: string | null;
          startedAt: number | null;
        } | null;
        lastActive: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null> | null;
      nextToken: string | null;
      startedAt: number | null;
    } | null;
    exitRegionID: string;
    exitRegion: {
      __typename: "region";
      id: string;
      regionName: string;
      publicKey: string | null;
      privateKey: string | null;
      serverName: string | null;
      awsRegion: string | null;
      fQDN: string | null;
      ipAddress: string | null;
      ipTablesPostUp: string | null;
      ipTablesPostDown: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      prettyName: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type RegionByNameQuery = {
  __typename: "ModelregionConnection";
  items: Array<{
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null> | null;
  nextToken: string | null;
  startedAt: number | null;
};

export type OnCreateUserSubDevSubscription = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateUserSubDevSubscription = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteUserSubDevSubscription = {
  __typename: "userSubDev";
  id: string;
  userID: string;
  email: string;
  deviceList: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  userStatusID: string;
  userStatus: {
    __typename: "userStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatus: {
    __typename: "subscriptionStatus";
    id: string;
    status: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  subStatusID: string;
  signedOn: boolean;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDevSubscription = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDevSubscription = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDevSubscription = {
  __typename: "dev";
  id: string;
  devName: string | null;
  userID: string;
  deviceTypeID: string;
  deviceType: {
    __typename: "devType";
    id: string;
    devType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  deviceStatusID: string;
  deviceStatus: {
    __typename: "devStatus";
    id: string;
    devStatus: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  dnsFiltering: boolean;
  devicePubKey: string | null;
  devicePrivKey: string | null;
  ipAddress: string | null;
  entryRegions: {
    __typename: "ModeldevEntryRegionConnection";
    items: Array<{
      __typename: "devEntryRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      entryRegionID: string;
      entryRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      lastActive: string | null;
      confFile: string | null;
      uploaded: string | null;
      downloaded: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegions: {
    __typename: "ModeldevExitRegionConnection";
    items: Array<{
      __typename: "devExitRegion";
      id: string;
      devID: string;
      device: {
        __typename: "ModeldevConnection";
        items: Array<{
          __typename: "dev";
          id: string;
          devName: string | null;
          userID: string;
          deviceTypeID: string;
          deviceType: {
            __typename: "devType";
            id: string;
            devType: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          deviceStatusID: string;
          deviceStatus: {
            __typename: "devStatus";
            id: string;
            devStatus: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          dnsFiltering: boolean;
          devicePubKey: string | null;
          devicePrivKey: string | null;
          ipAddress: string | null;
          entryRegions: {
            __typename: "ModeldevEntryRegionConnection";
            items: Array<{
              __typename: "devEntryRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              entryRegionID: string;
              entryRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              lastActive: string | null;
              confFile: string | null;
              uploaded: string | null;
              downloaded: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegions: {
            __typename: "ModeldevExitRegionConnection";
            items: Array<{
              __typename: "devExitRegion";
              id: string;
              devID: string;
              device: {
                __typename: "ModeldevConnection";
                items: Array<{
                  __typename: "dev";
                  id: string;
                  devName: string | null;
                  userID: string;
                  deviceTypeID: string;
                  deviceStatusID: string;
                  dnsFiltering: boolean;
                  devicePubKey: string | null;
                  devicePrivKey: string | null;
                  ipAddress: string | null;
                  lastActive: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegionID: string;
              exitRegion: {
                __typename: "region";
                id: string;
                regionName: string;
                publicKey: string | null;
                privateKey: string | null;
                serverName: string | null;
                awsRegion: string | null;
                fQDN: string | null;
                ipAddress: string | null;
                ipTablesPostUp: string | null;
                ipTablesPostDown: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                prettyName: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          lastActive: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegionID: string;
      exitRegion: {
        __typename: "region";
        id: string;
        regionName: string;
        publicKey: string | null;
        privateKey: string | null;
        serverName: string | null;
        awsRegion: string | null;
        fQDN: string | null;
        ipAddress: string | null;
        ipTablesPostUp: string | null;
        ipTablesPostDown: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        prettyName: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  lastActive: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDevEntryRegionSubscription = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDevEntryRegionSubscription = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDevEntryRegionSubscription = {
  __typename: "devEntryRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  entryRegionID: string;
  entryRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  lastActive: string | null;
  confFile: string | null;
  uploaded: string | null;
  downloaded: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDevExitRegionSubscription = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDevExitRegionSubscription = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDevExitRegionSubscription = {
  __typename: "devExitRegion";
  id: string;
  devID: string;
  device: {
    __typename: "ModeldevConnection";
    items: Array<{
      __typename: "dev";
      id: string;
      devName: string | null;
      userID: string;
      deviceTypeID: string;
      deviceType: {
        __typename: "devType";
        id: string;
        devType: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      deviceStatusID: string;
      deviceStatus: {
        __typename: "devStatus";
        id: string;
        devStatus: string | null;
        createdAt: string | null;
        updatedAt: string | null;
        _version: number;
        _deleted: boolean | null;
        _lastChangedAt: number;
      } | null;
      dnsFiltering: boolean;
      devicePubKey: string | null;
      devicePrivKey: string | null;
      ipAddress: string | null;
      entryRegions: {
        __typename: "ModeldevEntryRegionConnection";
        items: Array<{
          __typename: "devEntryRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          entryRegionID: string;
          entryRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          lastActive: string | null;
          confFile: string | null;
          uploaded: string | null;
          downloaded: string | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      exitRegions: {
        __typename: "ModeldevExitRegionConnection";
        items: Array<{
          __typename: "devExitRegion";
          id: string;
          devID: string;
          device: {
            __typename: "ModeldevConnection";
            items: Array<{
              __typename: "dev";
              id: string;
              devName: string | null;
              userID: string;
              deviceTypeID: string;
              deviceType: {
                __typename: "devType";
                id: string;
                devType: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              deviceStatusID: string;
              deviceStatus: {
                __typename: "devStatus";
                id: string;
                devStatus: string | null;
                createdAt: string | null;
                updatedAt: string | null;
                _version: number;
                _deleted: boolean | null;
                _lastChangedAt: number;
              } | null;
              dnsFiltering: boolean;
              devicePubKey: string | null;
              devicePrivKey: string | null;
              ipAddress: string | null;
              entryRegions: {
                __typename: "ModeldevEntryRegionConnection";
                items: Array<{
                  __typename: "devEntryRegion";
                  id: string;
                  devID: string;
                  entryRegionID: string;
                  lastActive: string | null;
                  confFile: string | null;
                  uploaded: string | null;
                  downloaded: string | null;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              exitRegions: {
                __typename: "ModeldevExitRegionConnection";
                items: Array<{
                  __typename: "devExitRegion";
                  id: string;
                  devID: string;
                  exitRegionID: string;
                  _version: number;
                  _deleted: boolean | null;
                  _lastChangedAt: number;
                } | null> | null;
                nextToken: string | null;
                startedAt: number | null;
              } | null;
              lastActive: string | null;
              _version: number;
              _deleted: boolean | null;
              _lastChangedAt: number;
            } | null> | null;
            nextToken: string | null;
            startedAt: number | null;
          } | null;
          exitRegionID: string;
          exitRegion: {
            __typename: "region";
            id: string;
            regionName: string;
            publicKey: string | null;
            privateKey: string | null;
            serverName: string | null;
            awsRegion: string | null;
            fQDN: string | null;
            ipAddress: string | null;
            ipTablesPostUp: string | null;
            ipTablesPostDown: string | null;
            createdAt: string | null;
            updatedAt: string | null;
            prettyName: string | null;
            _version: number;
            _deleted: boolean | null;
            _lastChangedAt: number;
          } | null;
          _version: number;
          _deleted: boolean | null;
          _lastChangedAt: number;
        } | null> | null;
        nextToken: string | null;
        startedAt: number | null;
      } | null;
      lastActive: string | null;
      _version: number;
      _deleted: boolean | null;
      _lastChangedAt: number;
    } | null> | null;
    nextToken: string | null;
    startedAt: number | null;
  } | null;
  exitRegionID: string;
  exitRegion: {
    __typename: "region";
    id: string;
    regionName: string;
    publicKey: string | null;
    privateKey: string | null;
    serverName: string | null;
    awsRegion: string | null;
    fQDN: string | null;
    ipAddress: string | null;
    ipTablesPostUp: string | null;
    ipTablesPostDown: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    prettyName: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateProductSubscription = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateProductSubscription = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteProductSubscription = {
  __typename: "product";
  id: string;
  SubTypeID: string;
  subType: {
    __typename: "subscriptionType";
    id: string;
    subType: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    _version: number;
    _deleted: boolean | null;
    _lastChangedAt: number;
  } | null;
  perdeviceDiscount: number | null;
  perDeviceCost: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateRegionSubscription = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateRegionSubscription = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteRegionSubscription = {
  __typename: "region";
  id: string;
  regionName: string;
  publicKey: string | null;
  privateKey: string | null;
  serverName: string | null;
  awsRegion: string | null;
  fQDN: string | null;
  ipAddress: string | null;
  ipTablesPostUp: string | null;
  ipTablesPostDown: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  prettyName: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateUserStatusSubscription = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateUserStatusSubscription = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteUserStatusSubscription = {
  __typename: "userStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSubscriptionTypeSubscription = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSubscriptionTypeSubscription = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSubscriptionTypeSubscription = {
  __typename: "subscriptionType";
  id: string;
  subType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSubscriptionStatusSubscription = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSubscriptionStatusSubscription = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSubscriptionStatusSubscription = {
  __typename: "subscriptionStatus";
  id: string;
  status: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDevTypeSubscription = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDevTypeSubscription = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDevTypeSubscription = {
  __typename: "devType";
  id: string;
  devType: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateDevStatusSubscription = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateDevStatusSubscription = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteDevStatusSubscription = {
  __typename: "devStatus";
  id: string;
  devStatus: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateSubSubscription = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSubSubscription = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSubSubscription = {
  __typename: "sub";
  id: string;
  status: string | null;
  currentSubDevCount: number | null;
  pendingSubDevCount: number | null;
  transactionSubDevCount: number | null;
  currentDNSFiltering: number | null;
  pendingDNSFiltering: number | null;
  transactionDNSFiltering: number | null;
  paymentEmail: string | null;
  devSubStartDate: string | null;
  devSubExpirationDate: string | null;
  dnsSubStartDate: string | null;
  dnsSubExpirationDate: string | null;
  lastPaidDate: string | null;
  lastPaidAmount: number | null;
  devSubNextDueDate: string | null;
  dnsSubNextDueDate: string | null;
  devSubNextDueAmount: number | null;
  dnsSubNextDueAmount: number | null;
  sub_Token: string | null;
  lastTransactionUpdate: string | null;
  lastTransactionID: string | null;
  terminationDate: string | null;
  dateChangeRequired: number | null;
  _version: number;
  _deleted: boolean | null;
  _lastChangedAt: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateUserSubDev(
    input: CreateUserSubDevInput,
    condition?: ModeluserSubDevConditionInput
  ): Promise<CreateUserSubDevMutation> {
    const statement = `mutation CreateUserSubDev($input: CreateUserSubDevInput!, $condition: ModeluserSubDevConditionInput) {
        createUserSubDev(input: $input, condition: $condition) {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserSubDevMutation>response.data.createUserSubDev;
  }
  async UpdateUserSubDev(
    input: UpdateUserSubDevInput,
    condition?: ModeluserSubDevConditionInput
  ): Promise<UpdateUserSubDevMutation> {
    const statement = `mutation UpdateUserSubDev($input: UpdateUserSubDevInput!, $condition: ModeluserSubDevConditionInput) {
        updateUserSubDev(input: $input, condition: $condition) {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserSubDevMutation>response.data.updateUserSubDev;
  }
  async DeleteUserSubDev(
    input: DeleteUserSubDevInput,
    condition?: ModeluserSubDevConditionInput
  ): Promise<DeleteUserSubDevMutation> {
    const statement = `mutation DeleteUserSubDev($input: DeleteUserSubDevInput!, $condition: ModeluserSubDevConditionInput) {
        deleteUserSubDev(input: $input, condition: $condition) {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserSubDevMutation>response.data.deleteUserSubDev;
  }
  async CreateDev(
    input: CreateDevInput,
    condition?: ModeldevConditionInput
  ): Promise<CreateDevMutation> {
    const statement = `mutation CreateDev($input: CreateDevInput!, $condition: ModeldevConditionInput) {
        createDev(input: $input, condition: $condition) {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDevMutation>response.data.createDev;
  }
  async UpdateDev(
    input: UpdateDevInput,
    condition?: ModeldevConditionInput
  ): Promise<UpdateDevMutation> {
    const statement = `mutation UpdateDev($input: UpdateDevInput!, $condition: ModeldevConditionInput) {
        updateDev(input: $input, condition: $condition) {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDevMutation>response.data.updateDev;
  }
  async DeleteDev(
    input: DeleteDevInput,
    condition?: ModeldevConditionInput
  ): Promise<DeleteDevMutation> {
    const statement = `mutation DeleteDev($input: DeleteDevInput!, $condition: ModeldevConditionInput) {
        deleteDev(input: $input, condition: $condition) {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDevMutation>response.data.deleteDev;
  }
  async CreateDevEntryRegion(
    input: CreateDevEntryRegionInput,
    condition?: ModeldevEntryRegionConditionInput
  ): Promise<CreateDevEntryRegionMutation> {
    const statement = `mutation CreateDevEntryRegion($input: CreateDevEntryRegionInput!, $condition: ModeldevEntryRegionConditionInput) {
        createDevEntryRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDevEntryRegionMutation>response.data.createDevEntryRegion;
  }
  async UpdateDevEntryRegion(
    input: UpdateDevEntryRegionInput,
    condition?: ModeldevEntryRegionConditionInput
  ): Promise<UpdateDevEntryRegionMutation> {
    const statement = `mutation UpdateDevEntryRegion($input: UpdateDevEntryRegionInput!, $condition: ModeldevEntryRegionConditionInput) {
        updateDevEntryRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDevEntryRegionMutation>response.data.updateDevEntryRegion;
  }
  async DeleteDevEntryRegion(
    input: DeleteDevEntryRegionInput,
    condition?: ModeldevEntryRegionConditionInput
  ): Promise<DeleteDevEntryRegionMutation> {
    const statement = `mutation DeleteDevEntryRegion($input: DeleteDevEntryRegionInput!, $condition: ModeldevEntryRegionConditionInput) {
        deleteDevEntryRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDevEntryRegionMutation>response.data.deleteDevEntryRegion;
  }
  async CreateDevExitRegion(
    input: CreateDevExitRegionInput,
    condition?: ModeldevExitRegionConditionInput
  ): Promise<CreateDevExitRegionMutation> {
    const statement = `mutation CreateDevExitRegion($input: CreateDevExitRegionInput!, $condition: ModeldevExitRegionConditionInput) {
        createDevExitRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDevExitRegionMutation>response.data.createDevExitRegion;
  }
  async UpdateDevExitRegion(
    input: UpdateDevExitRegionInput,
    condition?: ModeldevExitRegionConditionInput
  ): Promise<UpdateDevExitRegionMutation> {
    const statement = `mutation UpdateDevExitRegion($input: UpdateDevExitRegionInput!, $condition: ModeldevExitRegionConditionInput) {
        updateDevExitRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDevExitRegionMutation>response.data.updateDevExitRegion;
  }
  async DeleteDevExitRegion(
    input: DeleteDevExitRegionInput,
    condition?: ModeldevExitRegionConditionInput
  ): Promise<DeleteDevExitRegionMutation> {
    const statement = `mutation DeleteDevExitRegion($input: DeleteDevExitRegionInput!, $condition: ModeldevExitRegionConditionInput) {
        deleteDevExitRegion(input: $input, condition: $condition) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDevExitRegionMutation>response.data.deleteDevExitRegion;
  }
  async CreateProduct(
    input: CreateProductInput,
    condition?: ModelproductConditionInput
  ): Promise<CreateProductMutation> {
    const statement = `mutation CreateProduct($input: CreateProductInput!, $condition: ModelproductConditionInput) {
        createProduct(input: $input, condition: $condition) {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductMutation>response.data.createProduct;
  }
  async UpdateProduct(
    input: UpdateProductInput,
    condition?: ModelproductConditionInput
  ): Promise<UpdateProductMutation> {
    const statement = `mutation UpdateProduct($input: UpdateProductInput!, $condition: ModelproductConditionInput) {
        updateProduct(input: $input, condition: $condition) {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductMutation>response.data.updateProduct;
  }
  async DeleteProduct(
    input: DeleteProductInput,
    condition?: ModelproductConditionInput
  ): Promise<DeleteProductMutation> {
    const statement = `mutation DeleteProduct($input: DeleteProductInput!, $condition: ModelproductConditionInput) {
        deleteProduct(input: $input, condition: $condition) {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductMutation>response.data.deleteProduct;
  }
  async CreateRegion(
    input: CreateRegionInput,
    condition?: ModelregionConditionInput
  ): Promise<CreateRegionMutation> {
    const statement = `mutation CreateRegion($input: CreateRegionInput!, $condition: ModelregionConditionInput) {
        createRegion(input: $input, condition: $condition) {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRegionMutation>response.data.createRegion;
  }
  async UpdateRegion(
    input: UpdateRegionInput,
    condition?: ModelregionConditionInput
  ): Promise<UpdateRegionMutation> {
    const statement = `mutation UpdateRegion($input: UpdateRegionInput!, $condition: ModelregionConditionInput) {
        updateRegion(input: $input, condition: $condition) {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRegionMutation>response.data.updateRegion;
  }
  async DeleteRegion(
    input: DeleteRegionInput,
    condition?: ModelregionConditionInput
  ): Promise<DeleteRegionMutation> {
    const statement = `mutation DeleteRegion($input: DeleteRegionInput!, $condition: ModelregionConditionInput) {
        deleteRegion(input: $input, condition: $condition) {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRegionMutation>response.data.deleteRegion;
  }
  async CreateUserStatus(
    input: CreateUserStatusInput,
    condition?: ModeluserStatusConditionInput
  ): Promise<CreateUserStatusMutation> {
    const statement = `mutation CreateUserStatus($input: CreateUserStatusInput!, $condition: ModeluserStatusConditionInput) {
        createUserStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserStatusMutation>response.data.createUserStatus;
  }
  async UpdateUserStatus(
    input: UpdateUserStatusInput,
    condition?: ModeluserStatusConditionInput
  ): Promise<UpdateUserStatusMutation> {
    const statement = `mutation UpdateUserStatus($input: UpdateUserStatusInput!, $condition: ModeluserStatusConditionInput) {
        updateUserStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserStatusMutation>response.data.updateUserStatus;
  }
  async DeleteUserStatus(
    input: DeleteUserStatusInput,
    condition?: ModeluserStatusConditionInput
  ): Promise<DeleteUserStatusMutation> {
    const statement = `mutation DeleteUserStatus($input: DeleteUserStatusInput!, $condition: ModeluserStatusConditionInput) {
        deleteUserStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserStatusMutation>response.data.deleteUserStatus;
  }
  async CreateSubscriptionType(
    input: CreateSubscriptionTypeInput,
    condition?: ModelsubscriptionTypeConditionInput
  ): Promise<CreateSubscriptionTypeMutation> {
    const statement = `mutation CreateSubscriptionType($input: CreateSubscriptionTypeInput!, $condition: ModelsubscriptionTypeConditionInput) {
        createSubscriptionType(input: $input, condition: $condition) {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubscriptionTypeMutation>response.data.createSubscriptionType;
  }
  async UpdateSubscriptionType(
    input: UpdateSubscriptionTypeInput,
    condition?: ModelsubscriptionTypeConditionInput
  ): Promise<UpdateSubscriptionTypeMutation> {
    const statement = `mutation UpdateSubscriptionType($input: UpdateSubscriptionTypeInput!, $condition: ModelsubscriptionTypeConditionInput) {
        updateSubscriptionType(input: $input, condition: $condition) {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubscriptionTypeMutation>response.data.updateSubscriptionType;
  }
  async DeleteSubscriptionType(
    input: DeleteSubscriptionTypeInput,
    condition?: ModelsubscriptionTypeConditionInput
  ): Promise<DeleteSubscriptionTypeMutation> {
    const statement = `mutation DeleteSubscriptionType($input: DeleteSubscriptionTypeInput!, $condition: ModelsubscriptionTypeConditionInput) {
        deleteSubscriptionType(input: $input, condition: $condition) {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubscriptionTypeMutation>response.data.deleteSubscriptionType;
  }
  async CreateSubscriptionStatus(
    input: CreateSubscriptionStatusInput,
    condition?: ModelsubscriptionStatusConditionInput
  ): Promise<CreateSubscriptionStatusMutation> {
    const statement = `mutation CreateSubscriptionStatus($input: CreateSubscriptionStatusInput!, $condition: ModelsubscriptionStatusConditionInput) {
        createSubscriptionStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubscriptionStatusMutation>(
      response.data.createSubscriptionStatus
    );
  }
  async UpdateSubscriptionStatus(
    input: UpdateSubscriptionStatusInput,
    condition?: ModelsubscriptionStatusConditionInput
  ): Promise<UpdateSubscriptionStatusMutation> {
    const statement = `mutation UpdateSubscriptionStatus($input: UpdateSubscriptionStatusInput!, $condition: ModelsubscriptionStatusConditionInput) {
        updateSubscriptionStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubscriptionStatusMutation>(
      response.data.updateSubscriptionStatus
    );
  }
  async DeleteSubscriptionStatus(
    input: DeleteSubscriptionStatusInput,
    condition?: ModelsubscriptionStatusConditionInput
  ): Promise<DeleteSubscriptionStatusMutation> {
    const statement = `mutation DeleteSubscriptionStatus($input: DeleteSubscriptionStatusInput!, $condition: ModelsubscriptionStatusConditionInput) {
        deleteSubscriptionStatus(input: $input, condition: $condition) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubscriptionStatusMutation>(
      response.data.deleteSubscriptionStatus
    );
  }
  async CreateDevType(
    input: CreateDevTypeInput,
    condition?: ModeldevTypeConditionInput
  ): Promise<CreateDevTypeMutation> {
    const statement = `mutation CreateDevType($input: CreateDevTypeInput!, $condition: ModeldevTypeConditionInput) {
        createDevType(input: $input, condition: $condition) {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDevTypeMutation>response.data.createDevType;
  }
  async UpdateDevType(
    input: UpdateDevTypeInput,
    condition?: ModeldevTypeConditionInput
  ): Promise<UpdateDevTypeMutation> {
    const statement = `mutation UpdateDevType($input: UpdateDevTypeInput!, $condition: ModeldevTypeConditionInput) {
        updateDevType(input: $input, condition: $condition) {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDevTypeMutation>response.data.updateDevType;
  }
  async DeleteDevType(
    input: DeleteDevTypeInput,
    condition?: ModeldevTypeConditionInput
  ): Promise<DeleteDevTypeMutation> {
    const statement = `mutation DeleteDevType($input: DeleteDevTypeInput!, $condition: ModeldevTypeConditionInput) {
        deleteDevType(input: $input, condition: $condition) {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDevTypeMutation>response.data.deleteDevType;
  }
  async CreateDevStatus(
    input: CreateDevStatusInput,
    condition?: ModeldevStatusConditionInput
  ): Promise<CreateDevStatusMutation> {
    const statement = `mutation CreateDevStatus($input: CreateDevStatusInput!, $condition: ModeldevStatusConditionInput) {
        createDevStatus(input: $input, condition: $condition) {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDevStatusMutation>response.data.createDevStatus;
  }
  async UpdateDevStatus(
    input: UpdateDevStatusInput,
    condition?: ModeldevStatusConditionInput
  ): Promise<UpdateDevStatusMutation> {
    const statement = `mutation UpdateDevStatus($input: UpdateDevStatusInput!, $condition: ModeldevStatusConditionInput) {
        updateDevStatus(input: $input, condition: $condition) {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDevStatusMutation>response.data.updateDevStatus;
  }
  async DeleteDevStatus(
    input: DeleteDevStatusInput,
    condition?: ModeldevStatusConditionInput
  ): Promise<DeleteDevStatusMutation> {
    const statement = `mutation DeleteDevStatus($input: DeleteDevStatusInput!, $condition: ModeldevStatusConditionInput) {
        deleteDevStatus(input: $input, condition: $condition) {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDevStatusMutation>response.data.deleteDevStatus;
  }
  async CreateSub(
    input: CreateSubInput,
    condition?: ModelsubConditionInput
  ): Promise<CreateSubMutation> {
    const statement = `mutation CreateSub($input: CreateSubInput!, $condition: ModelsubConditionInput) {
        createSub(input: $input, condition: $condition) {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubMutation>response.data.createSub;
  }
  async UpdateSub(
    input: UpdateSubInput,
    condition?: ModelsubConditionInput
  ): Promise<UpdateSubMutation> {
    const statement = `mutation UpdateSub($input: UpdateSubInput!, $condition: ModelsubConditionInput) {
        updateSub(input: $input, condition: $condition) {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubMutation>response.data.updateSub;
  }
  async DeleteSub(
    input: DeleteSubInput,
    condition?: ModelsubConditionInput
  ): Promise<DeleteSubMutation> {
    const statement = `mutation DeleteSub($input: DeleteSubInput!, $condition: ModelsubConditionInput) {
        deleteSub(input: $input, condition: $condition) {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubMutation>response.data.deleteSub;
  }
  async SyncUserSubDevs(
    filter?: ModeluserSubDevFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUserSubDevsQuery> {
    const statement = `query SyncUserSubDevs($filter: ModeluserSubDevFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUserSubDevs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            userID
            email
            deviceList {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            userStatusID
            userStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatusID
            signedOn
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUserSubDevsQuery>response.data.syncUserSubDevs;
  }
  async GetUserSubDev(id: string): Promise<GetUserSubDevQuery> {
    const statement = `query GetUserSubDev($id: ID!) {
        getUserSubDev(id: $id) {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserSubDevQuery>response.data.getUserSubDev;
  }
  async ListUserSubDevs(
    filter?: ModeluserSubDevFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserSubDevsQuery> {
    const statement = `query ListUserSubDevs($filter: ModeluserSubDevFilterInput, $limit: Int, $nextToken: String) {
        listUserSubDevs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            email
            deviceList {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            userStatusID
            userStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatusID
            signedOn
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserSubDevsQuery>response.data.listUserSubDevs;
  }
  async SyncDevs(
    filter?: ModeldevFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDevsQuery> {
    const statement = `query SyncDevs($filter: ModeldevFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDevs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            devName
            userID
            deviceTypeID
            deviceType {
              __typename
              id
              devType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            deviceStatusID
            deviceStatus {
              __typename
              id
              devStatus
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            dnsFiltering
            devicePubKey
            devicePrivKey
            ipAddress
            entryRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                entryRegionID
                entryRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                lastActive
                confFile
                uploaded
                downloaded
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegionID
                exitRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDevsQuery>response.data.syncDevs;
  }
  async GetDev(id: string): Promise<GetDevQuery> {
    const statement = `query GetDev($id: ID!) {
        getDev(id: $id) {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDevQuery>response.data.getDev;
  }
  async ListDevs(
    filter?: ModeldevFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevsQuery> {
    const statement = `query ListDevs($filter: ModeldevFilterInput, $limit: Int, $nextToken: String) {
        listDevs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devName
            userID
            deviceTypeID
            deviceType {
              __typename
              id
              devType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            deviceStatusID
            deviceStatus {
              __typename
              id
              devStatus
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            dnsFiltering
            devicePubKey
            devicePrivKey
            ipAddress
            entryRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                entryRegionID
                entryRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                lastActive
                confFile
                uploaded
                downloaded
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegionID
                exitRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevsQuery>response.data.listDevs;
  }
  async SyncDevEntryRegions(
    filter?: ModeldevEntryRegionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDevEntryRegionsQuery> {
    const statement = `query SyncDevEntryRegions($filter: ModeldevEntryRegionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDevEntryRegions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            entryRegionID
            entryRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            lastActive
            confFile
            uploaded
            downloaded
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDevEntryRegionsQuery>response.data.syncDevEntryRegions;
  }
  async GetDevEntryRegion(id: string): Promise<GetDevEntryRegionQuery> {
    const statement = `query GetDevEntryRegion($id: ID!) {
        getDevEntryRegion(id: $id) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDevEntryRegionQuery>response.data.getDevEntryRegion;
  }
  async ListDevEntryRegions(
    filter?: ModeldevEntryRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevEntryRegionsQuery> {
    const statement = `query ListDevEntryRegions($filter: ModeldevEntryRegionFilterInput, $limit: Int, $nextToken: String) {
        listDevEntryRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            entryRegionID
            entryRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            lastActive
            confFile
            uploaded
            downloaded
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevEntryRegionsQuery>response.data.listDevEntryRegions;
  }
  async SyncDevExitRegions(
    filter?: ModeldevExitRegionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDevExitRegionsQuery> {
    const statement = `query SyncDevExitRegions($filter: ModeldevExitRegionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDevExitRegions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegionID
            exitRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDevExitRegionsQuery>response.data.syncDevExitRegions;
  }
  async GetDevExitRegion(id: string): Promise<GetDevExitRegionQuery> {
    const statement = `query GetDevExitRegion($id: ID!) {
        getDevExitRegion(id: $id) {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDevExitRegionQuery>response.data.getDevExitRegion;
  }
  async ListDevExitRegions(
    filter?: ModeldevExitRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevExitRegionsQuery> {
    const statement = `query ListDevExitRegions($filter: ModeldevExitRegionFilterInput, $limit: Int, $nextToken: String) {
        listDevExitRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegionID
            exitRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevExitRegionsQuery>response.data.listDevExitRegions;
  }
  async SyncProducts(
    filter?: ModelproductFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncProductsQuery> {
    const statement = `query SyncProducts($filter: ModelproductFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncProducts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            SubTypeID
            subType {
              __typename
              id
              subType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            perdeviceDiscount
            perDeviceCost
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncProductsQuery>response.data.syncProducts;
  }
  async GetProduct(id: string): Promise<GetProductQuery> {
    const statement = `query GetProduct($id: ID!) {
        getProduct(id: $id) {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductQuery>response.data.getProduct;
  }
  async ListProducts(
    filter?: ModelproductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductsQuery> {
    const statement = `query ListProducts($filter: ModelproductFilterInput, $limit: Int, $nextToken: String) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            SubTypeID
            subType {
              __typename
              id
              subType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            perdeviceDiscount
            perDeviceCost
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductsQuery>response.data.listProducts;
  }
  async SyncRegions(
    filter?: ModelregionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncRegionsQuery> {
    const statement = `query SyncRegions($filter: ModelregionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncRegions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncRegionsQuery>response.data.syncRegions;
  }
  async GetRegion(id: string): Promise<GetRegionQuery> {
    const statement = `query GetRegion($id: ID!) {
        getRegion(id: $id) {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRegionQuery>response.data.getRegion;
  }
  async ListRegions(
    filter?: ModelregionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRegionsQuery> {
    const statement = `query ListRegions($filter: ModelregionFilterInput, $limit: Int, $nextToken: String) {
        listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRegionsQuery>response.data.listRegions;
  }
  async SyncUserStatuses(
    filter?: ModeluserStatusFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncUserStatusesQuery> {
    const statement = `query SyncUserStatuses($filter: ModeluserStatusFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncUserStatuses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncUserStatusesQuery>response.data.syncUserStatuses;
  }
  async GetUserStatus(id: string): Promise<GetUserStatusQuery> {
    const statement = `query GetUserStatus($id: ID!) {
        getUserStatus(id: $id) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserStatusQuery>response.data.getUserStatus;
  }
  async ListUserStatuss(
    filter?: ModeluserStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserStatussQuery> {
    const statement = `query ListUserStatuss($filter: ModeluserStatusFilterInput, $limit: Int, $nextToken: String) {
        listUserStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserStatussQuery>response.data.listUserStatuss;
  }
  async SyncSubscriptionTypes(
    filter?: ModelsubscriptionTypeFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSubscriptionTypesQuery> {
    const statement = `query SyncSubscriptionTypes($filter: ModelsubscriptionTypeFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSubscriptionTypes(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSubscriptionTypesQuery>response.data.syncSubscriptionTypes;
  }
  async GetSubscriptionType(id: string): Promise<GetSubscriptionTypeQuery> {
    const statement = `query GetSubscriptionType($id: ID!) {
        getSubscriptionType(id: $id) {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubscriptionTypeQuery>response.data.getSubscriptionType;
  }
  async ListSubscriptionTypes(
    filter?: ModelsubscriptionTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubscriptionTypesQuery> {
    const statement = `query ListSubscriptionTypes($filter: ModelsubscriptionTypeFilterInput, $limit: Int, $nextToken: String) {
        listSubscriptionTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubscriptionTypesQuery>response.data.listSubscriptionTypes;
  }
  async SyncSubscriptionStatuses(
    filter?: ModelsubscriptionStatusFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSubscriptionStatusesQuery> {
    const statement = `query SyncSubscriptionStatuses($filter: ModelsubscriptionStatusFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSubscriptionStatuses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSubscriptionStatusesQuery>(
      response.data.syncSubscriptionStatuses
    );
  }
  async GetSubscriptionStatus(id: string): Promise<GetSubscriptionStatusQuery> {
    const statement = `query GetSubscriptionStatus($id: ID!) {
        getSubscriptionStatus(id: $id) {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubscriptionStatusQuery>response.data.getSubscriptionStatus;
  }
  async ListSubscriptionStatuss(
    filter?: ModelsubscriptionStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubscriptionStatussQuery> {
    const statement = `query ListSubscriptionStatuss($filter: ModelsubscriptionStatusFilterInput, $limit: Int, $nextToken: String) {
        listSubscriptionStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubscriptionStatussQuery>response.data.listSubscriptionStatuss;
  }
  async SyncDevTypes(
    filter?: ModeldevTypeFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDevTypesQuery> {
    const statement = `query SyncDevTypes($filter: ModeldevTypeFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDevTypes(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDevTypesQuery>response.data.syncDevTypes;
  }
  async GetDevType(id: string): Promise<GetDevTypeQuery> {
    const statement = `query GetDevType($id: ID!) {
        getDevType(id: $id) {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDevTypeQuery>response.data.getDevType;
  }
  async ListDevTypes(
    filter?: ModeldevTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevTypesQuery> {
    const statement = `query ListDevTypes($filter: ModeldevTypeFilterInput, $limit: Int, $nextToken: String) {
        listDevTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevTypesQuery>response.data.listDevTypes;
  }
  async SyncDevStatuses(
    filter?: ModeldevStatusFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncDevStatusesQuery> {
    const statement = `query SyncDevStatuses($filter: ModeldevStatusFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncDevStatuses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncDevStatusesQuery>response.data.syncDevStatuses;
  }
  async GetDevStatus(id: string): Promise<GetDevStatusQuery> {
    const statement = `query GetDevStatus($id: ID!) {
        getDevStatus(id: $id) {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDevStatusQuery>response.data.getDevStatus;
  }
  async ListDevStatuss(
    filter?: ModeldevStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevStatussQuery> {
    const statement = `query ListDevStatuss($filter: ModeldevStatusFilterInput, $limit: Int, $nextToken: String) {
        listDevStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevStatussQuery>response.data.listDevStatuss;
  }
  async SyncSubs(
    filter?: ModelsubFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSubsQuery> {
    const statement = `query SyncSubs($filter: ModelsubFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSubs(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
          __typename
          items {
            __typename
            id
            status
            currentSubDevCount
            pendingSubDevCount
            transactionSubDevCount
            currentDNSFiltering
            pendingDNSFiltering
            transactionDNSFiltering
            paymentEmail
            devSubStartDate
            devSubExpirationDate
            dnsSubStartDate
            dnsSubExpirationDate
            lastPaidDate
            lastPaidAmount
            devSubNextDueDate
            dnsSubNextDueDate
            devSubNextDueAmount
            dnsSubNextDueAmount
            sub_Token
            lastTransactionUpdate
            lastTransactionID
            terminationDate
            dateChangeRequired
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSubsQuery>response.data.syncSubs;
  }
  async GetSub(id: string): Promise<GetSubQuery> {
    const statement = `query GetSub($id: ID!) {
        getSub(id: $id) {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubQuery>response.data.getSub;
  }
  async ListSubs(
    filter?: ModelsubFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubsQuery> {
    const statement = `query ListSubs($filter: ModelsubFilterInput, $limit: Int, $nextToken: String) {
        listSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            status
            currentSubDevCount
            pendingSubDevCount
            transactionSubDevCount
            currentDNSFiltering
            pendingDNSFiltering
            transactionDNSFiltering
            paymentEmail
            devSubStartDate
            devSubExpirationDate
            dnsSubStartDate
            dnsSubExpirationDate
            lastPaidDate
            lastPaidAmount
            devSubNextDueDate
            dnsSubNextDueDate
            devSubNextDueAmount
            dnsSubNextDueAmount
            sub_Token
            lastTransactionUpdate
            lastTransactionID
            terminationDate
            dateChangeRequired
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubsQuery>response.data.listSubs;
  }
  async AccountsByUser(
    userID?: string,
    email?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModeluserSubDevFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AccountsByUserQuery> {
    const statement = `query AccountsByUser($userID: ID, $email: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModeluserSubDevFilterInput, $limit: Int, $nextToken: String) {
        accountsByUser(userID: $userID, email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            email
            deviceList {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            userStatusID
            userStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatusID
            signedOn
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userID) {
      gqlAPIServiceArguments.userID = userID;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AccountsByUserQuery>response.data.accountsByUser;
  }
  async AccountsByEmail(
    email?: string,
    userID?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModeluserSubDevFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AccountsByEmailQuery> {
    const statement = `query AccountsByEmail($email: ID, $userID: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModeluserSubDevFilterInput, $limit: Int, $nextToken: String) {
        accountsByEmail(email: $email, userID: $userID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            email
            deviceList {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            userStatusID
            userStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatus {
              __typename
              id
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            subStatusID
            signedOn
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (userID) {
      gqlAPIServiceArguments.userID = userID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AccountsByEmailQuery>response.data.accountsByEmail;
  }
  async DevicesByUser(
    userID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModeldevFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DevicesByUserQuery> {
    const statement = `query DevicesByUser($userID: ID, $sortDirection: ModelSortDirection, $filter: ModeldevFilterInput, $limit: Int, $nextToken: String) {
        devicesByUser(userID: $userID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devName
            userID
            deviceTypeID
            deviceType {
              __typename
              id
              devType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            deviceStatusID
            deviceStatus {
              __typename
              id
              devStatus
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            dnsFiltering
            devicePubKey
            devicePrivKey
            ipAddress
            entryRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                entryRegionID
                entryRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                lastActive
                confFile
                uploaded
                downloaded
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegions {
              __typename
              items {
                __typename
                id
                devID
                device {
                  __typename
                  items {
                    __typename
                    id
                    devName
                    userID
                    deviceTypeID
                    deviceType {
                      __typename
                      id
                      devType
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    deviceStatusID
                    deviceStatus {
                      __typename
                      id
                      devStatus
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    dnsFiltering
                    devicePubKey
                    devicePrivKey
                    ipAddress
                    entryRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        entryRegionID
                        entryRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        lastActive
                        confFile
                        uploaded
                        downloaded
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegions {
                      __typename
                      items {
                        __typename
                        id
                        devID
                        device {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegionID
                        exitRegion {
                          __typename
                          id
                          regionName
                          publicKey
                          privateKey
                          serverName
                          awsRegion
                          fQDN
                          ipAddress
                          ipTablesPostUp
                          ipTablesPostDown
                          createdAt
                          updatedAt
                          prettyName
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    lastActive
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegionID
                exitRegion {
                  __typename
                  id
                  regionName
                  publicKey
                  privateKey
                  serverName
                  awsRegion
                  fQDN
                  ipAddress
                  ipTablesPostUp
                  ipTablesPostDown
                  createdAt
                  updatedAt
                  prettyName
                  _version
                  _deleted
                  _lastChangedAt
                }
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            lastActive
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userID) {
      gqlAPIServiceArguments.userID = userID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DevicesByUserQuery>response.data.devicesByUser;
  }
  async DevsByEntryRegion(
    entryRegionID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModeldevEntryRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DevsByEntryRegionQuery> {
    const statement = `query DevsByEntryRegion($entryRegionID: ID, $sortDirection: ModelSortDirection, $filter: ModeldevEntryRegionFilterInput, $limit: Int, $nextToken: String) {
        devsByEntryRegion(entryRegionID: $entryRegionID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            entryRegionID
            entryRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            lastActive
            confFile
            uploaded
            downloaded
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entryRegionID) {
      gqlAPIServiceArguments.entryRegionID = entryRegionID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DevsByEntryRegionQuery>response.data.devsByEntryRegion;
  }
  async EntryRegionsByDevs(
    devID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModeldevEntryRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntryRegionsByDevsQuery> {
    const statement = `query EntryRegionsByDevs($devID: ID, $sortDirection: ModelSortDirection, $filter: ModeldevEntryRegionFilterInput, $limit: Int, $nextToken: String) {
        entryRegionsByDevs(devID: $devID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            entryRegionID
            entryRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            lastActive
            confFile
            uploaded
            downloaded
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (devID) {
      gqlAPIServiceArguments.devID = devID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntryRegionsByDevsQuery>response.data.entryRegionsByDevs;
  }
  async DevsByExitRegion(
    exitRegionID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModeldevExitRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DevsByExitRegionQuery> {
    const statement = `query DevsByExitRegion($exitRegionID: ID, $sortDirection: ModelSortDirection, $filter: ModeldevExitRegionFilterInput, $limit: Int, $nextToken: String) {
        devsByExitRegion(exitRegionID: $exitRegionID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegionID
            exitRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (exitRegionID) {
      gqlAPIServiceArguments.exitRegionID = exitRegionID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DevsByExitRegionQuery>response.data.devsByExitRegion;
  }
  async ExitRegionsByDevs(
    devID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModeldevExitRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExitRegionsByDevsQuery> {
    const statement = `query ExitRegionsByDevs($devID: ID, $sortDirection: ModelSortDirection, $filter: ModeldevExitRegionFilterInput, $limit: Int, $nextToken: String) {
        exitRegionsByDevs(devID: $devID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            devID
            device {
              __typename
              items {
                __typename
                id
                devName
                userID
                deviceTypeID
                deviceType {
                  __typename
                  id
                  devType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                deviceStatusID
                deviceStatus {
                  __typename
                  id
                  devStatus
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                dnsFiltering
                devicePubKey
                devicePrivKey
                ipAddress
                entryRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    entryRegionID
                    entryRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    lastActive
                    confFile
                    uploaded
                    downloaded
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                exitRegions {
                  __typename
                  items {
                    __typename
                    id
                    devID
                    device {
                      __typename
                      items {
                        __typename
                        id
                        devName
                        userID
                        deviceTypeID
                        deviceType {
                          __typename
                          id
                          devType
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        deviceStatusID
                        deviceStatus {
                          __typename
                          id
                          devStatus
                          createdAt
                          updatedAt
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        dnsFiltering
                        devicePubKey
                        devicePrivKey
                        ipAddress
                        entryRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        exitRegions {
                          __typename
                          nextToken
                          startedAt
                        }
                        lastActive
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      nextToken
                      startedAt
                    }
                    exitRegionID
                    exitRegion {
                      __typename
                      id
                      regionName
                      publicKey
                      privateKey
                      serverName
                      awsRegion
                      fQDN
                      ipAddress
                      ipTablesPostUp
                      ipTablesPostDown
                      createdAt
                      updatedAt
                      prettyName
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
                lastActive
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            exitRegionID
            exitRegion {
              __typename
              id
              regionName
              publicKey
              privateKey
              serverName
              awsRegion
              fQDN
              ipAddress
              ipTablesPostUp
              ipTablesPostDown
              createdAt
              updatedAt
              prettyName
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (devID) {
      gqlAPIServiceArguments.devID = devID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExitRegionsByDevsQuery>response.data.exitRegionsByDevs;
  }
  async RegionByName(
    regionName?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelregionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RegionByNameQuery> {
    const statement = `query RegionByName($regionName: String, $sortDirection: ModelSortDirection, $filter: ModelregionFilterInput, $limit: Int, $nextToken: String) {
        regionByName(regionName: $regionName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (regionName) {
      gqlAPIServiceArguments.regionName = regionName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RegionByNameQuery>response.data.regionByName;
  }
  OnCreateUserSubDevListener: Observable<
    OnCreateUserSubDevSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserSubDev {
        onCreateUserSubDev {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateUserSubDevSubscription>;

  OnUpdateUserSubDevListener: Observable<
    OnUpdateUserSubDevSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserSubDev {
        onUpdateUserSubDev {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserSubDevSubscription>;

  OnDeleteUserSubDevListener: Observable<
    OnDeleteUserSubDevSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserSubDev {
        onDeleteUserSubDev {
          __typename
          id
          userID
          email
          deviceList {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          userStatusID
          userStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatus {
            __typename
            id
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          subStatusID
          signedOn
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserSubDevSubscription>;

  OnCreateDevListener: Observable<OnCreateDevSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateDev {
        onCreateDev {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateDevSubscription>;

  OnUpdateDevListener: Observable<OnUpdateDevSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDev {
        onUpdateDev {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateDevSubscription>;

  OnDeleteDevListener: Observable<OnDeleteDevSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDev {
        onDeleteDev {
          __typename
          id
          devName
          userID
          deviceTypeID
          deviceType {
            __typename
            id
            devType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          deviceStatusID
          deviceStatus {
            __typename
            id
            devStatus
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          dnsFiltering
          devicePubKey
          devicePrivKey
          ipAddress
          entryRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              entryRegionID
              entryRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              lastActive
              confFile
              uploaded
              downloaded
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegions {
            __typename
            items {
              __typename
              id
              devID
              device {
                __typename
                items {
                  __typename
                  id
                  devName
                  userID
                  deviceTypeID
                  deviceType {
                    __typename
                    id
                    devType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  deviceStatusID
                  deviceStatus {
                    __typename
                    id
                    devStatus
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  dnsFiltering
                  devicePubKey
                  devicePrivKey
                  ipAddress
                  entryRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      entryRegionID
                      entryRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      lastActive
                      confFile
                      uploaded
                      downloaded
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegions {
                    __typename
                    items {
                      __typename
                      id
                      devID
                      device {
                        __typename
                        items {
                          __typename
                          id
                          devName
                          userID
                          deviceTypeID
                          deviceStatusID
                          dnsFiltering
                          devicePubKey
                          devicePrivKey
                          ipAddress
                          lastActive
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegionID
                      exitRegion {
                        __typename
                        id
                        regionName
                        publicKey
                        privateKey
                        serverName
                        awsRegion
                        fQDN
                        ipAddress
                        ipTablesPostUp
                        ipTablesPostDown
                        createdAt
                        updatedAt
                        prettyName
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  lastActive
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegionID
              exitRegion {
                __typename
                id
                regionName
                publicKey
                privateKey
                serverName
                awsRegion
                fQDN
                ipAddress
                ipTablesPostUp
                ipTablesPostDown
                createdAt
                updatedAt
                prettyName
                _version
                _deleted
                _lastChangedAt
              }
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          lastActive
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteDevSubscription>;

  OnCreateDevEntryRegionListener: Observable<
    OnCreateDevEntryRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDevEntryRegion {
        onCreateDevEntryRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateDevEntryRegionSubscription>;

  OnUpdateDevEntryRegionListener: Observable<
    OnUpdateDevEntryRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDevEntryRegion {
        onUpdateDevEntryRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateDevEntryRegionSubscription>;

  OnDeleteDevEntryRegionListener: Observable<
    OnDeleteDevEntryRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDevEntryRegion {
        onDeleteDevEntryRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          entryRegionID
          entryRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          lastActive
          confFile
          uploaded
          downloaded
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteDevEntryRegionSubscription>;

  OnCreateDevExitRegionListener: Observable<
    OnCreateDevExitRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDevExitRegion {
        onCreateDevExitRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateDevExitRegionSubscription>;

  OnUpdateDevExitRegionListener: Observable<
    OnUpdateDevExitRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDevExitRegion {
        onUpdateDevExitRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateDevExitRegionSubscription>;

  OnDeleteDevExitRegionListener: Observable<
    OnDeleteDevExitRegionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDevExitRegion {
        onDeleteDevExitRegion {
          __typename
          id
          devID
          device {
            __typename
            items {
              __typename
              id
              devName
              userID
              deviceTypeID
              deviceType {
                __typename
                id
                devType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              deviceStatusID
              deviceStatus {
                __typename
                id
                devStatus
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              dnsFiltering
              devicePubKey
              devicePrivKey
              ipAddress
              entryRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  entryRegionID
                  entryRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  lastActive
                  confFile
                  uploaded
                  downloaded
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              exitRegions {
                __typename
                items {
                  __typename
                  id
                  devID
                  device {
                    __typename
                    items {
                      __typename
                      id
                      devName
                      userID
                      deviceTypeID
                      deviceType {
                        __typename
                        id
                        devType
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      deviceStatusID
                      deviceStatus {
                        __typename
                        id
                        devStatus
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      dnsFiltering
                      devicePubKey
                      devicePrivKey
                      ipAddress
                      entryRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          entryRegionID
                          lastActive
                          confFile
                          uploaded
                          downloaded
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      exitRegions {
                        __typename
                        items {
                          __typename
                          id
                          devID
                          exitRegionID
                          _version
                          _deleted
                          _lastChangedAt
                        }
                        nextToken
                        startedAt
                      }
                      lastActive
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    nextToken
                    startedAt
                  }
                  exitRegionID
                  exitRegion {
                    __typename
                    id
                    regionName
                    publicKey
                    privateKey
                    serverName
                    awsRegion
                    fQDN
                    ipAddress
                    ipTablesPostUp
                    ipTablesPostDown
                    createdAt
                    updatedAt
                    prettyName
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  _version
                  _deleted
                  _lastChangedAt
                }
                nextToken
                startedAt
              }
              lastActive
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          exitRegionID
          exitRegion {
            __typename
            id
            regionName
            publicKey
            privateKey
            serverName
            awsRegion
            fQDN
            ipAddress
            ipTablesPostUp
            ipTablesPostDown
            createdAt
            updatedAt
            prettyName
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteDevExitRegionSubscription>;

  OnCreateProductListener: Observable<
    OnCreateProductSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProduct {
        onCreateProduct {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateProductSubscription>;

  OnUpdateProductListener: Observable<
    OnUpdateProductSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProduct {
        onUpdateProduct {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateProductSubscription>;

  OnDeleteProductListener: Observable<
    OnDeleteProductSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProduct {
        onDeleteProduct {
          __typename
          id
          SubTypeID
          subType {
            __typename
            id
            subType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          perdeviceDiscount
          perDeviceCost
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteProductSubscription>;

  OnCreateRegionListener: Observable<OnCreateRegionSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateRegion {
        onCreateRegion {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateRegionSubscription>;

  OnUpdateRegionListener: Observable<OnUpdateRegionSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRegion {
        onUpdateRegion {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateRegionSubscription>;

  OnDeleteRegionListener: Observable<OnDeleteRegionSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRegion {
        onDeleteRegion {
          __typename
          id
          regionName
          publicKey
          privateKey
          serverName
          awsRegion
          fQDN
          ipAddress
          ipTablesPostUp
          ipTablesPostDown
          createdAt
          updatedAt
          prettyName
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteRegionSubscription>;

  OnCreateUserStatusListener: Observable<
    OnCreateUserStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserStatus {
        onCreateUserStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateUserStatusSubscription>;

  OnUpdateUserStatusListener: Observable<
    OnUpdateUserStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserStatus {
        onUpdateUserStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserStatusSubscription>;

  OnDeleteUserStatusListener: Observable<
    OnDeleteUserStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserStatus {
        onDeleteUserStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserStatusSubscription>;

  OnCreateSubscriptionTypeListener: Observable<
    OnCreateSubscriptionTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSubscriptionType {
        onCreateSubscriptionType {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateSubscriptionTypeSubscription>;

  OnUpdateSubscriptionTypeListener: Observable<
    OnUpdateSubscriptionTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSubscriptionType {
        onUpdateSubscriptionType {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateSubscriptionTypeSubscription>;

  OnDeleteSubscriptionTypeListener: Observable<
    OnDeleteSubscriptionTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSubscriptionType {
        onDeleteSubscriptionType {
          __typename
          id
          subType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteSubscriptionTypeSubscription>;

  OnCreateSubscriptionStatusListener: Observable<
    OnCreateSubscriptionStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSubscriptionStatus {
        onCreateSubscriptionStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateSubscriptionStatusSubscription>;

  OnUpdateSubscriptionStatusListener: Observable<
    OnUpdateSubscriptionStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSubscriptionStatus {
        onUpdateSubscriptionStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateSubscriptionStatusSubscription>;

  OnDeleteSubscriptionStatusListener: Observable<
    OnDeleteSubscriptionStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSubscriptionStatus {
        onDeleteSubscriptionStatus {
          __typename
          id
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteSubscriptionStatusSubscription>;

  OnCreateDevTypeListener: Observable<
    OnCreateDevTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDevType {
        onCreateDevType {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateDevTypeSubscription>;

  OnUpdateDevTypeListener: Observable<
    OnUpdateDevTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDevType {
        onUpdateDevType {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateDevTypeSubscription>;

  OnDeleteDevTypeListener: Observable<
    OnDeleteDevTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDevType {
        onDeleteDevType {
          __typename
          id
          devType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteDevTypeSubscription>;

  OnCreateDevStatusListener: Observable<
    OnCreateDevStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDevStatus {
        onCreateDevStatus {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateDevStatusSubscription>;

  OnUpdateDevStatusListener: Observable<
    OnUpdateDevStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDevStatus {
        onUpdateDevStatus {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateDevStatusSubscription>;

  OnDeleteDevStatusListener: Observable<
    OnDeleteDevStatusSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDevStatus {
        onDeleteDevStatus {
          __typename
          id
          devStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteDevStatusSubscription>;

  OnCreateSubListener: Observable<OnCreateSubSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateSub {
        onCreateSub {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnCreateSubSubscription>;

  OnUpdateSubListener: Observable<OnUpdateSubSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSub {
        onUpdateSub {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnUpdateSubSubscription>;

  OnDeleteSubListener: Observable<OnDeleteSubSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSub {
        onDeleteSub {
          __typename
          id
          status
          currentSubDevCount
          pendingSubDevCount
          transactionSubDevCount
          currentDNSFiltering
          pendingDNSFiltering
          transactionDNSFiltering
          paymentEmail
          devSubStartDate
          devSubExpirationDate
          dnsSubStartDate
          dnsSubExpirationDate
          lastPaidDate
          lastPaidAmount
          devSubNextDueDate
          dnsSubNextDueDate
          devSubNextDueAmount
          dnsSubNextDueAmount
          sub_Token
          lastTransactionUpdate
          lastTransactionID
          terminationDate
          dateChangeRequired
          _version
          _deleted
          _lastChangedAt
        }
      }`
    )
  ) as Observable<OnDeleteSubSubscription>;
}
