import { Component, OnInit } from '@angular/core';
import { faBullhorn} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-founders-promise',
  templateUrl: './founders-promise.component.html',
  styleUrls: ['./founders-promise.component.css']
})
export class FoundersPromiseComponent implements OnInit {


  faBullhorn = faBullhorn;


  constructor() { }

  ngOnInit(): void {
  }

}
