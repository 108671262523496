import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/Types/user.model';
import { faBan, faSignature, faMobileAlt, faFilter, faGlobeAmericas, faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import { UpdateSubscriptionFormModel } from 'src/Types/update-subscription-form-model';
import jsSHA from "jssha";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-subscription-form',
  templateUrl: './update-subscription-form.component.html',
  styleUrls: ['./update-subscription-form.component.css']
})
export class UpdateSubscriptionFormComponent implements OnInit {

/* -------------------------------------------------------------------------- */
/*                              GLOBAL VARIABLES                              */
/* -------------------------------------------------------------------------- */

@Input() currentUser : User;
@Output() closeModalEvent = new EventEmitter<string>();

/* ------------------------------ ICON HANDLES ------------------------------ */
faSignature = faSignature;
faMobileAlt = faMobileAlt;
faFilter = faFilter;
faGlobeAmericas = faGlobeAmericas;
faEdit = faEdit;
faInfoCircle = faInfoCircle;
faBan = faBan;

/* ----------------------------- STATE VARIABLES ---------------------------- */
updateSubscriptionResults: UpdateSubscriptionFormModel = new UpdateSubscriptionFormModel();
model: UpdateSubscriptionFormModel = new UpdateSubscriptionFormModel();
submitted = false;
cancelling = false;
submittedStatus: submissionStatus = submissionStatus.noChanges;

/* --------------------------------- HANDLES -------------------------------- */

// handle for current subscription data for user in the back end.
currentSubData = {
  __typename: "sub",
  _deleted: null,  ​
  _lastChangedAt: 1,  ​
  _version: 1,  ​
  currentDNSFiltering: 0,  ​
  currentSubDevCount: 0,  ​
  devSubExpirationDate: "1970-01-01T00:00:00.000Z",  ​
  devSubNextDueAmount: 0.00,  ​
  devSubNextDueDate: "1970-01-01T00:00:00.000Z",  ​
  devSubStartDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubExpirationDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubNextDueAmount: 0.00,  ​
  dnsSubNextDueDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubStartDate: "1970-01-01T00:00:00.000Z",  ​
  id: "00000000-0000-0000-0000-000000000000",  ​
  lastPaidAmount: 0.00,  ​
  lastPaidDate: "1970-01-01T00:00:00.000Z",  ​
  lastTransactionID: "0000001",  ​
  lastTransactionUpdate: "1970-01-01T00:00:00.000Z",  ​
  paymentEmail: "guest@greyhex.io",  ​
  pendingDNSFiltering: 0,  ​
  pendingSubDevCount: 0,  ​
  status: "approved",  ​
  sub_Token: "",  ​
  transactionDNSFiltering: 0,  ​
  transactionSubDevCount: 0,
  terminationDate: null
};

//global handle for the cart link. (soon to be replaced with submit button)
straightToCartString = "";

//global variables for cost calculation
devSubCost = 2.00;
devSubDiscount = 0.00
dnsSubCost = 0.99;
dnsSubDiscount = 0.00;

//handles for visual
newDevTotal = "0.0";
newDNSTotal = "0.0";
totalServiceCharge = 0.0;
remainingValue = 0.0;
outstandingBalance = 0.0;

hasPastDue: boolean = false;
hasRemainingValue: boolean = false;
submissionModalMessage: string = "default message"

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

    if(this.currentUser.backendUserData.subStatusID == '0') {
      // we stick with defaults since they don't have a subscription record.

      //update the form data the user sees when opening the update panel using default info
      this.initializeFormData(false);

      //update the calculations based on initial numbers.
      this.updateFormVisualizations();

    } else {
      //get the backend user data
      this.updateUserSubscriptionData().then( event => {

        if(event != null)
        {
          //assign results if they exist, otherwise use defaults
          event._lastChangedAt > 1 ? this.currentSubData = event : this.currentSubData;
          
          //update the form data the user sees when opening the update panel using backend info
          this.initializeFormData(true);

        } else {

          //update the form data the user sees when opening the update panel using default info
          this.initializeFormData(false);
        }            

      //update the calculations based on initial numbers.
      this.updateFormVisualizations();
        
      });

    }
   
  }

  /**
   * track the form state after submission
   */
  onSubmit(content): void {
    
    this.submitted = true;
    this.processTransactionChanges(this.currentSubData, this.model);


    switch(this.submittedStatus) {
      case submissionStatus.decreasedDevices: // Decreased Devices, show modal regarding next month changes.
        
        //set message to notify user of action
        this.submissionModalMessage = "Maximum usable devices will be reduced at the start of the next billing cycle to prevent loss of value."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.removedFiltering:

        //set message to notify user of action
        this.submissionModalMessage = "Device filtering will be removed at the start of next billing cycle to prevent the loss of value."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.noChanges: // the customer has not made any changes
        
        //set message to notify user of action
        this.submissionModalMessage = "No changes have been selected."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.addedFiltering: // the customer added only filtering to their service
    
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
          
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.addedDevices: // the customer added new device subscriptions

        // set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.addedDevicesWithFree: // the customer added new devices subscriptions and needs a free device
      
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
          
        // open the modal with the message.
        this.triggerCompletionModal(content,true);

      break;

      case submissionStatus.onlyFreeDevice: // the customer is only getting the free device
        
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.accountDisabled: // the customer's account is disabled
      
        //set message to notify user of action
        this.submissionModalMessage = "Your account is currently disabled, please contact support."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.cancelledSubscription: // the customer cancelled their account
      
      //set message to notify user of action
      this.submissionModalMessage = "Your subscription will be cancelled at the end of the billing cycle and you will not be charged at renawal time. "
      
      // open the modal with the message.
      this.triggerCompletionModal(content, true);

    break;

      default: // there has been a mistake, do nothing.
        break;
    }
  }

  async updateUserSubscriptionData(): Promise<any> {
    
    //handle for results of action
    var results;

    try {

      //query the back end to see if the current user has subscription data by their cognito ID (not backendID)
      results = await this.currentUser.api.GetSub(this.currentUser.backendUserData.userID);

    } catch (err) {

      results = err.toString();

    }

    return results;

  }

  //converts Date time into local date string
  localDate(dateStringToConvert): string {
    return new Date(dateStringToConvert).toLocaleDateString();
  }

  initializeFormData(existingSub): void {

    if(existingSub) {

      //update model values from backend. 
      this.model.currentDeviceSubQuantity = (this.currentSubData.currentSubDevCount);
      this.model.pendingDeviceSubQuantity = (this.currentSubData.pendingSubDevCount)

      this.model.currentDNSFilteringQuantity = (this.currentSubData.currentDNSFiltering);
      this.model.pendingDNSFilteringQuantity = (this.currentSubData.pendingDNSFiltering);
      this.model.totalServiceCharge = (Math.round((this.currentSubData.devSubNextDueAmount + this.currentSubData.dnsSubNextDueAmount) * 100) / 100); // rounded for the "Model" data. (because user sees it.)
      this.model.devSubNextDueDate = this.currentSubData.devSubNextDueDate;
      this.model.lastPaidDate = this.currentSubData.lastPaidDate;
      this.model.terminationDate = this.currentSubData.terminationDate;

    }    


    //update the remaining service value and outstanding balances
    // -----------------------------------------------------------

    //set the various date values
    var rightNow = new Date(Date.now());
    //console.log("rightNow:" + rightNow );

    var nextDueDate = new Date(this.model.devSubNextDueDate);
    //console.log("nextDueDate:" + nextDueDate );

    var previousDate = new Date(nextDueDate);
    previousDate = new Date (previousDate.setMonth(previousDate.getMonth() - 1 )); // next due minus one month.
    //console.log("previousDate:" + previousDate );

    var lastPaidDate = new Date(this.model.lastPaidDate);
    //console.log("lastPaidDate:" + lastPaidDate);

    var terminationDate = new Date(this.model.terminationDate);
    //console.log("terminationDate:" + terminationDate);

    //check if account is up-to-date on payments
    if(lastPaidDate.getTime() < previousDate.getTime()) {
      
      //we should figure out the outstanding balance of the user.
      this.outstandingBalance = this.calculateOutstandingBalance(lastPaidDate, terminationDate);
      this.hasPastDue = true;

    } else {

      //we should figure out how much value they have left from what they paid upfront.
      this.remainingValue = this.calculateRemainingValue(nextDueDate, rightNow, previousDate);
      this.hasPastDue = false;
    }    

    //update model values for remaining value
    this.model.remainingValue = Math.round(this.remainingValue *100) / 100; // rounded the "model" version since the user sees it.

    //update model values for outstanding balance
    this.model.outstandingBalance = Math.round(this.outstandingBalance *100) / 100; // rounded the "model" version since the user sees it.

    //update model values for Amount Due Now
    this.model.amountDueNow = Math.round((this.model.totalServiceCharge - this.model.remainingValue) * 100) / 100;

  }

  updateFormVisualizations() {
    

    /* -------------------------------- version 2 ------------------------------- */
    /* ------------------------ Update user input totals ------------------------ */    

    //update the device totals.
    var newDevTotal = ((this.model.pendingDeviceSubQuantity * this.devSubCost));
    this.newDevTotal = (Math.round(newDevTotal * 100) / 100).toFixed(2);

    //update the filtering totals to match the device totals
    if(this.model.pendingDNSFilteringQuantity != this.model.pendingDeviceSubQuantity && this.model.pendingDNSFilteringQuantity > 0 ) {
      this.model.pendingDNSFilteringQuantity = this.model.pendingDeviceSubQuantity;
    }

    //update the pricing totals for filtering.
    var newDNStotal = ((this.model.pendingDNSFilteringQuantity * this.dnsSubCost));
    this.newDNSTotal = (Math.round(newDNStotal * 100) / 100).toFixed(2);

    //update the total monthly service charge.
    var totalServiceCharge = (this.model.pendingDeviceSubQuantity * this.devSubCost) + (this.model.pendingDNSFilteringQuantity * this.dnsSubCost);
    this.model.totalServiceCharge = Math.round( totalServiceCharge * 100 ) / 100;

    /* ------- update the remaining service value and outstanding balances ------ */

    //set the various date values
    var rightNow = new Date(Date.now());
    //console.log("rightNow:" + rightNow );

    var nextDueDate = new Date(this.currentSubData.devSubNextDueDate);
    //console.log("nextDueDate:" + nextDueDate );

    var previousDate = new Date(nextDueDate);
    previousDate = new Date (previousDate.setMonth(previousDate.getMonth() - 1 )); // next due minus one month.
    //console.log("previousDate:" + previousDate );

    var lastPaidDate = new Date(this.currentSubData.lastPaidDate);
    //console.log("lastPaidDate:" + lastPaidDate);

    var terminationDate = new Date(this.currentSubData.terminationDate);
    //console.log("terminationDate:" + terminationDate);

    //check if account is up-to-date on payments
    if((lastPaidDate.getTime() / (1000 * 60 * 60 * 24) ) < (previousDate.getTime()/ (1000 * 60 * 60 * 24) )) {
      
      //we aren't calculating remaining value so hide these visuals.
      this.hasRemainingValue = false;

      //we should figure out the outstanding balance of the user and ask them to pay by setting has past due
      this.outstandingBalance = this.calculateOutstandingBalance(lastPaidDate, terminationDate);
      
      //update visual model values for outstanding balance
      if(this.outstandingBalance <= 0) {

        //The customer had no outstanding balance, or it looks like we owe them money.
        //We just let the service continue until end of service period.
        //Hide the outstanding balance display from the user, and set the value to 0 to prevent issues with calculations
        this.model.outstandingBalance = 0;
        this.hasPastDue = false;

      } else {
        //the customer has an outstanding balance.
        //set the visual model and make sure the visuals are displayed
        this.model.outstandingBalance = this.outstandingBalance;
        this.hasPastDue = true;
      }

      this.model.outstandingBalance = Math.round(this.outstandingBalance *100) / 100; // rounded the "model" version since the user sees it.

      //update model values for Amount Due Now (display)
      this.model.amountDueNow = Math.round((totalServiceCharge - this.outstandingBalance) * 100) / 100;     

    } else if ((lastPaidDate.getTime() / (1000 * 60 * 60 * 24)) == (previousDate.getTime()/ (1000 * 60 * 60 * 24) )) {

      //they paid today, and therefore are current. don't show remaining value or outstanding balances. update amountDueNow accordingly

      //update model values for Amount Due Now (display)
      this.model.amountDueNow = Math.round((totalServiceCharge) * 100) / 100;

      this.hasPastDue = false;
      this.hasRemainingValue = false;

    } else {

      // we aren't calculating past due, hide those values from the user.
      this.hasPastDue = false;

      //we should figure out how much value they have left from what they paid last.
      this.remainingValue = this.calculateRemainingValue(nextDueDate, rightNow, previousDate);

      //update visual model values for remaining value
      if (this.remainingValue <= 0) {
        
        //the customer had no remaining value, or it looks like we owe money.
        //we just let the service continue until end of service period.
        //Hide the remaining value display from the user, and set the value to 0 to prevent issues with calculations
        this.model.remainingValue = 0;
        this.hasRemainingValue = false;

      } else {

        //the customer has a remaining value.
        //set the visual model and make sure the visuals are displayed
        this.model.remainingValue = this.remainingValue;
        this.hasRemainingValue = true;
      }     

      //update model values for Amount Due Now (display)
      this.model.amountDueNow = Math.round((totalServiceCharge - this.remainingValue) * 100) / 100;

    }    

  }

  //calc remaining value
  calculateRemainingValue(nextDueDate, rightNow, previousDate) : number {

    var daysOfServiceLeft = Math.floor((nextDueDate.getTime() - rightNow.getTime()) / (1000 * 60 * 60 * 24));
    this.currentUser.consoleDebug ? console.log("daysOfServiceLeft:" + daysOfServiceLeft ) : console.log();

    var daysInServicePeriod = Math.round(nextDueDate.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24);
    this.currentUser.consoleDebug ? console.log("daysInServicePeriod:" + daysInServicePeriod ) : console.log();

    var percentRemaining = daysOfServiceLeft != 0 ? Math.round((daysOfServiceLeft / daysInServicePeriod) * 1000) / 1000 : 0;
    this.currentUser.consoleDebug ? console.log("percentRemaining:" + percentRemaining ) : console.log();

    var newRemainingValue = Math.round(( (this.currentSubData.devSubNextDueAmount) + (this.currentSubData.dnsSubNextDueAmount) * percentRemaining) * 100 ) / 100;
    this.currentUser.consoleDebug ? console.log("newRemainingValue =" + newRemainingValue) : console.log();

    return newRemainingValue;
  }

  //calc outstanding balance.
  calculateOutstandingBalance(lastPaidDate, terminationDate ) : number {
    
    // last paid date Plus 1 month = expected payment date.
    var expectedPaymentDate = new Date(lastPaidDate);
    expectedPaymentDate = new Date (expectedPaymentDate.setMonth(expectedPaymentDate.getMonth() + 1 )); 
    
    var daysOfServiceUsed = Math.ceil((terminationDate.getTime() - expectedPaymentDate.getTime()) / (1000 * 60 * 60 * 24));
    this.currentUser.consoleDebug ? console.log("daysOfServiceUsed:" + daysOfServiceUsed) : console.log();

    var daysInPreviousServicePeriod = Math.round((expectedPaymentDate.getTime() - lastPaidDate.getTime()) / (1000 * 60 * 60 * 24));
    this.currentUser.consoleDebug ? console.log("daysInPreviousServicePeriod:" + daysInPreviousServicePeriod) : console.log();

    var totalAmountForServicePeriod = ((this.currentSubData.devSubNextDueAmount) + (this.currentSubData.dnsSubNextDueAmount));
    this.currentUser.consoleDebug ? console.log("totalAmountForServicePeriod: $" + totalAmountForServicePeriod ) : console.log();

    var perDayCost = Math.round((totalAmountForServicePeriod / daysInPreviousServicePeriod) * 1000) / 1000;
    this.currentUser.consoleDebug ? console.log("perDayCost: $" + perDayCost ) : console.log();

    var newOutstandingBalance = Math.round((perDayCost * daysOfServiceUsed) * 100) /100;
    this.currentUser.consoleDebug ? console.log("newOutStandingBalance: $" + newOutstandingBalance ) : console.log();

    return newOutstandingBalance;
  }

  processTransactionChanges(cSubData, formSubData: UpdateSubscriptionFormModel) {

    /* ------------------------------ JSSHA HANDLES ----------------------------- */
    //Device subscriptions
    var devSubHashHandle = {
      hashName : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashQuantity : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashPrice : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCategory : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashSubFreq : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCustQty : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCode : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashSubStartDate : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashValueName : "",
      hashValueQuantity : "",
      hashValuePrice : "",
      hashValueCategory : "",
      hashValueSubFreq : "",
      hashValueCustQty : "",
      hashValueCode : "",
      hashValueSubStartDate : "",
      prodName : "Device_Subscription",
      //prodQuantity : "1",
      prodQuantity : ((formSubData.pendingDeviceSubQuantity != cSubData.currentSubDevCount) ? formSubData.pendingDeviceSubQuantity-cSubData.currentSubDevCount : 0),
      prodPrice : this.devSubCost.toString(),
      prodCategory : "DevSubCat",
      prodSubFreq : "1m",
      prodCode : "devSub",
      prodSubStartDate : "1m"
    };

    //DNS filtering subscriptions
    var dNSSubHashHandle = {
      hashName : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashQuantity : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashPrice : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCategory : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashSubFreq : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCustQty : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCode : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashSubStartDate : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashValueName : "",
      hashValueQuantity : "",
      hashValuePrice : "",
      hashValueCategory : "",
      hashValueSubFreq : "",
      hashValueCustQty : "",
      hashValueCode : "",
      hashValueSubStartDate : "",
      prodName : "DNS_Filtering_Subscription",
      //prodQuantity : "1",
      prodQuantity : ( formSubData.pendingDNSFilteringQuantity != formSubData.pendingDeviceSubQuantity ? 0 : (formSubData.pendingDNSFilteringQuantity != cSubData.currentDNSFiltering ? formSubData.pendingDeviceSubQuantity-cSubData.currentDNSFiltering : 0 )),
      prodPrice : this.dnsSubCost.toString(),
      prodCategory : "DNSFilteringSubCat",
      prodSubFreq : "1m",
      prodCode : "dnsFilteringSub",
      prodSubStartDate : "1m"
    };

    //Service Proration (one-time fee)
    var serviceProrationHashHandle = {
      hashName : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashQuantity : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashPrice : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCategory : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashCode : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
      hashValueName : "",
      hashValueQuantity : "",
      hashValuePrice : "",
      hashValueCategory : "",
      hashValueCode : "",
      prodName : "One_Time_Proration",
      prodQuantity : "1",
      //prodPrice : "15.00",
      prodPrice : formSubData.amountDueNow,
      prodCategory : "ServiceProration",
      prodCode : "serviceProration"
    };

    //Free Device Subscription (single)
    // var freeDevSubHashHandle = {
    //   hashName : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashQuantity : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashQuantity2 : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashPrice : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashCategory : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashSubFreq : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashCustQty : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashCode : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashSubStartDate : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashDiscount : new jsSHA("SHA-256", "TEXT", { hmacKey: { value: "unS5NeZmbbHWiMVqmKve1WnbumJLZxJLLcGRLJ8Cq61mY8iKeCYZGEABDflw", format: "TEXT" } }),
    //   hashValueName : "",
    //   hashValueQuantity : "",
    //   hashValueQuantity2 : "",
    //   hashValuePrice : "",
    //   hashValueCategory : "",
    //   hashValueSubFreq : "",
    //   hashValueCustQty : "",
    //   hashValueCode : "",
    //   hashValueSubStartDate : "",
    //   hashValueDiscount : "",
    //   prodName : "Free_Device_Subscription",
    //   prodQuantity : 1,
    //   prodQuantity2 : 0,
    //   prodPrice : "2.00",
    //   prodCategory : "FreeDevSubCat",
    //   prodSubFreq : "1m",
    //   prodCode : "freeDevSub",
    //   prodSubStartDate : "1m",
    //   prodDiscount : "PromotionalDevice{single|1-2.00}"
    // };

    /* -------------------------------------------------------------------------- */
    /*                        PRODUCT VALUE INITIALIZATION                        */
    /* -------------------------------------------------------------------------- */
    
    /* ------------------------------- cart values ------------------------------ */
    //var prodSubToken = "";
    var prodSubToken = cSubData.sub_Token;
    var custGUID = this.currentUser.backendUserData.userID;
    var custGUID2 = this.currentUser.backendUserData.id;
    //var subModify = "replace";
    //var subRestart = "true";
    var subCancel = "next_transaction_date"

    /* ------------------------------ ITEM HASHING ------------------------------ */
    //item 1 : DevSub
    devSubHashHandle.hashName.update(devSubHashHandle.prodCode + "name" + devSubHashHandle.prodName);
    devSubHashHandle.hashValueName = devSubHashHandle.hashName.getHash("HEX");
    devSubHashHandle.hashQuantity.update(devSubHashHandle.prodCode + "quantity" + devSubHashHandle.prodQuantity);
    devSubHashHandle.hashValueQuantity = devSubHashHandle.hashQuantity.getHash("HEX");
    devSubHashHandle.hashPrice.update(devSubHashHandle.prodCode + "price" + devSubHashHandle.prodPrice);
    devSubHashHandle.hashValuePrice = devSubHashHandle.hashPrice.getHash("HEX");
    devSubHashHandle.hashCategory.update(devSubHashHandle.prodCode + "category" + devSubHashHandle.prodCategory);
    devSubHashHandle.hashValueCategory = devSubHashHandle.hashCategory.getHash("HEX");
    devSubHashHandle.hashSubFreq.update(devSubHashHandle.prodCode + "sub_frequency" + devSubHashHandle.prodSubFreq);
    devSubHashHandle.hashValueSubFreq = devSubHashHandle.hashSubFreq.getHash("HEX");
    devSubHashHandle.hashCustQty.update(devSubHashHandle.prodCode + "custqty" + devSubHashHandle.prodQuantity);
    devSubHashHandle.hashValueCustQty = devSubHashHandle.hashCustQty.getHash("HEX");
    devSubHashHandle.hashSubStartDate.update(devSubHashHandle.prodCode + "sub_startdate" + devSubHashHandle.prodSubStartDate);
    devSubHashHandle.hashValueSubStartDate = devSubHashHandle.hashSubStartDate.getHash("HEX");
    devSubHashHandle.hashCode.update(devSubHashHandle.prodCode + "code" + devSubHashHandle.prodCode);
    devSubHashHandle.hashValueCode = devSubHashHandle.hashCode.getHash("HEX");
    //item 2 : DNSFilteringSub
    dNSSubHashHandle.hashName.update(dNSSubHashHandle.prodCode + "name" + dNSSubHashHandle.prodName);
    dNSSubHashHandle.hashValueName = dNSSubHashHandle.hashName.getHash("HEX");
    dNSSubHashHandle.hashQuantity.update(dNSSubHashHandle.prodCode + "quantity" + dNSSubHashHandle.prodQuantity);
    dNSSubHashHandle.hashValueQuantity = dNSSubHashHandle.hashQuantity.getHash("HEX");
    dNSSubHashHandle.hashPrice.update(dNSSubHashHandle.prodCode + "price" + dNSSubHashHandle.prodPrice);
    dNSSubHashHandle.hashValuePrice = dNSSubHashHandle.hashPrice.getHash("HEX");
    dNSSubHashHandle.hashCategory.update(dNSSubHashHandle.prodCode + "category" + dNSSubHashHandle.prodCategory);
    dNSSubHashHandle.hashValueCategory = dNSSubHashHandle.hashCategory.getHash("HEX");
    dNSSubHashHandle.hashSubFreq.update(dNSSubHashHandle.prodCode + "sub_frequency" + dNSSubHashHandle.prodSubFreq);
    dNSSubHashHandle.hashValueSubFreq = dNSSubHashHandle.hashSubFreq.getHash("HEX");
    dNSSubHashHandle.hashCustQty.update(dNSSubHashHandle.prodCode + "custqty" + dNSSubHashHandle.prodQuantity);
    dNSSubHashHandle.hashValueCustQty = dNSSubHashHandle.hashCustQty.getHash("HEX");
    dNSSubHashHandle.hashSubStartDate.update(dNSSubHashHandle.prodCode + "sub_startdate" + dNSSubHashHandle.prodSubStartDate);
    dNSSubHashHandle.hashValueSubStartDate = dNSSubHashHandle.hashSubStartDate.getHash("HEX");
    dNSSubHashHandle.hashCode.update(dNSSubHashHandle.prodCode + "code" + dNSSubHashHandle.prodCode);
    dNSSubHashHandle.hashValueCode = dNSSubHashHandle.hashCode.getHash("HEX");
    //item 3: serviceProration
    serviceProrationHashHandle.hashName.update(serviceProrationHashHandle.prodCode + "name" + serviceProrationHashHandle.prodName);
    serviceProrationHashHandle.hashValueName = serviceProrationHashHandle.hashName.getHash("HEX");
    serviceProrationHashHandle.hashQuantity.update(serviceProrationHashHandle.prodCode + "quantity" + serviceProrationHashHandle.prodQuantity);
    serviceProrationHashHandle.hashValueQuantity = serviceProrationHashHandle.hashQuantity.getHash("HEX");
    serviceProrationHashHandle.hashPrice.update(serviceProrationHashHandle.prodCode + "price" + serviceProrationHashHandle.prodPrice);
    serviceProrationHashHandle.hashValuePrice = serviceProrationHashHandle.hashPrice.getHash("HEX");
    serviceProrationHashHandle.hashCategory.update(serviceProrationHashHandle.prodCode + "category" + serviceProrationHashHandle.prodCategory);
    serviceProrationHashHandle.hashValueCategory = serviceProrationHashHandle.hashCategory.getHash("HEX");
    serviceProrationHashHandle.hashCode.update(serviceProrationHashHandle.prodCode + "code" + serviceProrationHashHandle.prodCode);
    serviceProrationHashHandle.hashValueCode = serviceProrationHashHandle.hashCode.getHash("HEX");


    /* -------------------------------------------------------------------------- */
    /*                                   LOGIC                                    */
    /* -------------------------------------------------------------------------- */
    

    // did the user select to cancel an existing subscription?
    if (this.cancelling) {

      //Subscriber cancelled so send cancellation cart string foxyIO cart.
      this.straightToCartString = this.constructCartLinkCancellation(prodSubToken, subCancel, devSubHashHandle,dNSSubHashHandle,custGUID,custGUID2);

      // set the new device quantities and when done set the sub status to activating so they  know it is progress
      this.setPendingdeviceQuantities(0).then((e) =>{this.setSubStatusPending()});

      //set the status accordingly.
      this.submittedStatus = submissionStatus.cancelledSubscription;

    // the user is not cancelling
    } else {

      //is this is new subscriber?
      if(this.currentUser.backendUserData.subStatusID == "0") {
        
        /* -------------------------------- Version 2 ------------------------------- */
        
        //New subscriber so all devices and filtering choices are new, add as is to foxyIO cart.
        this.straightToCartString = this.constructCartLinkNewSub(devSubHashHandle,dNSSubHashHandle,custGUID,custGUID2);
        this.submittedStatus = submissionStatus.addedDevices;

        this.setSubStatusActivating(); //mark their record as activating.

      // this user is not a new subscriber
      } else {

        //is their account disabled by us?
        if(this.currentUser.backendUserData.subStatusID == "5") {

          //set the status accordingly.
          this.submittedStatus = submissionStatus.accountDisabled;

        // their account is not disabled
        } else {
        
        /* -------------------------------- Version 2 ------------------------------- */
        //is the user decreasing their device quantity?
        if(formSubData.pendingDeviceSubQuantity < cSubData.currentSubDevCount) {

          // set the new device quantities and when done set the sub status to activating so they  know it is progress
          this.setPendingdeviceQuantities().then((e) =>{this.setSubStatusPending()});

          //set the status accordingly.
          this.submittedStatus = submissionStatus.decreasedDevices;
          
        //The user isn't decreasing device quantity, is it staying the same?
        } else if((formSubData.pendingDeviceSubQuantity) == cSubData.currentSubDevCount) {

          //did the user change DNSFiltering options?
          if((formSubData.pendingDNSFilteringQuantity) != cSubData.currentDNSFiltering) {

            //did the user turn off their DNSFiltering options?
            if((formSubData.pendingDNSFilteringQuantity) < cSubData.currentDNSFiltering) {

              //set the new device quantities and when done set the sub status to activating so they know it is in progress.
              this.setPendingdeviceQuantities().then((e) => {this.setSubStatusPending()});

              //set the status accordingly.
              this.submittedStatus = submissionStatus.removedFiltering;

            // the user enabled DNSFiltering on existing devices
            } else {

              //set the cart with thier new device and DNS numbers
              this.straightToCartString = this.constructCartLinkExistingSub(devSubHashHandle,dNSSubHashHandle,serviceProrationHashHandle,custGUID,custGUID2,prodSubToken);
              
              //mark the backend as activating so the user is aware.
              this.setSubStatusActivating();

              // set dateChangeRequired Boolean
              this.setDateChangeRequired();

              //set the status accordingly.
              this.submittedStatus = submissionStatus.addedFiltering;

            }

          //the user did not change DNSFIltering options
          } else {

            //Do nothing, device quantities did not change, and filtering did not change.
            
            //set the status accordingly.
            this.submittedStatus = submissionStatus.noChanges;

          }
          //the user is increasing their device quantity.
        } else {

          //setup the new cart link
          this.straightToCartString = this.constructCartLinkExistingSub(devSubHashHandle,dNSSubHashHandle,serviceProrationHashHandle,custGUID,custGUID2,prodSubToken);

          //mark the backend as activating so the user is aware.
          this.setSubStatusActivating();
          
          // set dateChangeRequired Boolean
          this.setDateChangeRequired();

          //set the status accordingly.
          this.submittedStatus = submissionStatus.addedDevices;
          
        }

      }
    }
  
  }
  
}


/**
 * 
 */
constructCartLinkCancellation(prodSubToken, subCancel, devSubHashHandle, dNSSubHashHandle ,custGUID, custGUID2): string {
  
  //start the cart weblink
  var straightToCartString = "https://vpngreyhex.foxycart.com/cart?cart=checkout";

  //add the backend user id.
  straightToCartString += "&sub_token=" + prodSubToken;
  straightToCartString += "&sub_cancel=" + subCancel;
  straightToCartString += "&h:customer_guid=" + custGUID;
  straightToCartString += "&h:customer_guid2=" + custGUID2;

  //add the product representing device subscriptions - appended to existing subscriptions as seperate "item"
  // straightToCartString += "&2:name||" + devSubHashHandle.hashValueName + "=" + devSubHashHandle.prodName;
  // straightToCartString += "&2:code||" + devSubHashHandle.hashValueCode + "=" + devSubHashHandle.prodCode;
  // straightToCartString += "&2:price||" + devSubHashHandle.hashValuePrice + "=" + devSubHashHandle.prodPrice;
  // straightToCartString += "&2:quantity||" + devSubHashHandle.hashValueQuantity + "=" + devSubHashHandle.prodQuantity;
  // straightToCartString += "&2:category||" + devSubHashHandle.hashValueCategory + "=" + devSubHashHandle.prodCategory;
  // straightToCartString += "&2:sub_frequency||" + devSubHashHandle.hashValueSubFreq + "=" + devSubHashHandle.prodSubFreq;
  // straightToCartString += "&2:custqty||" + devSubHashHandle.hashValueCustQty + "=" + devSubHashHandle.prodQuantity;

  //add the product representing the dns filtering subscriptions
  // straightToCartString += "&3:name||" + dNSSubHashHandle.hashValueName + "=" + dNSSubHashHandle.prodName;
  // straightToCartString += "&3:code||" + dNSSubHashHandle.hashValueCode + "=" + dNSSubHashHandle.prodCode;
  // straightToCartString += "&3:price||" + dNSSubHashHandle.hashValuePrice + "=" + dNSSubHashHandle.prodPrice;
  // straightToCartString += "&3:quantity||" + dNSSubHashHandle.hashValueQuantity + "=" + dNSSubHashHandle.prodQuantity;
  // straightToCartString += "&3:category||" + dNSSubHashHandle.hashValueCategory + "=" + dNSSubHashHandle.prodCategory;
  // straightToCartString += "&3:sub_frequency||" + dNSSubHashHandle.hashValueSubFreq + "=" + dNSSubHashHandle.prodSubFreq;
  // straightToCartString += "&3:custqty||" + dNSSubHashHandle.hashValueCustQty + "=" + dNSSubHashHandle.prodQuantity;

  //output the cart link to console for review
  console.log("product link = " + straightToCartString);

  //return the cart link
  return straightToCartString;

}


  /**
 * Creates a Cart with the new device and DNS subscriptions.
 * meant to be used to add in a new set of subscriptions.
 * @param devSubHashHandle 
 * @param dNSSubHashHandle 
 * @param custGUID 
 * @param custGUID2
 * @returns 
 */
  constructCartLinkNewSub(devSubHashHandle,dNSSubHashHandle,custGUID, custGUID2): string {      
      //start the cart weblink
      var straightToCartString = "https://vpngreyhex.foxycart.com/cart?cart=checkout&empty=true";
      //add the backend user id.
      straightToCartString += "&h:customer_guid=" + custGUID;  
      straightToCartString += "&h:customer_guid2=" + custGUID2;  
      //add the product representing the device subscriptions
      straightToCartString += "&2:name||" + devSubHashHandle.hashValueName + "=" + devSubHashHandle.prodName;
      straightToCartString += "&2:code||" + devSubHashHandle.hashValueCode + "=" + devSubHashHandle.prodCode;
      straightToCartString += "&2:price||" + devSubHashHandle.hashValuePrice + "=" + devSubHashHandle.prodPrice;
      straightToCartString += "&2:quantity||" + devSubHashHandle.hashValueQuantity + "=" + devSubHashHandle.prodQuantity;
      straightToCartString += "&2:category||" + devSubHashHandle.hashValueCategory + "=" + devSubHashHandle.prodCategory;
      straightToCartString += "&2:sub_frequency||" + devSubHashHandle.hashValueSubFreq + "=" + devSubHashHandle.prodSubFreq;
      straightToCartString += "&2:custqty||" + devSubHashHandle.hashValueCustQty + "=" + devSubHashHandle.prodQuantity;
      //add the product representing the dns filtering subscriptions
      straightToCartString += "&3:name||" + dNSSubHashHandle.hashValueName + "=" + dNSSubHashHandle.prodName;
      straightToCartString += "&3:code||" + dNSSubHashHandle.hashValueCode + "=" + dNSSubHashHandle.prodCode;
      straightToCartString += "&3:price||" + dNSSubHashHandle.hashValuePrice + "=" + dNSSubHashHandle.prodPrice;
      straightToCartString += "&3:quantity||" + dNSSubHashHandle.hashValueQuantity + "=" + dNSSubHashHandle.prodQuantity;
      straightToCartString += "&3:category||" + dNSSubHashHandle.hashValueCategory + "=" + dNSSubHashHandle.prodCategory;
      straightToCartString += "&3:sub_frequency||" + dNSSubHashHandle.hashValueSubFreq + "=" + dNSSubHashHandle.prodSubFreq;
      straightToCartString += "&3:custqty||" + dNSSubHashHandle.hashValueCustQty + "=" + dNSSubHashHandle.prodQuantity;
      //output the cart link to console for review
      console.log("product link = " + straightToCartString);
      //return the cart link
      return straightToCartString;
}

  /**
   * Creates a Cart with the proration product to account for differences in subscriptions.
   * devices quantity should be 1 less of the selection on the form since one device will be the free device.
   * meant to be used when the customer already has a subscription
   * @param devSubHashHandle 
   * @param dNSSubHashHandle 
   * @param serviceProrationHashHandle 
   * @param custGUID 
   * @param custGUID2
   * @param prodSubToken 
   * @returns 
   */
  constructCartLinkExistingSub(devSubHashHandle,dNSSubHashHandle,serviceProrationHashHandle,custGUID,custGUID2,prodSubToken): string {   
    //start the cart weblink
    var straightToCartString = "https://vpngreyhex.foxycart.com/cart?cart=checkout&empty=true";
    //add the backend user id.
    straightToCartString += "&sub_token=" + prodSubToken;
    straightToCartString += "&h:customer_guid=" + custGUID;
    straightToCartString += "&h:customer_guid2=" + custGUID2;

    //add the product representing device subscriptions - appended to existing subscriptions as seperate "item"
    straightToCartString += "&2:name||" + devSubHashHandle.hashValueName + "=" + devSubHashHandle.prodName;
    straightToCartString += "&2:code||" + devSubHashHandle.hashValueCode + "=" + devSubHashHandle.prodCode;
    straightToCartString += "&2:price||" + devSubHashHandle.hashValuePrice + "=" + devSubHashHandle.prodPrice;
    straightToCartString += "&2:quantity||" + devSubHashHandle.hashValueQuantity + "=" + devSubHashHandle.prodQuantity;
    straightToCartString += "&2:category||" + devSubHashHandle.hashValueCategory + "=" + devSubHashHandle.prodCategory;
    straightToCartString += "&2:sub_frequency||" + devSubHashHandle.hashValueSubFreq + "=" + devSubHashHandle.prodSubFreq;
    straightToCartString += "&2:custqty||" + devSubHashHandle.hashValueCustQty + "=" + devSubHashHandle.prodQuantity;
    //add the product representing the dns filtering subscriptions
    straightToCartString += "&3:name||" + dNSSubHashHandle.hashValueName + "=" + dNSSubHashHandle.prodName;
    straightToCartString += "&3:code||" + dNSSubHashHandle.hashValueCode + "=" + dNSSubHashHandle.prodCode;
    straightToCartString += "&3:price||" + dNSSubHashHandle.hashValuePrice + "=" + dNSSubHashHandle.prodPrice;
    straightToCartString += "&3:quantity||" + dNSSubHashHandle.hashValueQuantity + "=" + dNSSubHashHandle.prodQuantity;
    straightToCartString += "&3:category||" + dNSSubHashHandle.hashValueCategory + "=" + dNSSubHashHandle.prodCategory;
    straightToCartString += "&3:sub_frequency||" + dNSSubHashHandle.hashValueSubFreq + "=" + dNSSubHashHandle.prodSubFreq;
    straightToCartString += "&3:custqty||" + dNSSubHashHandle.hashValueCustQty + "=" + dNSSubHashHandle.prodQuantity;
    //add the product representing the one time proration of service for differences
    straightToCartString += "&4:name||" + serviceProrationHashHandle.hashValueName + "=" + serviceProrationHashHandle.prodName;
    straightToCartString += "&4:code||" + serviceProrationHashHandle.hashValueCode + "=" + serviceProrationHashHandle.prodCode;
    straightToCartString += "&4:price||" + serviceProrationHashHandle.hashValuePrice + "=" + serviceProrationHashHandle.prodPrice;
    straightToCartString += "&4:quantity||" + serviceProrationHashHandle.hashValueQuantity + "=" + serviceProrationHashHandle.prodQuantity;
    straightToCartString += "&4:category||" + serviceProrationHashHandle.hashValueCategory + "=" + serviceProrationHashHandle.prodCategory;

    //output the cart link to console for review
    console.log("product link = " + straightToCartString);
    //return the cart link
    return straightToCartString;
  }

  async setSubStatusActivating(): Promise<any> {
    
    await this.currentUser.api.UpdateUserSubDev({
      id: this.currentUser.backendUserData.id,
      subStatusID: '2', //set status to activating
      _version: this.currentUser.backendUserData._version
    }).catch((e) => {
      this.currentUser.consoleDebug ? console.log(e, "Err: unable to update SubStatusID to activating status (2) in backend") : console.log()
    });

  }

  async setSubStatusPending(): Promise<any> {
    
    await this.currentUser.api.UpdateUserSubDev({
      id: this.currentUser.backendUserData.id,
      subStatusID: '7', //set status to Pending requested changes
      _version: this.currentUser.backendUserData._version
    }).catch((e) => {
      this.currentUser.consoleDebug ? console.log(e, "Err: unable to update SubStatusID to pending requested changes status (7) in backend") : console.log()
    });

  }

  async setPendingdeviceQuantities(forcedValue?: number) {

    if (typeof forcedValue !== 'undefined') {

      await this.currentUser.api.UpdateSub({
        id: this.currentUser.backendUserData.userID,
        pendingSubDevCount: forcedValue,
        pendingDNSFiltering: forcedValue,
        _version: this.currentSubData._version
      }).catch((e) => {
          this.currentUser.consoleDebug ? console.log(e, "Err: unable to update pending device quantities in backend") : console.log()
      });

    } else {

      await this.currentUser.api.UpdateSub({
        id: this.currentUser.backendUserData.userID,
        pendingSubDevCount: this.model.pendingDeviceSubQuantity,
        pendingDNSFiltering: this.model.pendingDNSFilteringQuantity,
        _version: this.currentSubData._version
      }).catch((e) => {
          this.currentUser.consoleDebug ? console.log(e, "Err: unable to update pending device quantities in backend") : console.log()
      });

    }
  }

  async setDateChangeRequired() {

    await this.currentUser.api.UpdateSub({
      id: this.currentUser.backendUserData.userID,
      dateChangeRequired: 1,
      _version: this.currentSubData._version
    }).catch((e) => {
        this.currentUser.consoleDebug ? console.log(e, "Err: unable to update next due date in backend") : console.log()
    });
  }

  triggerCompletionModal(content: any, openCartLink: boolean) {

    this.modalService.open(content, {centered: true}).result.then( (result) => {
      
      console.log(result);

      // submission modal was closed, is cart link needed?
      if (openCartLink) {

        //open cart link in new window
        window.open(this.straightToCartString, "_blank");

      }

      // close the update modal.
      this.closeModalEvent.emit("UpdateSub clicked");

    }, (reason) => {
      console.log(reason);
    }
    );

  }

  cancelSubClicked(content): void {
    
    //set the form state that it has been submitted, even though the user used the cancel button.
    this.submitted = true;
    
    //Set state that the user is cancelling the service
    this.cancelling = true;
    
    //Process the transactions same as if the update form was closed normally.
    this.processTransactionChanges(this.currentSubData, this.model);

    //check the results of the transaction process so we know if we need to make a modal popup or not.
    switch(this.submittedStatus) {
      case submissionStatus.decreasedDevices: // Decreased Devices, show modal regarding next month changes.
        
        //set message to notify user of action
        this.submissionModalMessage = "Maximum usable devices will be reduced at the start of the next billing cycle to prevent loss of value."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.removedFiltering:

        //set message to notify user of action
        this.submissionModalMessage = "Device filtering will be removed at the start of next billing cycle to prevent the loss of value."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.noChanges: // the customer has not made any changes
        
        //set message to notify user of action
        this.submissionModalMessage = "No changes have been selected."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.addedFiltering: // the customer added only filtering to their service
    
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
          
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.addedDevices: // the customer added new device subscriptions

        // set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
                
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.addedDevicesWithFree: // the customer added new devices subscriptions and needs a free device
      
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
          
        // open the modal with the message.
        this.triggerCompletionModal(content,true);

      break;

      case submissionStatus.onlyFreeDevice: // the customer is only getting the free device
        
        //set message to notify user of action
        this.submissionModalMessage = "A New window will be opened. Please complete checkout in the payment portal."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, true);

      break;

      case submissionStatus.accountDisabled: // the customer's account is disabled
      
        //set message to notify user of action
        this.submissionModalMessage = "Your account is currently disabled, please contact support."
        
        // open the modal with the message.
        this.triggerCompletionModal(content, false);

      break;

      case submissionStatus.cancelledSubscription: // the customer cancelled their account
      
      //set message to notify user of action
      this.submissionModalMessage = "Your subscription will be cancelled at the end of the billing cycle and you will not be charged at renewal time. "
      
      // open the modal with the message.
      this.triggerCompletionModal(content, true);

    break;

      default: // there has been a mistake, do nothing.
        break;
    }
  }

}

export enum submissionStatus {
 decreasedDevices = 0,
 removedFiltering,
 noChanges,
 addedFiltering,
 addedDevices,
 onlyFreeDevice,
 accountDisabled,
 addedDevicesWithFree,
 cancelledSubscription
}