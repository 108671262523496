export class UpdateDeviceFormModel {

    constructor(
        public id: string,
        public _version: number,
        public _deleted: boolean = false,
        public lastActive: string,
        public userID: string,
        public devName: string,
        public deviceType: { 
            __typename: "devType"; 
            id: string; 
            devType: string; 
            createdAt: string; 
            updatedAt: string; 
            _version: number; 
            _deleted: boolean; 
            _lastChangedAt: number; 
          },
        public deviceStatusID: string = "1",
        public dnsFiltering: boolean,
        public entryRegionChoices: { 
            __typename: "region"; 
            id: string; 
            regionName: string; 
            publicKey: string | null; 
            privateKey: string | null; 
            serverName: string | null; 
            awsRegion: string | null; 
            fQDN: string | null; 
            ipAddress: string | null; 
            ipTablesPostUp: string | null; 
            ipTablesPostDown: string | null; 
            createdAt: string | null; 
            updatedAt: string | null; 
            prettyName: string | null; 
            _version: number; 
            _deleted: boolean | null; 
            _lastChangedAt: number; 
        }[] = [],
        public exitRegionChoices: { 
            __typename: "region"; 
            id: string; 
            regionName: string; 
            publicKey: string | null; 
            privateKey: string | null; 
            serverName: string | null; 
            awsRegion: string | null; 
            fQDN: string | null; 
            ipAddress: string | null; 
            ipTablesPostUp: string | null; 
            ipTablesPostDown: string | null; 
            createdAt: string | null; 
            updatedAt: string | null; 
            prettyName: string | null; 
            _version: number; 
            _deleted: boolean | null; 
            _lastChangedAt: number; 
        }[] = []        
    ) {

    }

}
