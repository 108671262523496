<div class="container-fluid segmentLight" id="pricing">

    <div class="row py-5 align-items-center">

        <div class="card-deck mx-auto">

            <div class="card card3D-lg card3D-light cardbg-light">
                <img class="card-img-top" src="../../assets/img/CostOfCoffee.jpg" alt="First Connection Free">
                <div class="card-body card-body-background">
                    <p class="card-text card-question text-center">SUBSCRIPTION PRICING</p>
                    <p class="card-text text-center">Monthly fee's are determined by the number of devices you sign up to connect and the level of filtering you want on those connections. </p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-header">
                    <h5 class="card-title text-center"><fa-icon [icon]="faHandHoldingUsd"></fa-icon> First Connection</h5>
                </div>
                <div class="card-body card-body-background2 d-flex align-items-center justify-content-center">
                        <p class="lead text-center"><strong>$0.00 Free</strong></p>
                </div>
                <div class="card-footer">
                    <p class="card-text">No credit card required to protect your first device</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-header">
                    <h5 class="card-title text-center"><fa-icon [icon]="faLaptop"></fa-icon>&nbsp;<fa-icon [icon]="faMobileAlt"></fa-icon> Device Access</h5>
                </div>
                <div class="card-body card-body-background2 d-flex align-items-center justify-content-center">
                    <p class="lead text-center"><strong>$2.00 per device per month</strong></p>
                </div>
                <div class="card-footer">
                    <p class="card-text">Subscriptions automatically renewal after a month.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-header">
                    <h5 class="card-title text-center"><fa-icon [icon]="faFilter"></fa-icon> DNS Filtering</h5>
                </div>
                <div class="card-body card-body-background2 d-flex align-items-center justify-content-center">
                    <p class="lead text-center"><strong>$0.99 per device per month</strong></p>
                </div>
                <div class="card-footer">
                    <p class="card-text">DNS Filtering cost is totalled for all devices.</p>
                </div>
            </div>

        </div>

    </div>

</div>