import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/Types/user.model';
import { faMobileAlt, faTrash } from '@fortawesome/free-solid-svg-icons'; 
import { DeleteDeviceFormModel } from 'src/Types/delete-device-form-model';

@Component({
  selector: 'app-delete-device-form',
  templateUrl: './delete-device-form.component.html',
  styleUrls: ['./delete-device-form.component.css']
})
export class DeleteDeviceFormComponent implements OnInit {

/* -------------------------------------------------------------------------- */
/*                              GLOBAL VARIABLES                              */
/* -------------------------------------------------------------------------- */

@Input() currentUser : User;
@Input() displayDeviceNumber : number;
@Output() closeModalEvent = new EventEmitter<string>();


/* ------------------------------ ICON HANDLES ------------------------------ */

faMobileAlt = faMobileAlt;
faTrash = faTrash;

/* ----------------------------- STATE VARIABLES ---------------------------- */

deleteDeviceResults: DeleteDeviceFormModel = new DeleteDeviceFormModel("name","9999999999", "0", 1);
model: DeleteDeviceFormModel = new DeleteDeviceFormModel("name","9999999999", "0", 1);
submitted = false;

/* --------------------------------- HANDLES -------------------------------- */

/**
 * Input handle for deleting device
 */
  deleteDeviceInput = {
  id: "0000000000", // replace with the id we are changing?
  deviceStatusID: "0", // set the devicestatusID to deleting (4)
  _version: 0 // we need to use the current version of the file if we intened to update the live system.
};

  /**
   * no values required
   */
  constructor() { }

  ngOnInit(): void {
    this.loadDeviceDetails();
  }

  private loadDeviceDetails(): void {

    //copy the verification fields over 
    this.model.id = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].id;
    this.model._version = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber]._version;
    this.model.deviceStatusID = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceStatusID;
    this.model.devName = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].devName;
  }

  /**
   * track the form state after submission
   */
  onSubmit(): void {
    this.submitted = true;
  }

  submitDeleteDeviceResults(): void {

    //get data from users object
    this.deleteDeviceResults = this.model;

    //modify status to that of deletion (4)
    this.deleteDeviceResults.deviceStatusID = "4";

    //reset the form
    this.model = new DeleteDeviceFormModel("name","9999999999", "4", 1);
    
    //update the backend
    this.deleteBackendDevice();
    
    // close the modal, and confirm via deletion.
    this.closeModalEvent.emit("Delete clicked");
  }

  deleteBackendDevice() {
    this.deleteDeviceInput.id = this.deleteDeviceResults.id;
    this.deleteDeviceInput._version = this.deleteDeviceResults._version;
    this.deleteDeviceInput.deviceStatusID = this.deleteDeviceResults.deviceStatusID;

    this.currentUser.api.UpdateDev(this.deleteDeviceInput).then(
      event => {
        this.currentUser.consoleDebug ? console.log(event.devName, " has been marked for deletion") : console.log();
      }
    ).catch(
      e => console.log(e, "Err: couldnt update device to deleting status.")
    );

  }

}
