import { Component, OnInit } from '@angular/core';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faAppStoreIos, faAndroid, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-wireguard-info',
  templateUrl: './wireguard-info.component.html',
  styleUrls: ['./wireguard-info.component.css']
})
export class WireguardInfoComponent implements OnInit {

  faCloudDownloadAlt = faCloudDownloadAlt;
  faLinux = faLinux;
  faWindows = faWindows;
  faAndroid = faAndroid;
  faAppStoreIos = faAppStoreIos;


  constructor() { }

  ngOnInit(): void {
  }

}
