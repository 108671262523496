import { DeviceList } from "./device-list";
import { SubStatus } from "./sub-status";
import { UserStatus } from "./user-status";

/**
 * Handle for Backend User Data with defaults to prevent null cases. 
 */
export class BackEndUserData {

    constructor(
    public id: string = "0", // backend record ID
    public userID: string = "Guest", // Cognito user ID
    public email: string = "Guest@greyhex.io", // user registered email
    public subStatusID: string = "0", // subscription status ID
    public subStatus: SubStatus = new SubStatus(), // subscription status object
    public userStatusID: string = "0", // user status ID
    public userStatus: UserStatus = new UserStatus(), // user status object
    public deviceList: DeviceList = new DeviceList(), // arry of device objects
    public signedOn: boolean = false, // track if the user is signed on or not
    public lastActive: string = "2021-01-01T01:01:01.001Z", // when was the user last active?
    public _version: number = 0,
    public _deleted: boolean = false
    ) {
        /**
         * Code to execute on contrstruction
         */
        lastActive = new Date().toISOString();
    }

    
}
