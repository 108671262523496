<div class="container-fluid segmentDark">

    <div class="row py-5">

        <div class="card-deck mx-auto">
            <div class="card card3D-lg card3D-dark cardbg-light">
                <img class="card-img-top" src="../../assets/img/corridor.jpg" alt="Ad Blocking">
                <div class="card-body">
                  <p class="card-text card-question text-center">WHY GREYHEX VPN?</p>
                  <p class="card-text text-center">GreyHex VPN is logless, blocks ads by default, and built on robust cloud infrastructure.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-dark cardbg-dark">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faTrashAlt"></fa-icon><span class="ml-3"> BEYOND LOGLESS</span></h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">GreyHex infrastructure is regularly terminated and refreshed, which means new temporary storage and IP Addresses.</p>
                    <br>
                    <p class="lead mt-3">GreyHex VPN does not record traffic or metadata logs and the VPN servers operate for short lifetimes, which keeps your internet fingerprint low.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-dark cardbg-dark">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faHandPaper"></fa-icon><span class="ml-3">AD BLOCKING</span></h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">Ads can be thought of as mini-webpages embeded in the apps and websites you like to use.</p>
                    <br>
                    <p class="lead mt-3">GreyHex keeps a big list of these mini-webpages and blocks them from going through the VPN.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-dark cardbg-dark">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faCloudversify"></fa-icon><span class="ml-3">CLOUD BASED</span></h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">Thanks to Cloud Technology GreyHex VPN ensures consistent service during usage spikes by automatically increasing or decreasing resources within the VPN.</p>
                </div>
            </div>
        </div>

    </div>
</div>