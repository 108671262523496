/* 
// vpn.Greyhex.io
//
// A web application designed to permit interaction with users across multiple devices.
// Whereby, a user is capable of registering to use a vpn service, 
// and permitted the use of digital tools designed to facilitate the management of the service,
// at the discrection of the service provider. 

// Designed by: Christopher S. Wykel
// 
// All user interface designs, such as animations, color schemes, and interaction processes,
// excluding branding logo were created for the Greyhex vpn application, though are owned solely by its creator.

// Developed by: Christopher S. Wykel
//
// All code associated with this application is proprietary, 
// was developed for the use of the Greyhex vpn application, and is wholely owned by its creator.

// Terms of Use:
// 
// All code and design work created for the Greyhex vpn web application shall not be used,
// in any derivitive works, or as part of any application, including future variations of itself  
// without the explicit permissions of the creator, whereby that use may conflict with or deviate from
// the intent of the application as described above in the description.


// Christopher S. Wykel
// Written: 3/17/2021
// Last Updated: 3/17/2021
*/ 




import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));