<form (ngSubmit)="onSubmit()" #updateDeviceForm="ngForm">
    <!-- Device Name -->
    <div class="form-row mb-3">
        <div class="input-group">
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:12rem;"
                    for="devName"
                >
                <fa-icon 
                    [icon]="faSignature" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;Device Name
                </label>
            </div>
            <input
                id="devName" 
                type="text"
                required
                class="form-control"
                [(ngModel)]="model.devName"
                name="devName"
                placeholder="model.devName"
                #devName="ngModel"
            >
        </div>
        <div 
            [hidden]="devName.valid || devName.pristine"
            class="alert alert-danger input-group"
            role="alert"
        >
        A Device Name is required
        </div>
    </div>

    <!-- Device Type -->
    <div class="form-row mb-3">
        <div class="input-group">
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:12rem;"
                    for="deviceType"
                >
                <fa-icon 
                    [icon]="faMobileAlt" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;Device Type
                </label>
            </div>
            <select
                class="form-control" 
                id="deviceType"
                required
                [(ngModel)]="model.deviceType" 
                name="deviceType"
                #deviceType="ngModel"                
            >
            <ng-container *ngIf="(deviceTypeOptions != null); else emptyDeviceTypeOptionsElseBlock">
                <ng-container *ngFor="let option of deviceTypeOptions">
                    <option *ngIf="option.id != 0" [ngValue]="option">{{option.devType}}</option>
                </ng-container>
            </ng-container>
            <ng-template #emptyDeviceTypeOptionsElseBlock>
                <option disabled >Loading Options...</option>
            </ng-template>    
            </select>  
        </div>
        <div 
            [hidden]="deviceType.valid || deviceType.pristine" 
            class="alert alert-danger input-group"
            role="alert"
        >
        A Device Type is required
        </div>
    </div>

    <!-- DNS Filtering -->
    <div *ngIf="this.HasDNSSubscription(); then DNSOptionEnabled else DNSOptionDisabled"></div>
    
    <ng-template #DNSOptionEnabled>    
        <div class="form-row mb-3" >
            
            <div class="input-group">
                <div class="input-group-prepend">
                    <label 
                        class="input-group-text bg-light"
                        style="min-width:12rem;"
                        for="dnsFiltering"
                    >
                    <fa-icon 
                        [icon]="faFilter" 
                        class="text-danger"
                    ></fa-icon>
                    &nbsp;DNS Filtering
                    </label>
                </div>
                <select
                    class="form-control" 
                    id="dnsFiltering"
                    required
                    [(ngModel)]="model.dnsFiltering" 
                    name="dnsFiltering"
                    #dnsFiltering="ngModel"                
                    placeholder="model.dnsFiltering"
                >
                <option [ngValue]=false>Disabled</option>
                <option [ngValue]=true>Enabled</option>    
                </select>  
            </div>

            <div 
                [hidden]="dnsFiltering.valid || dnsFiltering.pristine" 
                class="alert alert-danger input-group"
                role="alert"
            >
            A DNS choice is required
            </div>

        </div> 
    </ng-template>

    <ng-template #DNSOptionDisabled>    
        <div class="form-row mb-3" >

                <div class="input-group">
                    <div class="input-group-prepend">
                        <label 
                            class="input-group-text bg-light"
                            style="min-width:12rem;"
                            for="dnsFiltering"
                        >
                        <fa-icon 
                            [icon]="faFilter" 
                            class="text-danger"
                        ></fa-icon>
                        &nbsp;DNS Filtering
                        </label>
                    </div>
                    <select
                        class="form-control" 
                        id="dnsFiltering"
                        required
                        [(ngModel)]="model.dnsFiltering" 
                        name="dnsFiltering"
                        #dnsFiltering="ngModel"                
                        placeholder="model.dnsFiltering"
                        disabled
                    >
                    <option selected [ngValue]=false>Disabled</option>
                    </select>  
                </div>

                <div 
                    [hidden]="dnsFiltering.valid || dnsFiltering.pristine" 
                    class="alert alert-danger input-group"
                    role="alert"
                >
                A DNS choice is required
                </div>

        </div> 
    </ng-template>

    <!-- Entry Regions -->
    <div [hidden]="true" class="form-row mb-3">
        <div class="input-group">
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:12rem;"
                    for="entryRegions"
                >
                <fa-icon 
                    [icon]="faGlobeAmericas" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;Entry Regions
                </label>
            </div>
            <select
                class="form-control" 
                id="entryRegions"
                required
                multiple
                [(ngModel)]="model.entryRegionChoices" 
                name="entryRegions"
                #entryRegions="ngModel"                
            >
            <ng-container *ngIf="(regionOptions != null) else emptyEntryRegionOptionsElseBlock">
                <ng-container *ngFor="let option of regionOptions">
                    <option *ngIf="option.id !=0" [ngValue]="option">{{option.prettyName}}</option>
                </ng-container>
            </ng-container>
            <ng-template #emptyEntryRegionOptionsElseBlock>
                <option disabled>Loading Options...</option>
            </ng-template>
            </select>
        </div>
        <div 
            [hidden]="entryRegions.valid || entryRegions.pristine" 
            class="alert alert-danger input-group"
            role="alert"
        >
        At least one Entry Region is required.
        </div>
    </div>

    <!-- Exit Regions -->
    <div class="form-row mb-3">
        <div class="input-group">
            <div class="input-group-prepend">
                <label 
                    class="input-group-text bg-light"
                    style="min-width:12rem;"
                    for="exitRegions"
                >
                <fa-icon 
                    [icon]="faGlobeAmericas" 
                    class="text-danger"
                ></fa-icon>
                &nbsp;Exit Regions
                </label>
            </div>
            <select
                class="form-control" 
                id="exitRegions"
                required
                multiple
                [(ngModel)]="model.exitRegionChoices" 
                name="exitRegions"
                #exitRegions="ngModel"               
                (ngModelChange)="updateEntryRegion()" 
            >
            <ng-container *ngIf="(regionOptions != null) else emptyExitRegionOptionsElseBlock">
                <ng-container *ngFor="let option of regionOptions">
                    <option *ngIf="option.id !=0" [ngValue]="option">{{option.prettyName}}</option>
                </ng-container>
            </ng-container>
            <ng-template #emptyExitRegionOptionsElseBlock>
                <option disabled>Loading Options...</option>
            </ng-template>
            </select>
        </div>
        <div 
            [hidden]="exitRegions.valid || exitRegions.pristine" 
            class="alert alert-danger input-group"
            role="alert"
        >
        At least one Exit Region is required
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-auto ml-auto">
                <button type="submit" class="btn btn-dark" [disabled]="!updateDeviceForm.form.valid" (click)="submitUpdateDeviceResults(); updateDeviceForm.reset()">Update&nbsp;<fa-icon [icon]="faEdit"></fa-icon></button>
            </div>
        </div>
        
    </div>
</form>