<div class="container-fluid segmentLight" id="information">

    <div class="row py-5">
        
        <div class="card-deck mx-auto">

            <div class="card card3D-lg card3D-light cardbg-light">
                <img class="card-img-top" src="../../assets/img/whatisavpn.jpg" alt="Privacy and Security">
                <div class="card-body">
                  <p class="card-text card-question text-center">WHAT IS A VPN?</p>
                  <p class="card-text text-center">A Virtual Private Network (VPN) is a collection of computers securely connected to allow information to be exchanged privately.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center">PRIVACY & SECURITY</h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">A VPN uses encryption to scramble your data making it unreadable to everyone except the intended recipient and makes your device appear to be elsewhere.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center">GREYHEX VPN</h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">Our high-tech cutting-edge Virtual Private Network (VPN) is designed with your privacy and security in mind. So let us do the worrying about your data privacy and security.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-light cardbg-light">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center">WHY YOU NEED A VPN</h4>
                    <hr class="border-danger">
                    <p class="lead my-1 text-left">A VPN makes it more difficult to track you online whether it be your ISP, advertisers or websites in general. </p>
                    <br>
                    <p class="lead my-1 text-left">A VPN is a tool in the online privacy toolkit, it doesn't solve all of your online privacy issues, but it should be included in your efforts to protect your privacy.</p>
                </div>
            </div>
        </div>

    </div>
</div>