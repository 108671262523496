import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor() {

   }

  ngOnInit(): void {
    console.log( credentialsCheck());
  }
}

async function credentialsCheck() : Promise<Boolean> {
  try {
    const {identityId} = await Auth.currentUserCredentials();
    if(identityId == null)
    {
      Auth.federatedSignIn();
      return false;
    }
    else 
    {
      return true;
    }
 
  }
  catch (e) {
    console.log("failed CurrentUserCredentials check");
    console.log(e);
    return false;
  }
    
}
