import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/Types/user.model';
import { faSignature, faMobileAlt, faFilter, faGlobeAmericas, faEdit } from '@fortawesome/free-solid-svg-icons'; 
import { UpdateDeviceFormModel } from 'src/Types/update-device-form-model';
import { CreateDevEntryRegionInput, CreateDevExitRegionInput } from '../API.service';

@Component({
  selector: 'app-update-device-form',
  templateUrl: './update-device-form.component.html',
  styleUrls: ['./update-device-form.component.css']
})
export class UpdateDeviceFormComponent implements OnInit {

/* -------------------------------------------------------------------------- */
/*                              GLOBAL VARIABLES                              */
/* -------------------------------------------------------------------------- */

  @Input() currentUser : User;
  @Input() displayDeviceNumber : number;
  @Output() closeModalEvent = new EventEmitter<string>();

/* ------------------------------ ICON HANDLES ------------------------------ */

  faSignature = faSignature;
  faMobileAlt = faMobileAlt;
  faFilter = faFilter;
  faGlobeAmericas = faGlobeAmericas;
  faEdit = faEdit;

/* ----------------------------- STATE VARIABLES ---------------------------- */

  updateDeviceResults: UpdateDeviceFormModel = new UpdateDeviceFormModel("9999999999",0,false,"2021-04-24T16:44:26.308Z","0"," ",null,"0",false,[null],null);
  model: UpdateDeviceFormModel = new UpdateDeviceFormModel("8888888888",0,false,"2021-04-24T16:44:26.308Z","0","Your Device Name",null,"0",false,null,null);  
  submitted = false;
  hasDNSSubscription = false;

/* --------------------------------- HANDLES -------------------------------- */

  /**
   * Input handle for creating a device. Get data from user input
   * and overwrite defaults.
   */
  updateDeviceInput = {
    id : "", // so we know what device to change
    _version : 0, // so we know what version to change
    devName: "Your Device Name", // user should tell us the device name
    userID: "0000000000", // must match current user ID
    deviceTypeID: "0", // user should provide the device type for us to use based on current options.
    deviceStatusID: "1", // we are working with existing devices, assume active. but check current. user could change later
    dnsFiltering: null, // Default to false, but ask user which they want.
    lastActive: "2021-01-01T01:01:01.001Z", // when was the device last used?
  };

  /**
   * Input handle for fixing a region on a device
   */
  entryRegionInput = {
    //id: "0", automatically generated dont send anything
    devID:"0000000000", // should be overwritten with devID from CreateDeviceInput
    entryRegionID:"0", //overwrite with the region from createDeviceInput
    lastActive: "2021-04-24T16:44:26.308Z" //set the time to 0 so it adds the field in the table for later manipulation
  };

  /**
   * Input handle for fixing a regions on a device
   */
  exitRegionInput = {
    //id: "0", automatically generated dont send anything
    devID:"0000000000", // should be overwritten with devID from CreateDeviceInput
    exitRegionID:"0", //overwrite with the region from createDeviceInput
  };

  /**
   * Handle for Device type options from the backend.
   */
  deviceTypeOptions: { 
    __typename: "devType"; 
    id: string; 
    devType: string; 
    createdAt: string; 
    updatedAt: string; 
    _version: number; 
    _deleted: boolean; 
    _lastChangedAt: number; 
  }[];

  /**
   * Handle for Region options from the backend 
   */
  regionOptions: { 
    __typename: "region"; 
    id: string; 
    regionName: string; 
    publicKey: string | null; 
    privateKey: string | null; 
    serverName: string | null; 
    awsRegion: string | null; 
    fQDN: string | null; 
    ipAddress: string | null; 
    ipTablesPostUp: string | null; 
    ipTablesPostDown: string | null; 
    createdAt: string | null; 
    updatedAt: string | null; 
    prettyName: string | null; 
    _version: number; 
    _deleted: boolean | null; 
    _lastChangedAt: number; 
  }[];

  // handle for current subscription data for user in the back end.
currentSubData = {
  __typename: "sub",
  _deleted: null,
  _lastChangedAt: 1,
  _version: 1,
  currentDNSFiltering: 0,
  currentSubDevCount: 0,
  devSubExpirationDate: "1970-01-01T00:00:00.000Z",
  devSubNextDueAmount: 0.00,
  devSubNextDueDate: "1970-01-01T00:00:00.000Z",
  devSubStartDate: "1970-01-01T00:00:00.000Z",
  dnsSubExpirationDate: "1970-01-01T00:00:00.000Z",
  dnsSubNextDueAmount: 0.00,
  dnsSubNextDueDate: "1970-01-01T00:00:00.000Z",
  dnsSubStartDate: "1970-01-01T00:00:00.000Z",
  id: "00000000-0000-0000-0000-000000000000",
  lastPaidAmount: 0.00,
  lastPaidDate: "1970-01-01T00:00:00.000Z",
  lastTransactionID: "0000001",
  lastTransactionUpdate: "1970-01-01T00:00:00.000Z",
  paymentEmail: "guest@greyhex.io",
  pendingDNSFiltering: 0,
  pendingSubDevCount: 0,
  status: "approved",
  sub_Token: "",
  transactionDNSFiltering: 0,
  transactionSubDevCount: 0,
  terminationDate: null
};


  /**
   * no values required
   */
  constructor() { }

  /**
   * Executed on initilization 
   */
  ngOnInit(): void {

    this.getDeviceTypeOptions();
    this.getDeviceRegionOptions();
    this.loadCurrentDevDetails();

    if(this.currentUser.backendUserData.subStatusID == '0') {
      
      // we stick with defaults since they don't have a subscription record.
      this.hasDNSSubscription = false;

    } else {
      //get the backend user data
      this.updateUserSubscriptionData().then( event => {

        if (event != null) {
          //assign results if they exist, otherwise use defaults
          event._lastChangedAt != 1 ? this.currentSubData = event : this.currentSubData;
          this.currentSubData.currentDNSFiltering > 0 ? this.hasDNSSubscription = true : this.hasDNSSubscription = false;
        } else {

          this.hasDNSSubscription = false;

        }
        
       } );

    }


  }

  /**
    * obtains viable device options from the backend
    */
  private async getDeviceTypeOptions(): Promise<any> {
    await this.currentUser.api.ListDevTypes().then( event => {
      this.deviceTypeOptions = event.items;
      event.items.forEach( element => {
        if(this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceType.id == element.id ) {
          this.model.deviceType = element;
        }
      });
    }).catch( e => {
      console.log("unable to get the list of device types from the backend.", e);
    });
  }

  /**
   * obtains viable device region options from the backend
   */
  private async getDeviceRegionOptions(): Promise<any> {
    await this.currentUser.api.ListRegions().then( event => {
      this.regionOptions = event.items;
      event.items.forEach( regionOption => {
        this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].entryRegions.items.forEach( entryRegion => {
          if(entryRegion.entryRegionID == regionOption.id) {
            this.model.entryRegionChoices == null ? this.model.entryRegionChoices = [regionOption] : this.model.entryRegionChoices.push(regionOption);
          }
        });
        this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].exitRegions.items.forEach( exitRegion => {
          if(exitRegion.exitRegionID == regionOption.id) {
            this.model.exitRegionChoices == null ? this.model.exitRegionChoices = [regionOption] : this.model.exitRegionChoices.push(regionOption);
          }
        });
      })
    }).catch( e => {
      console.log("unable to get the list of region options from the backend.", e);
    });
  }

  /**
   * obtain the current device's details for the display model
   */
  private loadCurrentDevDetails(): void {
    //get user id
    this.model.userID = this.currentUser.backendUserData.userID;
    
    //get device data
    this.model.id = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].id;
    this.model._version = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber]._version;
    this.model.devName = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].devName;
    this.model.deviceStatusID = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceStatusID;
    //this.model.deviceType = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceType;
    this.model.dnsFiltering = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].dnsFiltering;
    this.model._deleted = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber]._deleted;
    
    // get actual entry regions from the devEntryRegions list
    // this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].entryRegions.items.forEach( 
    //   (devEntryRegion) => {
    //     // if null
    //     if(this.model.entryRegionChoices == null) {
    //       // make a new arry with the current entry region
    //       this.model.entryRegionChoices = [devEntryRegion.entryRegion];
    //     } else {
    //       //otherwise append region
    //       this.model.entryRegionChoices.push(devEntryRegion.entryRegion);
    //     }
    //   }
    // );
    // console.log(this.model.entryRegionChoices);    

    // get actual exit regions from the devExitRegions list
    // this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].exitRegions.items.forEach( 
    //   (devExitRegion) => {
    //     // if null
    //     if(this.model.exitRegionChoices == null) {
    //       // make a new arry with the current entry region
    //       this.model.exitRegionChoices = [devExitRegion.exitRegion];
    //     } else {
    //       //otherwise append region
    //       this.model.exitRegionChoices.push(devExitRegion.exitRegion);
    //     }
    //   }
    // );
    // console.log(this.model.exitRegionChoices);
  }

  /**
   * track the form state after submission
   */
  onSubmit(): void {
    this.submitted = true;
  }

  /**
   * update the submission handle and reset the model for future additions
   */
  submitUpdateDeviceResults(): void {
    //get data from user's choices.
    this.updateDeviceResults = this.model;

    //set the device status to activating so the backend knows to perform the updates
    this.updateDeviceResults.deviceStatusID = "2";
    
    //now reset the users choices to default
    //this.model = new UpdateDeviceFormModel("999999999",0,"2021-04-24T16:44:26.308Z","0","Your Device Name",null,"0",false,null,null);
    
    //push what the user chose to the backend
    this.updateBackendDevice();
    
    //close the modal confirming the method.
    this.closeModalEvent.emit("Update clicked");
  }

  /**
   * Make a connection to the backend and submit the new device details then, subsequent region details
   */
  async updateBackendDevice() {
    this.updateDeviceInput.id = this.updateDeviceResults.id;
    this.updateDeviceInput._version = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber]._version; //this.updateDeviceResults._version;
    this.updateDeviceInput.devName = this.updateDeviceResults.devName;
    this.updateDeviceInput.userID = this.updateDeviceResults.userID;
    this.updateDeviceInput.deviceTypeID = this.updateDeviceResults.deviceType.id;
    this.updateDeviceInput.deviceStatusID = "2" // set to activating (2) so the backend knows we are making changes
    this.updateDeviceInput.dnsFiltering = this.updateDeviceResults.dnsFiltering;

    console.log("This is our input data: ", this.updateDeviceInput)
    //add the device, remove unnecessary regions, and add new regions.
    await this.currentUser.api.UpdateDev(this.updateDeviceInput).then(
      (event) => {

        //for all of our existing entry regions
        event.entryRegions.items.forEach(
          async (existingDevEntryRegion) => {

            //track if we find it in the new regions list
            var entryFound: boolean = false;

            //for each new region chosen
            this.updateDeviceResults.entryRegionChoices.forEach( 
              (newEntryRegion) => {

                if(!existingDevEntryRegion._deleted)
                {
                  // then it is a valid record, so check if it matches a request for the new region.
                  if(existingDevEntryRegion.entryRegion.id == newEntryRegion.id) {
                    //yes mark it
                    entryFound = true;  
                  }
                  else {
                    // We didnt find it do nothing
                  }
                }
                else {
                  // this is an existing region marked for deletion, ignore it to save processing.
                }                
              }
            );

            // delete if we have it and we did not find it in the user's choices.
            if(!entryFound && !existingDevEntryRegion._deleted) {
              await this.removeDeviceEntryRegionFromBackend(existingDevEntryRegion.id, existingDevEntryRegion._version);
            }
            else {
              //do nothing, we want to keep this
            }
          }
        );

        //for all of our existing exit regions
        event.exitRegions.items.forEach(
          async (existingDevExitRegion) => {

            //track if we find it in the new regions list
            var exitFound: boolean = false;

            //for each new region chosen
            this.updateDeviceResults.exitRegionChoices.forEach( 
              (newExitRegion) => {

                if(!existingDevExitRegion._deleted) {
                  // this record is valid, check to see if it matches a request for our new region
                  if(existingDevExitRegion.exitRegion.id == newExitRegion.id) {
                    //yes mark it
                    exitFound = true;  
                  }
                  else {
                    // we didnt find it do nothing
                  }
                }
                else {
                  // this record is marked for deletion, ignore it to save processing
                }
              }
            );

            // delete if we have it and we did not find it in the user's choices.
            if(!exitFound && !existingDevExitRegion._deleted) {
              await this.removeDeviceExitRegionFromBackend(existingDevExitRegion.id, existingDevExitRegion._version);
            }
            else {
              //we found it, so leave it alone.
            }
          }
        );

        //for all of our new entry regions
        this.updateDeviceResults.entryRegionChoices.forEach(
          async (newEntryRegion) => {

            //track if we found it in the existing regions list
            var entryFound: boolean = false;

            //for each existing region on the device
            event.entryRegions.items.forEach(
              (existingEntryRegion) => {

                if(!existingEntryRegion._deleted) {
                  // then we have a valid record, check if we have a match in our new regions request.
                  if(newEntryRegion.id == existingEntryRegion.entryRegion.id) {
                    //yes, mark it
                    entryFound = true;
                  }
                  else {
                    // we didnt find it do nothing
                  }
                }
                else {
                   // the record is marked for deletion, ignore it to save processing.
                }
              }
            );

            //if we dont find a matching entry add it.
            if(!entryFound) {
              //create local handle because it is async call.
              var localEntryRegionInput: {
                devID: string;
                entryRegionID: string;
                lastActive: string;
              } = this.entryRegionInput;
              
              //populate our handle with values
              localEntryRegionInput.devID = event.id;
              localEntryRegionInput.entryRegionID = newEntryRegion.id;
              localEntryRegionInput.lastActive = "2021-04-24T16:44:26.308Z";

              //call the backend with our new handle
              await this.addNewDeviceEntryRegionToBackend(localEntryRegionInput).catch( 
                (e) => {
                  this.currentUser.consoleDebug ? console.log(e, "Err: Unable to add Entry Region to the backend") : console.log();
                }
              );
            }
            else {
              //do nothing, because we want to keep this
            }
          }
        );
        
        //for all of our new exit regions
        this.updateDeviceResults.exitRegionChoices.forEach(
          async (newExitRegion) => {

            //track if we found it in the existing regions list
            var exitFound: boolean = false;

            //for each existing region on the device
            event.exitRegions.items.forEach(
              (existingExitRegion) => {

                if(!existingExitRegion._deleted) {
                  // then we found a valid record, check if it is a new match in our new request
                  if(newExitRegion.id == existingExitRegion.exitRegion.id) {
                    //yes, mark it
                    exitFound = true;
                  }
                  else {
                    // we didnt find it do nothing
                  }
                }
                else {
                  // the record is marked for deletion, ignore it to save processing.
                }

              }
            );

            //if we dont find a matching exit add it.
            if(!exitFound) {
              //create local handle because it is async call.
              var localExitRegionInput: {
                devID: string;
                exitRegionID: string;
              } = this.exitRegionInput;
              
              //populate our handle with values
              localExitRegionInput.devID = event.id;
              localExitRegionInput.exitRegionID = newExitRegion.id;

              //call the backend with our new handle
              await this.addNewDeviceExitRegionToBackend(localExitRegionInput).catch( 
                (e) => {
                  this.currentUser.consoleDebug ? console.log(e, "Err: Unable to add Exit Region to the backend") : console.log();
                }
              );
            }
            else {
              //do nothing, because we want to keep this
            }
          }
        );
      }
    ).catch(
      (e) => {
        this.currentUser.consoleDebug ? console.log(e, "Err: Unable to add device to the backend") : console.log();
      }
    );
  }

  /**
   * Send the new device's Entry Region information to the backend.
   * @param regionHandle of type CreateDevEntryRegionInput
   */
  async addNewDeviceEntryRegionToBackend(regionHandle: CreateDevEntryRegionInput): Promise<any> {
    var localRegionHandle = {
      devID: regionHandle.devID,
      entryRegionID: regionHandle.entryRegionID
    }; 

    await this.currentUser.api.CreateDevEntryRegion(localRegionHandle).then(
      event => {
        this.currentUser.consoleDebug ? console.log(event.entryRegion.regionName, " has been added to ",event.devID, event) : console.log();
      }
    ).catch(
      e => {
        this.currentUser.consoleDebug ? console.log(e,"Err: writing new device's Entry Region to backend.") : console.log();
      }
    );
  }
  
  /**
    * Send the new device's exit Region information to the backend.
    * @param regionHandle of type CreateDevExitRegionInput
    */
  async addNewDeviceExitRegionToBackend(regionHandle: CreateDevExitRegionInput): Promise<any> {
    var localRegionHandle = {
      devID: regionHandle.devID,
      exitRegionID: regionHandle.exitRegionID
    };

    await this.currentUser.api.CreateDevExitRegion(localRegionHandle).then(
      event => {
        this.currentUser.consoleDebug ? console.log(event.exitRegion.regionName, " has been added to ",event.devID, event) : console.log();
      }
    ).catch(
      e => {
        this.currentUser.consoleDebug ? console.log(e, "Err: writing new device's Exit Region to backend.") : console.log();
      }
    );
  }

  /**
   * Remove a device Entry Region from the backend.
   * @param devEntryRegionID region to remove
   * @param devEntryRegionVersion the corresponding version
   */
  async removeDeviceEntryRegionFromBackend(devEntryRegionID: string, devEntryRegionVersion: number): Promise<any> {
    var localDevEntryRegionInput = {
      id: devEntryRegionID,
      _version: devEntryRegionVersion
    };
    
    await this.currentUser.api.DeleteDevEntryRegion(localDevEntryRegionInput).then(
      (event) => {
        this.currentUser.consoleDebug ? console.log("Entry region", localDevEntryRegionInput.id, "removed from the backend.", event) : console.log();
      }
    ).catch(
      (e) => {
        this.currentUser.consoleDebug ? console.log(e, "Err: deleting devEntryRegion from the backend.") : console.log();
      }
    );
  }

  /**
   * Remove a device Exit Region from the backend.
   * @param devExitRegionID Region to remove
   * @param devExitRegionVersion The corresponding version
   */
  async removeDeviceExitRegionFromBackend(devExitRegionID: string, devExitRegionVersion: number): Promise<any> {
    var localDevExitRegionInput = {
      id: devExitRegionID,
      _version: devExitRegionVersion
    };

    await this.currentUser.api.DeleteDevExitRegion(localDevExitRegionInput).then(
      (event) => {
        this.currentUser.consoleDebug ? console.log("Exit region", localDevExitRegionInput.id, "removed from the backend.", event) : console.log();
      }
    ).catch(
      (e) => {
        this.currentUser.consoleDebug ? console.log(e, "Err: deleting devExitRegion from the backend.") : console.log();
      }
    );
  }

  /**
  * copy the Exit Region choice into entry region since the field is hidden.
  * This way we do not have to modify the codebase once exit region functionality is added, we unhide and stop using this function on ngMOdelChange for exit region.
  */
  updateEntryRegion(): any {
    this.model.entryRegionChoices = this.model.exitRegionChoices;
  }

  /**
  * checks to see if the user has a DNS subscription
  * @returns hasDNSSubscription as bool
  */
  HasDNSSubscription(): boolean {
  
    this.currentSubData.currentDNSFiltering > 0 ? this.hasDNSSubscription = true : this.hasDNSSubscription = false;
    return this.hasDNSSubscription;
  }


  async updateUserSubscriptionData(): Promise<any> {
    
    //handle for results of action
    var results;

    try {

      //query the back end to see if the current user has subscription data by their cognito ID (not backendID)
      results = await this.currentUser.api.GetSub(this.currentUser.backendUserData.userID);

    } catch (err) {

      results = err.toString();

    }

    return results;

  }

}
