export class UserStatus {

    constructor(
        public __typename: string = "userStatus",
        public id: string = "0",
        public status: string = "default",
        public createdAt: string = "",
        public updatedAt: string = "",
        public _version: number = 0,
        public _deleted: boolean = false,
        public _lastChangedAt: number = 0,
    ) {
        /**
         * code to run during construction
         */
    }

}
