<div class="container-fluid segmentDark">

    <div class="row py-5 align-items-center">

        <div class="card-deck mx-auto">

            <div class="card card3D-lg card3D-dark cardbg-dark">
                <img class="card-img-top" src="../../assets/img/Privacy.jpg" alt="Easy Setup">
                <div class="card-body">
                    <p class="card-text card-question text-center">HOW TO GET STARTED</p>
                    <p class="card-text text-center">In today's fast-paced, dynamic world, we need services that we can trust. GreyHex VPN uses the Open Source WireGuard client so you can have complete confidence in your privacy and security.</p>
                </div>
            </div>

            <div class="card card3D-lg card3D-dark cardbg-dark">
                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faTasks"></fa-icon><span class="ml-3">5 MINUTES TO PRIVACY</span></h4>
                    <hr class="border-danger">
                    <br>
                    <br>
                    <ul class="list-group list-group-flush">
                        <a href="#signup" hreflang="en" class="list-group-item list-group-item-light list-group-item-action"><fa-icon [icon]="faEdit"></fa-icon><span class="ml-3"> Sign up or in to your GreyHex account</span></a>
                        <li class="list-group-item list-group-item-light list-group-item-action"><fa-icon [icon]="faPlus"></fa-icon>&nbsp;<fa-icon [icon]="faMobileAlt"></fa-icon><span class="ml-2"> Add a device on the device panel</span></li>
                        <a href="#download" hreflang="en" class="list-group-item list-group-item-light list-group-item-action"><fa-icon [icon]="faCloudDownloadAlt"></fa-icon><span class="ml-3"> Download WireGuard on your device</span></a>
                        <li class="list-group-item list-group-item-light list-group-item-action"><fa-icon [icon]="faFileImport"></fa-icon><span class="ml-3"> Import the configuration file from device panel</span></li>
                        <li class="list-group-item list-group-item-light list-group-item-action"><fa-icon [icon]="faToggleOn"></fa-icon><span class="ml-3"> Toggle on the GreyHex VPN in the app</span></li>
                    </ul>
                </div>
            </div>

        </div>

    </div>

</div>