import { Component, OnInit } from '@angular/core';
import { faToggleOn, faEdit, faTasks, faDollarSign, faUserPlus, faTrashAlt, faHandPaper, faCalendarDay, faCalendarAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faCloudversify, faAppStoreIos, faAndroid, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-why-greyhex',
  templateUrl: './why-greyhex.component.html',
  styleUrls: ['./why-greyhex.component.css']
})
export class WhyGreyhexComponent implements OnInit {

  //font icons
  faToggleOn = faToggleOn;
  faEdit = faEdit;
  faLinux = faLinux;
  faWindows = faWindows;
  faAndroid = faAndroid;
  faAppStoreIos = faAppStoreIos;
  faTasks = faTasks;
  faDollarSign = faDollarSign;
  faUserPlus = faUserPlus;
  faTrashAlt = faTrashAlt;
  faHandPaper = faHandPaper;
  faCloudversify = faCloudversify;
  faCalendarDay = faCalendarDay;
  faCalendarAlt = faCalendarAlt;
  faCloudDownloadAlt = faCloudDownloadAlt;

  constructor() { }

  ngOnInit(): void {
  }

}
