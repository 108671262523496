/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:e823f9a3-cbb2-4276-805f-0c247f5d4c8f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Ggfq76ccY",
    "aws_user_pools_web_client_id": "37mgb8biglkvrp69nm6s5ji5bh",
    "oauth": {
        "domain": "greyhexvpnad816aab-ad816aab-greyhexpro.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "Https://vpn.greyhex.io/",
        "redirectSignOut": "Https://vpn.greyhex.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://g5jl2z53hzglxg3v2gnyjk7cum.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sxybg75j55azfpm5nueh7dycpy",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hotgfcx9tg.execute-api.us-east-2.amazonaws.com/greyhexpro",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
