import { Component, OnInit } from '@angular/core';
import { faToggleOn, faEdit, faTasks, faUserPlus, faCloudDownloadAlt, faPlus, faMobileAlt, faFileImport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.css']
})
export class GettingStartedComponent implements OnInit {

  //font icons
  faToggleOn = faToggleOn;
  faEdit = faEdit;
  faTasks = faTasks;
  faUserPlus = faUserPlus;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faPlus = faPlus;
  faMobileAlt = faMobileAlt; 
  faFileImport = faFileImport;


  constructor() { }

  ngOnInit(): void {
  }

}
