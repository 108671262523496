import { Component, OnInit } from '@angular/core';
import { faToggleOn, faEdit, faTasks, faDollarSign, faUserPlus, faTrashAlt, faHandPaper, faCalendarDay, faCalendarAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faCloudversify, faAppStoreIos, faAndroid, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-vpninfo',
  templateUrl: './vpninfo.component.html',
  styleUrls: ['./vpninfo.component.css']
})
export class VPNInfoComponent implements OnInit {

    //font icons
    faToggleOn = faToggleOn;
    faEdit = faEdit;
    faLinux = faLinux;
    faWindows = faWindows;
    faAndroid = faAndroid;
    faAppStoreIos = faAppStoreIos;
    faTasks = faTasks;
    faDollarSign = faDollarSign;
    faUserPlus = faUserPlus;
    faTrashAlt = faTrashAlt;
    faHandPaper = faHandPaper;
    faCloudversify = faCloudversify;
    faCalendarDay = faCalendarDay;
    faCalendarAlt = faCalendarAlt;
    faCloudDownloadAlt = faCloudDownloadAlt;


  constructor() { }

  ngOnInit(): void {
  }

}
