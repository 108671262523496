<div class="container-fluid segmentLight" id="metaSearchEngine">

    <div class="row py-5 align-items-center">

        <div class="card-deck mx-auto">

            <div class="card card3D-xl card3D-light cardbg-light">

                <div class="card-body">
                    <h4 class="card-text my-3 text-center"><fa-icon [icon]="faSearch"></fa-icon><span class="ml-3">GREYHEX'S METASEARCH ENGINE</span></h4>
                    <hr class="border-danger">
                    <br>
                    <br>
                    <p class="card-text text-justified">If privacy is important to you, check out our Meta Search Engine. We make sure your searches are private, even from us! That's why we based our Meta Search on Open Source Frameworks you can trust.</p>
                    <br>
                    <form (ngSubmit)="onSubmit()" #searchGreyhexMeta="ngForm">
                        <!-- Device Name -->
                        <div class="form-row mb-3">
                            <div class="input-group input-group-lg">
                                <div class="input-group-prepend">
                                    <label class="input-group-text bg-light text-center" for="searchQuery">
                                        <img src="../../assets/img/ghIcon2.png" alt="Greyhex" width="28" height="23">&nbsp;Greyhex
                                    </label>
                                </div>
                                <input
                                    id="searchQuery" 
                                    type="text"
                                    required
                                    class="form-control"
                                    [(ngModel)]="model.searchQuery"
                                    name="searchQuery"
                                    placeholder="Search the web!"
                                    #searchQuery="ngModel"
                                >
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-danger" [disabled]="!searchGreyhexMeta.form.valid">Search&nbsp;<fa-icon [icon]="faSearch"></fa-icon></button>
                                </div>
                            </div>
                            <div [hidden]="searchQuery.valid || searchQuery.pristine" class="alert alert-danger input-group text-center" role="alert">
                                Go ahead, we'll be discrete.
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </div>

    </div>

</div> 
