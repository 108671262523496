import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUserShield  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {

  //address for redirect.
  private baseUrl = `https://greyhexvpnad816aab-ad816aab-greyhexpro.auth.us-east-2.amazoncognito.com/signup`;
  private search = `?redirect_uri=Https%3A%2F%2Fvpn.greyhex.io%2F&response_type=code&client_id=37mgb8biglkvrp69nm6s5ji5bh&identity_provider=COGNITO&scopes=phone%2Cemail%2Copenid%2Cprofile%2Caws.cognito.signin.user.admin&state=A7XX1DjM7TXVbUkSoGdSfhaaFoV6yhF0&code_challenge=maio7RVq_-E8HYOilD-xCAfppOn8YgGPBRDVZaaXAE8&code_challenge_method=S256`

  //ICONS
  faUserShield=faUserShield;

  constructor(public router:Router) { }

  ngOnInit(): void {
  }

  signUpClicked() {
    window.location.replace(this.baseUrl + this.search)
    //this.router.navigate([this.baseUrl+this.search, {}]);  

  }

}
