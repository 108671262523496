<!-- JUMBOTRON -->
<div class="jumbotron jumbotron-fluid m-0 py-5">
    
    <div class="container">

        <div class="row HeroRow">

            <div class="col text-center align-self-center">

                <div class="row">

                    <!-- LOGO -->
                    <div class="col">
                        <img src="../../assets/img/greyhex_red.png" alt="Greyhex VPN" class="HeroLogo mx-auto my-3">
                    </div>

                    <!-- SLOGAN -->
                    <div class="col">
                        <h3 class="text-left">More than just a VPN</h3>
                        <h1 class="text-left">Take your privacy and data protection to the next level</h1>
                        <p class="lead text-left">PROTECT YOUR FIRST DEVICE COMPLETELY FREE</p>                 
                    </div>

                </div>            

                <br />
                <br />

                <!-- LEAD -->
                <div class="row">
                    
                    <div class="col">
                        <p class="lead text-left mx-auto">It's easy to start fighting Ads, Malware, Trackers, and improve your privacy through secure encrypted access to the internet</p>                 
                        <button type="button" (click)="signUpClicked();" class="btn btn-danger text-right mx-auto my-1">Get started <fa-icon [icon]="faUserShield"></fa-icon></button>
                    </div>
                    
                </div>
              
            </div>

        </div>

    </div>

</div>