//module and component imports
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../Types/user.model';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faPenSquare, faUserAlt, faMobileAlt, faCaretSquareRight, faCaretSquareLeft, faEllipsisH, faPlusSquare, faMinusSquare, faGlobeAmericas, faFileDownload, faFilter, faSignature } from '@fortawesome/free-solid-svg-icons';

//service imports
import { DynamicScriptLoaderServiceService } from '../dynamic-script-loader-service.service';

const modals = { NONE: 'none', DELETE: 'deleteDevModal', ADD: 'addDevModal', UPDATE: 'updateDevModal', UPDATESUB: 'updateSubscriptionModal'};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnChanges {
  
  @Input() currentUser : User = new User ();
  
  // STATES
  updatingDevices = false;
  currentDeviceNumber = 1; // the device the user thinks they are using.
  displayDeviceNumber = 0; // The actual device the user is using.
  numberOfDevices = 0;
  currentModal = modals.NONE;
  intervalID;

  // VARIABLES
  devSubCost = 2.00 // need to get these from back end table (and on subscription update form as well)
  dnsSubCost = 0.99 // need to get these from back end table (and on subscription update form as well)
  modalCloseResult = '';
  accessibleRegions = "";
  exitRegionChoices = null;
  entryRegionChoices = null;
  convertedLastActive = null;
  regionDownloadLinkChoices = null;
  regionDownloadLinks = [];
  regionDownloads : {
    link : string,
    name : string,
    uploaded : string,
    downloaded : string
  }[] = [];
  
  // ICON HANDLES
  faEdit = faEdit;
  faUserAlt = faUserAlt;
  faMobileAlt = faMobileAlt;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareLeft = faCaretSquareLeft;
  faEllipsisH = faEllipsisH;
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faGlobeAmericas = faGlobeAmericas;
  faFileDownload = faFileDownload;
  faFilter = faFilter;
  faSignature = faSignature;
  faPenSquare = faPenSquare;

  // CURRENT SUBSCRIPTION HANDLE
  currentSubData = {
  __typename: "sub",
  _deleted: null,  ​
  _lastChangedAt: 1,  ​
  _version: 1,  ​
  currentDNSFiltering: 0,  ​
  currentSubDevCount: 0,  ​
  devSubExpirationDate: "1970-01-01T00:00:00.000Z",  ​
  devSubNextDueAmount: 0.00,  ​
  devSubNextDueDate: "1970-01-01T00:00:00.000Z",  ​
  devSubStartDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubExpirationDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubNextDueAmount: 0.00,  ​
  dnsSubNextDueDate: "1970-01-01T00:00:00.000Z",  ​
  dnsSubStartDate: "1970-01-01T00:00:00.000Z",  ​
  id: "00000000-0000-0000-0000-000000000000",  ​
  lastPaidAmount: 0.00,  ​
  lastPaidDate: "1970-01-01T00:00:00.000Z",  ​
  lastTransactionID: "0000001",  ​
  lastTransactionUpdate: "1970-01-01T00:00:00.000Z",  ​
  paymentEmail: "guest@greyhex.io",  ​
  pendingDNSFiltering: 0,  ​
  pendingSubDevCount: 0,  ​
  status: "approved",  ​
  sub_Token: "",  ​
  transactionDNSFiltering: 0,  ​
  transactionSubDevCount: 0,
  terminationDate: null
};

  // NGCLASSES (DISPLAY)
  public outsideSpacer = {
    "row": true,
    "bodySpacer": true
  };

  public normalSpacer = {
    "row": true,
    "bodySpacer": false
  };
  
  constructor(public router:Router, private modalService: NgbModal, private dynamicScriptLoader: DynamicScriptLoaderServiceService) { }
  
  /**
   * Configure profile page on generation.
   */
  ngOnInit(): void {

    //kickoff the asyncronous loading of scripts via the script loader service. For use with things like foxycart, since the user is now signing in to their profile.
    this.loadScripts();

    //wait until congnito response before collecting these things
    setTimeout(() => {
      this.validateSession(); 
      this.updateNumberOfDevices(); 
      // this.convertLastActive();
      this.updateRegionDownloads();
      this.updateSubData();
      console.log(this.currentUser)
    }, 2000);    
    //every 5 seconds refresh backend user data
    this.intervalID = setInterval(() => {
        this.updateAll(false); 
      }, 5000);
  }
  
  ngOnDestroy(): void {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  /**
   * Update visuals on angular detected changes. (ng-esc)
   */
  ngOnChanges(): void {
    this.currentUser.refreshBackendData().then( (event) => {
      this.validateSession();
      this.updateNumberOfDevices();
      this.updateRegionDownloads();
      this.updateSubData();}
    );
  }

  /**
   * uses the dynamic script loading service to load .js files needed.
   */
  private loadScripts() {
    this.dynamicScriptLoader.load('foxycart').then(data => {
      //script loaded successfully, could console this if needed here.
    }).catch(error => console.log(error)); // logs the error if there is one.
  }

  /**
   * Validate the user's session.
   */
  validateSession() {
    if(!this.currentUser.loggedIn)
    {
      console.log("User not signed in!");
      this.notSignedIn();
    }
    else
    {
      //Do nothing as the user is currently signed in.
    }
  }

  /**
   * Actions to perform if user session is not active.
   */
  notSignedIn(): void{
    this.router.navigate(['/', {}]);
  }

  /**
   * Updates all backend information. will remove user if signed-out
   */
  async updateAll(skipBackend? : boolean): Promise<any> {
    //if we are doing our timed update
    if(skipBackend) {
      this.validateSession();
      this.updateNumberOfDevices();
      this.updateRegionDownloads();
    } else {
      //we are updating because we made a change.
      await this.currentUser.refreshBackendData().then( (event) => {
        this.validateSession();
        this.updateNumberOfDevices();
        this.updateRegionDownloads();
        this.updateSubData();
      }).catch( e => {
        this.currentUser.consoleDebug ? console.log("Unable to update backend user data", e) : console.log();
      });
    }
    
  }

  /**
   * Interface to backend for Subscription data
   * @returns "results" from subsciption table for the user
   */
  async updateUserSubscriptionData(): Promise<any> {
    
    //handle for results of action
    var results;

    try {

      //query the back end to see if the current user has subscription data by their cognito ID (not backendID)
      results = await this.currentUser.api.GetSub(this.currentUser.backendUserData.userID);

    } catch (err) {

      results = err.toString();

    }

    return results;

  }

  /**
   * Retrieves the backend subscription data
   */
  updateSubData() {
    //Check if the user has a subscription already
    if(this.currentUser.backendUserData.subStatusID != '0') {

      // they do, so grab their info
      this.updateUserSubscriptionData().then((eventResults) => {
        eventResults == null ? this.currentSubData = this.currentSubData : this.currentSubData = eventResults;
      });

    //the user does not have a subscription, do not pull from backend.
    } else {

      // use the defaults for the visuals

    }

  }

  /**
   * collects download links into an array for display
   */
  updateRegionDownloads() {
    if( (this.currentUser.backendUserData.deviceList.items != null) && (this.currentUser.backendUserData.deviceList.items.length > 0) ){
      //empty regionDownloads to start fresh
      this.regionDownloads = [];
      var tempDevice = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber]
      //grab each entry region download link
      tempDevice.entryRegions.items.forEach( devEntryRegion => {
          //if it isn't null
          if(devEntryRegion.confFile != null && devEntryRegion.confFile != "") {
            //add it to our list of downloads for this device

            var tempUploaded = "";
            var tempDownloaded = "";

            (devEntryRegion.uploaded == null || devEntryRegion.uploaded == "" ) ? tempUploaded = "0" : tempUploaded = devEntryRegion.uploaded;
            (devEntryRegion.downloaded == null || devEntryRegion.downloaded == "" ) ? tempDownloaded = "0" : tempDownloaded = devEntryRegion.downloaded;

            this.regionDownloads.push( { link:devEntryRegion.confFile, name:devEntryRegion.entryRegion.prettyName, uploaded:tempUploaded, downloaded:tempDownloaded } );
          }
      });
    }
  }

  deviceStatusVisual(id) :string {
    var tempString = "creating";

    if(id == 0) {
      tempString = "creating";
    }
    else {
      tempString = this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].deviceStatus.devStatus;
    }
    return tempString;
  }

  convertBoolToEnabledDisabled(bool: boolean): string {
    var tempString = "";
    bool ? tempString = "Enabled" : tempString = "Disabled";
    return tempString;
  }

  isDeviceProcessing(): boolean {
    
    var isProcessing: boolean = false;
    var tempDeviceListItems = this.currentUser.backendUserData.deviceList.items;

    if(tempDeviceListItems != null && tempDeviceListItems.length > 0) {

      switch (tempDeviceListItems[this.displayDeviceNumber].deviceStatusID) {
        case "1":
        case "3":
        case "5":
          isProcessing = false;
          break;
        default:
          isProcessing = true;
          break;
      }
    }

    return isProcessing;
  }

  /**
   * Converst the currently displayed device's last Active time 
   */
  convertLastActive(): string {
    var tempConvertedDateTime = "";
    var tempDeviceListItems = this.currentUser.backendUserData.deviceList.items;
    if(tempDeviceListItems != null && tempDeviceListItems.length > 0) {
      if(tempDeviceListItems[this.displayDeviceNumber].lastActive != null && tempDeviceListItems[this.displayDeviceNumber].lastActive !="") {
        if(tempDeviceListItems[this.displayDeviceNumber].lastActive != "2021-04-24T16:44:26.308Z") {
          var tempdate = new Date(this.currentUser.backendUserData.deviceList.items[this.displayDeviceNumber].lastActive);
          tempConvertedDateTime = tempdate.toLocaleString();
        } else {
          tempConvertedDateTime = " ";
        }
      } else {
        tempConvertedDateTime = " ";
      } 
    } else {
      tempConvertedDateTime = " ";
    }
    return tempConvertedDateTime;
  }

  /**
   * Converts the date for one worthy of display 
   */
  convertDate(dateIn: string, includeTime: boolean): string {
    
    var dateAsText = "";
      var tempdate = new Date(dateIn);
      
      includeTime ? dateAsText = tempdate.toLocaleString() : dateAsText = tempdate.toLocaleDateString();
    return dateAsText;

  }

  /**
   * Open new window with download link for user.
   */
  clickedDevDownload(): void {
  // add code to open new window and route user if download device button is clicked.
  }

  /**
   * Check if the user currently intends to update a device.
   */
  isUpdatingDevices(): boolean {
    return this.updatingDevices;
  }

  /**
   * The User clicked on done in the devices blade 
   */
  doneDevicesClicked(): void {

  }

  /**
   * The User clicked on Previous Device in the devices blade
   */
  previousDeviceClicked(): void {
    (this.currentDeviceNumber <= 1) ? (this.currentDeviceNumber = this.numberOfDevices) : (this.currentDeviceNumber -= 1);
    (this.currentDeviceNumber <= 0) ? (this.displayDeviceNumber = 0) : (this.displayDeviceNumber = this.currentDeviceNumber-1);
  }

  /**
   * The User clicked on Next Device in the devices blade
   */
  nextDeviceClicked(): void {
    (this.currentDeviceNumber >= this.numberOfDevices) ? (this.currentDeviceNumber = 1) : (this.currentDeviceNumber += 1);
    this.displayDeviceNumber = this.currentDeviceNumber-1;
  }

  /**
   * The User clicked on Add Device in the devices blade
   */
  addDeviceClicked(content: any): void {

    //set modal state and launch modal.
    this.currentModal = modals.ADD;
    this.openModal(content);

  }

  /**
   * the user has selected update in the devices blade
   */
  updateDeviceClicked(content: any): void {

    //mark update to prevent issues on displayed device.
    //this.updatingDevices = !this.updatingDevices;

    //set modal state and launch modal.
    this.currentModal = modals.UPDATE;
    this.openModal(content);
  }

  /**
   * The user has selected update on the subscription blade
   */
  updateSubscriptionClicked(content: any): void {
    //mark update to prevent issues on displayed subscription.

    //set modal state and launch modal.
    this.currentModal = modals.UPDATESUB;
    this.openModalXL(content);
  }

  /**
   * The user clicked the delete button on the device blade
   */
  deleteDeviceClicked(content: any): void {
    
    this.currentModal = modals.DELETE

    this.openModal(content);

  }

  /**
   * calculate the number of devices the user has for interactions
   */
  updateNumberOfDevices(): void {
    (this.currentUser.backendUserData.deviceList.items != null) ? (this.numberOfDevices = this.currentUser.backendUserData.deviceList.items.length) : (this.numberOfDevices = 0);
  }

  /**
   * Open Modal via the selected content, listen to response, and react accordingly.
   * @param content HTML structure for display
   */
  openModal(content) {
    this.modalService.open(content, {centered: true, ariaLabelledBy: 'modal-device-blade'}).result.then((result) => {
      this.modalCloseResult = `${result}`;
      switch(this.modalCloseResult) {
        case "Cross clicked":
          // code block
          this.currentModal = modals.NONE;
          break;
        case "Cancel clicked":
          // code block
          //this.addNewDevice();
          this.currentModal = modals.NONE;
          break;
        case "Delete clicked":
          if (this.currentModal = modals.DELETE) {
            this.updateAll();
          }
          this.currentModal = modals.NONE;
          break;
        case "Update clicked":
          if (this.currentModal = modals.UPDATE) {
            this.updateAll();
          }
          this.currentModal = modals.NONE
          break;
        case "Create clicked":
          if (this.currentModal = modals.ADD) {
            this.updateAll();
          }
          this.currentModal = modals.NONE
          break;
        default:
          // code block
          this.currentModal = modals.NONE;
      };
    }, (reason) => {
      this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`;
      switch(this.modalCloseResult) {
        case "ESC Pressed":
          // code block
          break;
        case "Backdrop clicked":
          // code block
          break;
        default:
          // code block
      };
    });
  }

  /**
   * Open Modal via the selected content, listen to response, and react accordingly.
   * @param content HTML structure for display
   */
  openModalXL(content) {
      this.modalService.open(content, {centered: true, size: 'xl', ariaLabelledBy: 'modal-subscription-blade'}).result.then((result) => {
        this.modalCloseResult = `${result}`;
        switch(this.modalCloseResult) {
          case "Cross clicked":
            // code block
            this.currentModal = modals.NONE;
            break;
          case "UpdateSub clicked":
            if (this.currentModal = modals.UPDATESUB) {
              this.updateAll();
            }
            this.currentModal = modals.NONE
            break;
          default:
            // code block
            this.currentModal = modals.NONE;
        };
      }, (reason) => {
        this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`;
        switch(this.modalCloseResult) {
          case "ESC Pressed":
            // code block
            break;
          case "Backdrop clicked":
            // code block
            break;
          default:
            // code block
        };
      });
    }


  /**
   * Determine abnormal cause for modal dismissal
   * @param reason event code for modal closure
   * @returns cause of modal closue not from option selection; held in event code.
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC pressed';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Backdrop clicked';
    } else {
      return `${reason}`;
    }
  }

  currentDNSFilteringAsText(): string {

    var text ="";
    this.currentSubData.currentDNSFiltering > 0 ? text="Enabled" : text="Disabled";
    return text;

  }

  pendingDNSFilteringAsText(): string {
    
    var text ="";
    this.currentSubData.pendingDNSFiltering > 0 ? text="Enabled" : text="Disabled";
    return text;
    
  }

  getNextDueAmount(): string {

    var nextDueAmt = 0;
    nextDueAmt = Math.round(((this.currentSubData.pendingSubDevCount * this.devSubCost) + (this.currentSubData.pendingDNSFiltering * this.dnsSubCost)) * 100 ) / 100;
    
    return nextDueAmt.toString();
    
  }

  getCurrentSubDevCount(): number {

    var tempString = 0;
    if(this.currentSubData.currentSubDevCount == null || this.currentSubData.currentSubDevCount < 0)
    {

      tempString = 1; // 0 plus 1, but just 1 to account for free device.

    } else {
      tempString = (this.currentSubData.currentSubDevCount + 1);
    }
    return tempString;
  }

  getPendingSubDevCount(): number {

    var tempString = 0;
    if(this.currentSubData.pendingSubDevCount == null || this.currentSubData.pendingSubDevCount < 0)
    {

      tempString = 1; // 0 plus 1, but just 1 to account for free device.

    } else {
      tempString = (this.currentSubData.pendingSubDevCount + 1);
    }
    return tempString;
  }

}