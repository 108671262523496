import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'

import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeroComponent } from './hero/hero.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddDeviceFormComponent } from './add-device-form/add-device-form.component';
import { DeleteDeviceFormComponent } from './delete-device-form/delete-device-form.component';
import { UpdateDeviceFormComponent } from './update-device-form/update-device-form.component';
import { UpdateSubscriptionFormComponent } from './update-subscription-form/update-subscription-form.component';
import { WireguardInfoComponent } from './wireguard-info/wireguard-info.component';
import { PricingInfoComponent } from './pricing-info/pricing-info.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { VPNInfoComponent } from './vpninfo/vpninfo.component';
import { WhyGreyhexComponent } from './why-greyhex/why-greyhex.component';
import { FoundersInfoComponent } from './founders-info/founders-info.component';
import { FoundersPromiseComponent } from './founders-promise/founders-promise.component';
import { FAQComponent } from './faq/faq.component';
import { MetaSearchEngineComponent } from './meta-search-engine/meta-search-engine.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProfileComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    HeroComponent,
    AddDeviceFormComponent,
    DeleteDeviceFormComponent,
    UpdateDeviceFormComponent,
    UpdateSubscriptionFormComponent,
    WireguardInfoComponent,
    PricingInfoComponent,
    GettingStartedComponent,
    VPNInfoComponent,
    WhyGreyhexComponent,
    FoundersInfoComponent,
    FoundersPromiseComponent,
    FAQComponent,
    MetaSearchEngineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AmplifyUIAngularModule,
    FontAwesomeModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
