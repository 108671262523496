<div class="container-fluid segmentDark" id="foundersInfo">

    <div class="row py-5 align-items-center">

        <div class="col-auto mx-auto align-content-center">
            <h4 class="card-text my-3 text-center"><fa-icon [icon]="faAddressBook"></fa-icon><span class="ml-3">THE FOUNDERS</span></h4>
            <hr class="border-danger">
            <br>
            <br>  
            <div class="row align-items-center">
                <div class="card-deck mx-auto">

                    <div class="card card3D card3D-light cardbg-light">
                        <img class="card-img-top card-image-mask mx-auto my-3" src="../../assets/img/Wykel.jpg" alt="Founder Christopher Wykel">
                        
                        <div class="card-body align-content-center">
                            <p class="card-text card-question text-center">CHRISTOPHER WYKEL</p>
                            <p class="card-text text-center">President, Bitcipher, Inc.</p>
                        </div>
    
                        <div class="card-footer">
                            <p class="lead text-left"><fa-icon [icon]="faQuoteLeft"></fa-icon>&nbsp;The world needs more privacy oriented apps. Greyhex answers that call.&nbsp;<fa-icon [icon]="faQuoteRight"></fa-icon></p>
                        </div>
                    </div>
    
                    <div class="card card3D card3D-light cardbg-light">
    
                        <img class="card-img-top card-image-mask mx-auto my-3" src="../../assets/img/Kearns.jpg" alt="Founder Kearns Image">
    
                        <div class="card-body align-content-center">
                            <p class="card-text card-question text-center">JASON KEARNS</p>
                            <p class="card-text text-center">Vice President, Bitcipher, Inc.</p>
                        </div>
    
                        <div class="card-footer">
                            <p class="lead text-left"><fa-icon [icon]="faQuoteLeft"></fa-icon>&nbsp;Privacy is and always will be the focus of GreyHex.&nbsp;<fa-icon [icon]="faQuoteRight"></fa-icon></p>
                        </div>
                    </div>
    
                </div>
            </div> 

        </div>

    </div>

</div>