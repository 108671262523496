<footer class="footer ">

    <div class="container-fluid">

        <div class="row">

            <div class="col text-center align-self-end mt-auto text-nowrap">
                <p> </p>
                <p class="m-0 p-0">
                    <a href="https://s3.us-east-2.amazonaws.com/greyhex.policies/Greyhex+Terms+Of+Service.pdf" class="mx-1">Terms and Conditions</a>
                    |
                    <a href="https://s3.us-east-2.amazonaws.com/greyhex.policies/Greyhex+Privacy+Policy.pdf" class="mx-1">Privacy Policy</a>
                    |
                    <a href="mailto:admin@bitcipher.com" target="_blank" class="mx-1">Support</a>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col text-center align-self-end mt-auto text-nowrap">
                <p>
                    Bitcipher, inc.
                    |
                    <a href="https://www.instagram.com/greyhexvpn/" hreflang="en" rel="nofollow" target="_blank" class="mx-1 text-white"><fa-icon [icon]="faInstagram"></fa-icon></a>
                    |
                    <a href="https://www.facebook.com/greyhexvpn/" hreflang="en" rel="nofollow" target="_blank" class="mx-1 text-white"><fa-icon [icon]="faFacebook"></fa-icon></a>
                </p>
            </div>
        </div>
    </div>
</footer>

