export class DeviceList {

    constructor(
        public __typename: string = "ModeldevConnection",
        public items: Array<any | null> | null = null,
        public nextToken = null,
        public startedAt = null 
    ) {
        /**
         * code run during construction
         */
    }

}
